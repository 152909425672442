import {configureStore} from '@reduxjs/toolkit';
import categoryReducer from './slice/category'
import newsReducer from './slice/news';
import eventReducer  from './slice/event';
import firmsReducer from './slice/firm';
import registrantSlice from './slice/registrantSlice';
import userDataReducer from './slice/userData';
import firmdNumber from './slice/firmdNumber';
import blogsReducer from './slice/blogs';
import registrantnearmeaddress from './slice/registrantNearmeLocation';
import navReducer from "./slice/navbar";


export const store = configureStore({
  reducer: {
    category: categoryReducer,
    news: newsReducer,
    events: eventReducer,
    firms: firmsReducer,
    registrants: registrantSlice,
    user: userDataReducer,
    pageNumber: firmdNumber,
    blogs: blogsReducer,
    registrantnearmeaddress: registrantnearmeaddress,
    menus: navReducer,
  },
});
