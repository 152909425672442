import React from 'react';
import "./CustomLoader.css";

const CustomLoader = () => {
    return (
      <div className="loading-box">
        <div className="loaders">
          <div></div>
          <div></div>
          <div></div>       
        </div>
      </div>
    );
};
export default CustomLoader;