import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/NavbarGlobal";
import Footer from "../../components/footer/Footer";
import MemberMenu from "./MemberMenu"
import {useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { Form, Modal } from "react-bootstrap";


const MembershipDues = () => {

    const navigate = useNavigate()
    const [id, setId] = useState('');
    const location = useLocation();
    const yourData = location.state || {};
    const dataArray = Object.values(yourData);
    const [discountValue, setDiscountValue] = useState(0);
    const [promo, setPromo] = useState(null)
    const [promotionalCode, setPromotionalCode] = useState('');
    const [show, setShow] = useState(false);
    const [cursorAnimation, setCursorAnimation] = useState(false);
    const [paymentData, setPaymentData] = useState({
        paymentAmount: 0,
        discountedAmount:0,
        tax: 0,
        totalAmount: 0,   
        taxPercentage: 0,
    });

    const [billingErrors, setbillingErrors] = useState({
        organization: '',
        address: '',
        AddressCont: '',
        City_Town: '',
        country: '',
        phone: '',
        poNumber: '',
        postalCode: ''
    });

    const [provinceCode, setProvinceCode] = useState('');
    const [priceId, setPriceId] = useState('');

    const [billingFormData, setBillingFormData] = useState({
        organization: '',
        address: '',
        AddressCont: '',
        City_Town: '',
        country: '',
        phone: '',
        poNumber: '',
        postalCode: ''
    });
 
    const [membershipdetail, setMembershipdetail] = useState('')

    const params = useParams();

    const [option, setOption] = useState([])

    const [viewData, setViewData] = useState([])
    const [data, setData] = useState([])

    const [isChecked, setIsChecked] = useState(false);
    
    const [isBillingSameAsMember, setIsBillingSameAsMember] = useState(false);
     const [modalOff, setModalOff]=useState(false);
     const [selectedOptions, setSelectedOptions] = useState("");
     const [paymentUpdate,setPaymentUpdate]= useState([]);


     const [promoDisable, setPromoDisable] = useState(false)

     const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;


    const handleChange = (e) => {
        const { name, value } = e.target;
        const trimmedValue = value?.trimStart();

        const filteredValue = trimmedValue.replace(emojiRegex, '');

        setBillingFormData({
            ...billingFormData,
            [name]: filteredValue,
        });
        validation(name, filteredValue);
    }

  let newErrors= { ...billingErrors };
  const validation = (name, value) => {
    let errorMessage = '';
  
    switch (name) {
      case 'address':
        errorMessage = value?.trim() === '' ? 'Address is required.' : '';
        break;
      case 'City_Town':
        errorMessage = value?.trim() === '' ? 'City/Town is required.' : '';
        break;
      case 'Country':
        errorMessage = value?.trim() === '' ? 'Country is required.' : '';
        break;
      case 'postalCode':
        errorMessage = value?.trim() === '' ? 'Postal Code is required.' : '';
        break;
      case 'phone':
        errorMessage = value?.trim() === '' ? 'Phone Code is required.' : '';
        break;
      default:
        break;
    }
  
    setbillingErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

    const validateForm = () => {
        for (const name in billingFormData) {
            if (billingFormData.hasOwnProperty(name)) {
                validation(name, billingFormData[name]);
          
            }
    
        }
    };

    useEffect(() => {
        document.title = "PCMA || Membership Dues";
    }, []);
    const onChange = (e) => {
        setPromotionalCode(e.target.value)
    }

    const ClearPromoCode = () => {
        setPromotionalCode('')
        setDiscountValue(0)
        setPromo(null);
    }

    const ApplyCode = async () => {
        setPromoDisable(true)
        if (discountValue > 0) {
            toast.error("Promo code already applied");
            setTimeout(() => {
                setPromoDisable(false)
            }, 5000);
            return false
        }
        else if (promotionalCode.length > 0) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/reddempt-promo-code?promo_code=${promotionalCode}`)
                if (response?.data?.status === true) {
                    toast.success("Promo Code Applied")
                    // toast.success(response?.data?.message);     
                    setPromo(response?.data?.promo_code_id);
                    setDiscountValue(response?.data?.discount)
                } else if (response?.data?.status === false) {
                    toast.error(response?.data?.message);
                    setPromo(null);
                    setDiscountValue(0)
                }
                setTimeout(() => {
                    setPromoDisable(false)
                }, 5000);

            } catch (error) {
                console.log("error", error)
                setTimeout(() => {
                    setPromoDisable(false)
                }, 5000);
            }
            //  toast.error("Not Valid");
        } else {
            toast.error("Promo code cannot be empty");
            setTimeout(() => {
                setPromoDisable(false)
            }, 5000);
        }

        

    };

    useEffect(() => {
        if (location) {
            setId(dataArray[0]?._id);
        }
    }, [dataArray]);

    

    const handleRadioChange = (item) => {
        setSelectedOptions(item.subscription_name);
        setPaymentUpdate(item);
      };

    const handleSubmit = async () => {
        if (paymentUpdate?.price_id && paymentUpdate?.subscription_name && paymentUpdate?.unit_amount) {

        
            if (!Object.values(newErrors).some((error) => error !== '')) {
                try {
                    const response = await axios.put(`${process.env.REACT_APP_PCMA_BACKEND}/api/membership-update/${params?.id}`, {
                        "membership_type": [{
                            price_id: paymentUpdate?.price_id,
                            subscription_name: paymentUpdate?.subscription_name,
                            price: paymentUpdate?.unit_amount
                        }]
                    });
                    toast.success(`${response?.data?.message}`);
                    setShow(false);
                    window.location.reload();
                } catch (error) {
                    console.log("error:", error)
                }
            }
        } else {
            toast.error("Select any one subscription")
        }
    };

    const userData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/get-profile-details`, {
                params: {
                    _id: params?.id,
                }
            }
            )
            if (response?.data) {
                setData([response?.data?.user]);
                setBillingFormData((billingFormData) => ({
                    ...billingFormData,
                    organization: response?.data?.user?.organization,
                    address: response?.data?.user?.address,
                    AddressCont: response?.data?.user?.address_cont,
                    City_Town: response?.data?.user?.city,
                    country: response?.data?.user?.country,
                    phone: response?.data?.user?.phone,
                    poNumber: response?.data?.user?.organization,
                    postalCode: response?.data?.user?.postal_code
                }))
                setMembershipdetail(response?.data?.user?.membership_type);
                setProvinceCode(response?.data?.user?.province_code);
                setPriceId(response?.data?.user?.membership_type[0].price_id);
            }
        } catch (error) {
            if (error.response.data.message === "User not found") {
                navigate('/not-found')
            }
        }
    };

 

    useEffect(() => {
        if (provinceCode && priceId) {   
        axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/payment-info`, {
            params: {
                province_code: provinceCode,
                price_id: priceId,
                discount: discountValue,
            },

        }).then((response) => {          
            setPaymentData((paymentData) => ({
                ...paymentData,
                paymentAmount: response?.data?.payment_amount,
                discountedAmount: response?.data?.discounted_amount,
                tax: response?.data?.extra_dollars_for_tax,               
                totalAmount: response?.data?.total_amount_with_tax,
                taxPercentage:response?.data?.tax
            }))
        }).catch((error) => {
            if (error.response.data.message === "User not found") {
                navigate('/not-found')
            }
        });
        }
    }, [provinceCode, priceId, discountValue]);

  
    useEffect(() => {
        userData();
        api();
    }, []);

    const api = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/pacakges `)
            setViewData(response?.data)
        } catch (error) {
            console.log("Error", error)
        }
    }

    const handleClose = () => {
        setShow(false);
    };

    const handleCheckboxChange = () => {
        setIsBillingSameAsMember(!isBillingSameAsMember);
    };

    const handleModal = async (data) => {
           
        // if (data && !Object.values(newErrors).some((error) => error !== '')) {

            setModalOff(true)
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_PCMA_BACKEND}/api/payment-mode-test`,
                    {
                        id: params?.id,
                        price_id: membershipdetail[0]?.price_id,
                        promo_code: promo != null ? promo : "",
                    }
                );
                if (response?.data?.payment_url) {
                    window.location.replace(response?.data?.payment_url);
                }

                if (response.data.success === false) {

                    handleModal(false);
                    api();
                    setShow(true);
                }

                if (response.data?.status === false) {
                    toast.error(response?.data?.error)
                    handleModal(false);
                    api();
                    setShow(true);
                }

            } catch (error) {
                toast.error(error);
                console.error(`Error: ${error}`);
                window.location.reload();
            }
        // }
        // else {
        //     toast.error('Fill the Billing information form')
        // }
    };


    const handleCheckboxChanges = () => {
        setIsChecked(!isChecked);
    };

    return (
        <>
            <Navbar />
            <div className="container-fluid slider_img_div_box mb-3" style={{maxWidth:'1690px', padding:'0px 20px', marginTop: '115px' }}>
                <div className="row my-3 my-sm-5 gy-3 g-lg-4">
                    <div className="col-lg-7 col-xl-8 dues_first_left_text_div  pe-lg-4">
                    <div className="member_ship_head_text mb-5">
                                <h3>
                                Membership <br /> <span>
                                        DUES
                                    </span>
                                </h3>
                                <p>
                                    <span className=" d-flex align-items-center justify-content-center"><img src="/asset/hand_ship.png" alt="" /> Welcome to the PCMA Community!</span>
                                </p>
                            </div>
                        <div className="dues_first_left_text">
                            <p className="mb-4"><strong>Join or renew today!</strong> Simply fill out the form below. Once we process your dues, you will enjoy full access to the Online Member Community. </p>
                            <strong >
                                Thank you for your support! <i className="fa-solid fa-heart"></i>
                            </strong>
                            <p className="mt-4">If you are signing up for the first time, this is the last step of your registration.</p>
                        </div>
                        <div className="account-information  d-flex align-items-center px-4 justify-content-between mb-3 py-2" style={{ backgroundColor: '#0F66B4' }}>
                            <span className="p-1"> MEMBERSHIP INFORMATION</span>
                        </div>
                        <div className="membership-subscription">
                            <h2 className="mb-0">{membershipdetail[0]?.subscription_name}:-  $ {membershipdetail[0]?.price}</h2>
                        </div>
                        {/* <input className="orm-control fs-6 py-2" style={{ border: ' 1px solid rgba(15, 102, 180, 0.30)' }} onChange={handleSelectChange} /> */}
                        {/* <select
                            className="form-select fs-6 py-2"
                            aria-label="Default select example"
                            style={{ border: ' 1px solid rgba(15, 102, 180, 0.30)' }}
                            value={membershipdetail[0]?.subscription_name}
                            onChange={handleSelectChange}
                        >
                            <option value="membershipdetail">{membershipdetail[0]?.subscription_name}:-  $ {membershipdetail[0]?.price}</option>
                        </select> */}
                        <div className="account-information mt-4  d-flex align-items-center px-4 justify-content-between mb-3 py-2" style={{ backgroundColor: '#0F66B4' }}>
                            <span className="p-1"> PROMOTIONAL CODE</span>
                        </div>
                        <div>
                            <div className="mb-3">
                                <input type="text" className="form-control
                                  fs-6 py-2" id="exampleFormControlInput1"
                                    value={promotionalCode.trim()}
                                    onChange={onChange}
                                    placeholder="Enter Promotional Code" style={{ border: ' 1px solid rgba(15, 102, 180, 0.30)' }} />
                            </div>
                            <div className=" d-flex align-items-center code_btn_clear_box">

                                <button onClick={ApplyCode} disabled={promoDisable}>Apply CODE</button>
                                <button onClick={ClearPromoCode} >CLEAR</button>
                            </div>
                        </div>
                        <div className="account-information mt-4  d-flex align-items-center px-4 justify-content-between mb-3 py-2" style={{ backgroundColor: '#0F66B4' }}>
                            <span className="p-1"> MEMBER INFORMATION</span>
                        </div>
                        <div className=" py-4 px-4 px-sm-5 membership_dues_table" style={{ backgroundColor: '#E7EFF7' }}>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="border-0"></th>
                                            <th className="border-0"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.map((userData, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>
                                                            <p className="mb-0 dues_table_heading">Full Name</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0 dues_table_data"><strong>{userData?.name}</strong></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="mb-0 dues_table_heading">Email Address</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0 dues_table_data"><strong>{userData?.email}</strong></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="mb-0 dues_table_heading">Organization</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0 dues_table_data"><strong>{userData?.organization}</strong></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="mb-0 dues_table_heading">Address</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0 dues_table_data"><strong>{userData?.address}</strong></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="mb-0 dues_table_heading">City/Town</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0 dues_table_data"><strong>{userData?.city}</strong></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="mb-0 dues_table_heading">Country</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0 dues_table_data"><strong>{userData?.country}</strong></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="mb-0 dues_table_heading">Province</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0 dues_table_data"><strong>{userData?.province}</strong></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="mb-0 dues_table_heading">Postal Code</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0 dues_table_data"><strong>{userData?.postal_code}</strong></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="mb-0 dues_table_heading">Phone</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0 dues_table_data"><strong>{userData?.phone}</strong></p>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="account-information mt-4  d-flex  px-4  mb-3 py-2" style={{ backgroundColor: '#0F66B4', fontSize: '18px', borderRadius: '5px' }}>
                                <input type="checkbox" name="" id="" className="bg-transparent" style={{ height: '20px', width: '15px' }}
                                    checked={isBillingSameAsMember}
                                    onChange={handleCheckboxChange} /> <span className="">Check here if the billing address is the same as the member address</span>
                            </div> */}
                        </div>
                        {/* <div className="account-information mt-4  d-flex align-items-center px-4 justify-content-between mb-3 py-2" style={{ backgroundColor: '#0F66B4' }}>
                            <span className="p-1">BILLING INFORMATION</span>
                        </div> */}
                        {/* <div className="py-4 px-4 px-sm-5 membership_dues_table" style={{ backgroundColor: '#E7EFF7' }}>
                            <div className="d-flex align-items-center mb-3 input_dues">
                                <label htmlFor="">Organization</label>
                                {isBillingSameAsMember ?
                                    <input
                                        type="text"
                                        className="form-control fs-6 py-2"
                                        id="exampleFormControlInput1"
                                        value={data[0]?.organization}
                                        disabled
                                    />
                                    :
                                    <input
                                        type="text"
                                        name="organization"
                                        className="form-control fs-6 py-2"
                                        id="exampleFormControlInput1"
                                        value={billingFormData?.organization}
                                        onChange={handleChange}
                                    />
                                }
                            </div>
                            <div className="d-flex align-items-center mb-3 input_dues">
                                <label htmlFor="">Address <span>*</span></label>
                                {isBillingSameAsMember ?
                                    <input
                                        type="text"
                                        className="form-control fs-6 py-2"
                                        id="exampleFormControlInput1"
                                        value={data[0]?.address}
                                        disabled
                                    />
                                    :
                                <div className="w-100">
                                    <input
                                        type="text"
                                        name="address"
                                        className={`form-control fs-6 py-2
                                        ${(((newErrors.address) && (billingFormData.address === ''))) && 'error_active'}
                                        ${((newErrors.address === '') && (billingFormData.address)) && 'sucess_active'}`}
                                        id="exampleFormControlInput1"
                                        value={billingFormData?.address}
                                        onChange={handleChange}
                                    />
                                       <span className="mt-1 d-inline-block" style={{ color: "red" }}>
                                       {newErrors.address}
                                     </span>
                                     </div>
                                    }
                            </div>
                            <div className="d-flex align-items-center mb-3 input_dues">
                                <label htmlFor="">Address Cont.</label>
                                {isBillingSameAsMember ?
                                    <input
                                        type="text"
                                        className="form-control fs-6 py-2"
                                        id="exampleFormControlInput1"
                                        value={data[0]?.address_cont}
                                        disabled
                                    />
                                    :
                                    <input
                                        type="text"
                                        name="AddressCont"
                                        className="form-control fs-6 py-2"
                                        id="exampleFormControlInput1"
                                        value={billingFormData?.AddressCont}
                                        onChange={handleChange}
                                    />
                                }
                            </div>
                            <div className="d-flex align-items-center mb-3 input_dues">
                                <label htmlFor="">City/Town <span>*</span></label>
                                {isBillingSameAsMember ?
                                    <input
                                        type="text"
                                        className="form-control fs-6 py-2"
                                        id="exampleFormControlInput1"
                                        value={data[0]?.city}
                                        disabled
                                    />
                                    :
                                    <div className="w-100">
                                    <input
                                        name="City_Town"
                                        type="text"
                                        className={`form-control fs-6 py-2
                                        ${(((newErrors.City_Town) && (billingFormData.City_Town === ''))) && 'error_active'}
                                        ${((newErrors.City_Town === '') && (billingFormData.City_Town)) && 'sucess_active'}`}
                                        id="exampleFormControlInput1"
                                        value={billingFormData?.City_Town}
                                        onChange={handleChange}
                                    />
                                   <span className="mt-1 d-inline-block" style={{ color: "red" }}>
                                       {newErrors.City_Town}
                                     </span>
                                </div>
                                }
                            </div>
                            <div className="d-flex align-items-center mb-3 input_dues">
                                <label htmlFor="">Country <span>*</span></label>
                                {isBillingSameAsMember ?

                                    <select
                                        name="country"
                                        className="form-control fs-6 py-2"
                                        value={data[0]?.country}
                                        onChange={handleChange}
                                        disabled
                                    >
                                        <option value="USA">USA</option>
                                    </select>
                                    :
                                <div className=" w-100">
                                    <select
                                        name="country"
                                        className={`form-control fs-6 py-2
                                        ${(((newErrors.country) && (billingFormData.country === '') && (billingFormData?.country !== 'Canada' || billingFormData?.country !== 'USA'))) && 'error_active'}
                                        ${(newErrors.country === '') && 'sucess_active'}`}
                                        value={billingFormData?.country}
                                        onChange={handleChange}
                                    >
                                        <option value="Canada">Canada</option>
                                        <option value="USA" selected>USA</option>
                                    </select>
                                    <span className="mt-1 d-inline-block" style={{ color: "red" }}>
                                    {newErrors.country}
                                  </span>
                                  </div>
                                }
                            </div>
                            <div className="d-flex align-items-center mb-3 input_dues">
                                <label htmlFor="">Location</label>
                                {isBillingSameAsMember ? 
                                <input
                                    type="text"
                                    className="form-control fs-6 py-2"
                                    id="exampleFormControlInput1"
                                    disabled
                                />:
                                <input
                                type="text"
                                className="form-control fs-6 py-2"
                                id="exampleFormControlInput1"/>
                            }
                            </div>
                            <div className="d-flex align-items-center mb-3 input_dues">
                                <label htmlFor="">Postal Code <span>*</span></label>
                                {isBillingSameAsMember ?
                                    <input
                                        type="text"
                                        className="form-control fs-6 py-2"
                                        id="exampleFormControlInput1"
                                        value={data[0]?.postal_code}
                                        disabled
                                    />
                                    :
                                    <div className=" w-100">
                                    <input
                                        type="text"
                                        name="postalCode"
                                        className={`form-control fs-6 py-2
                                        ${(((newErrors.postalCode) && (billingFormData.postalCode === ''))) && 'error_active'}
                                        ${((newErrors.postalCode === '') && (billingFormData.postalCode)) && 'sucess_active'}`}
                                        id="exampleFormControlInput1"
                                        value={billingFormData?.postalCode}
                                        onChange={handleChange}
                                    />
                                     <span className="mt-1 d-inline-block" style={{ color: "red" }}>
                                    {newErrors.postalCode}
                                  </span>
                                    </div>
                                }
                            </div>
                            <div className="d-flex align-items-center mb-3 input_dues">
                                <label htmlFor="">Phone <span>*</span></label>
                                {isBillingSameAsMember ?
                                    <input
                                        type="text"
                                        className="form-control fs-6 py-2"
                                        id="exampleFormControlInput1"
                                        value={data[0]?.phone}
                                        disabled
                                    />
                                    :
                                    <div className=" w-100">
                                    <input
                                        name="phone"
                                        type="text"
                                        className={`form-control fs-6 py-2
                                        ${(((newErrors.phone) && (billingFormData.phone === ''))) && 'error_active'}
                                        ${((newErrors.phone === '') && (billingFormData.phone)) && 'sucess_active'}`}
                                        id="exampleFormControlInput1"
                                        value={billingFormData?.phone}
                                        onChange={handleChange}
                                    />
                                       <span className="mt-1 d-inline-block" style={{ color: "red" }}>
                                    {newErrors.phone}
                                  </span>
                                    </div>
                                }
                            </div>
                            <div className="d-flex align-items-center mb-3 input_dues">
                                <label htmlFor="">PO Number</label>
                            {isBillingSameAsMember ? 
                           
                           <input
                           type="text"
                           name="poNumber"
                           className="form-control fs-6 py-2"
                           id="exampleFormControlInput1"
                           disabled
                       />
                           :
                           <input
                           type="text"
                           name="poNumber"
                           className="form-control fs-6 py-2"
                           id="exampleFormControlInput1"
                           value={billingFormData?.poNumber}
                           onChange={handleChange}
                       />
                       }
                            </div>
                        </div> */}

                        <div className="account-information mt-4  d-flex align-items-center px-4 justify-content-between mb-3 py-2" style={{ backgroundColor: '#0F66B4' }}>
                            <span className="p-1">PAYMENT INFORMATION</span>
                        </div>
                        <div className=" py-4 px-4 px-sm-5 membership_dues_table" style={{ backgroundColor: '#E7EFF7' }}>
                            <div className="payment_info_money py-2">
                                <p className="d-flex align-items-center justify-content-between"><span>Payment Amount</span> <span> <strong>${Number(paymentData?.paymentAmount)?.toFixed(2)}</strong></span></p>
                                <p className="d-flex align-items-center justify-content-between"><span style={{ color: 'green' }}>Promo</span> <span > <strong style={{ color: 'green' }}> - ${(Number(paymentData?.paymentAmount) - Number(paymentData?.discountedAmount)).toFixed(2)}</strong></span></p>
                                <p className="d-flex align-items-center justify-content-between"><span>Tax <span>{Number(paymentData?.taxPercentage)?.toFixed(2)} %</span></span> <span> <strong>${Number(paymentData?.tax)?.toFixed(2)}</strong></span></p>
                            </div>
                            <div className=" total_money_box py-3">
                                <p className="d-flex align-items-center justify-content-between"><span>Total Amount</span> <span> <strong>${Number(paymentData?.totalAmount)?.toFixed(2)}</strong></span></p>
                            </div>

                            <div className="px-3 py-2 payment_info_text" >
                                <p className="mb-0">The Cardholder stated above has authorized this payment. If this payment is part of a recurring series of payments, the Cardholder understands the terms and amounts of those recurring payments, and authorizes payment for each of them. Cardholder consent will expire once all scheduled recurring payments have been processed, or if all future recurrences are cancelled. The Cardholder acknowledges that specific payment dates may vary. Examples include, but are not limited to: payment dates falling during weekends, holidays, or during system outages. In order to facilitate the processing of those recurring payments, stored credentials will be used. Those stored credentials will only be used to process payments that are part of this series of recurrences. In order to use those stored credentials for any other payment, Cardholder re-authorization will be required. In the event that a consent agreement must be modified, the new agreement will be sent to the email address associated with the invoice. </p>
                            </div>

                            <div className="account-information mt-3 d-flex px-4 mb-4 py-2" style={{ backgroundColor: '#0F66B4', fontSize: '18px', borderRadius: '5px' }}>
                                <div className=" position-relative">
                                <input
                                    type="checkbox"
                                    className="bg-transparent"
                                    style={{ height: '20px', width: '15px' }}
                                    checked={isChecked}
                                    onChange={handleCheckboxChanges}
                                />
                                {
                                  ((isChecked==false) && cursorAnimation) ? <i className="fa-solid fa-hand-pointer fa-bounce fa_hand_pointer"></i>:''
                                }
                                </div>
                                <span className="">The Cardholder understands the terms and authorizes the payment(s).</span>
                            </div>
                            {
                                isChecked ? <button className="securely_btn mt-2" data-bs-toggle="modal" data-bs-target="#exampleModal">SUBMIT SECURELY</button> :
                                    <button className="securely_btn mt-2" onClick={setCursorAnimation}>SUBMIT SECURELY</button>
                            }
                            {/* <button className="securely_btn mt-2" data-bs-toggle="modal" data-bs-target="#exampleModal">SUBMIT SECURELY</button> */}

                            <div className="modal fade " id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content py-3">
                                        <div className="modal-header border-0 d-flex align-items-center justify-content-center pb-0">
                                            <img src="/asset/Featured.png" alt="" className="img-fluid" />
                                        </div>
                                        <div className="modal-body border-0">
                                            <div className="securely_modal_body">
                                                <h5>Are you sure to proceed ?</h5>
                                                <p>After confirmation this cannot be undone.</p>
                                            </div>
                                        </div>
                                        <div className="modal-footer border-0 d-block">
                                            <div className="securely_modal_btn  d-flex flex-column flex-sm-row  align-items-center justify-content-center">
                                                <button onClick={() => handleModal(false)} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                {
                                                    data && !Object.values(newErrors).some((error) => error !== '') ?
                                                    <button onClick={() => handleModal(true)} type="button" className="btn btn-primary">Confirm</button>:
                                                    <button onClick={() => handleModal(true)} type="button" className="btn btn-primary" data-bs-dismiss="modal">Confirm</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-xl-4 border_left ps-lg-4">
                    <div className="w-100  d-flex align-items-center justify-content-center mb-4">
                                <img src="/asset/pcmalogo1.png" alt="" className=" img-fluid" />
                            </div>
                        <MemberMenu />
                    </div>
                </div>
            </div>
            <Footer Width='1690px' Padding="0px" />
            <Modal centered backdrop="true" keyboard={false} size="lg" show={show}>
                <Modal.Body>
                    <div className="form p-3" >
                        {viewData && viewData?.map((data, i) => (
                            <div className="row" key={i}>
                                <div className="col-12 ">
                                    <div className="radio-button d-sm-flex align-items-center justify-content-between py-2" key={i}>
                                        <div className="d-flex align-items-center">
                                            <Form.Check
                                                className="mb-0 optiondata"
                                                type="radio"
                                                id={`accountingFirm-${i}`}
                                                name="fav_language"
                                                value={data?.subscription_name}
                                                checked={selectedOptions === data?.subscription_name}
                                                onChange={() => handleRadioChange(data)}
                                            />
                                            <p className="firm-option px-2">
                                                <label htmlFor={`accountingFirm-${i}`}>{data?.subscription_name}</label>
                                                <span><i className="fa-solid fa-circle-info"></i></span>
                                            </p>
                                        </div>
                                        {data?.is_popular && (<>
                                            <div className="most_popular py-1 px-2">
                                                <p className="mb-0"> Most popular</p>
                                            </div>
                                        </>)}

                                        <div className="firm-option-balance">{`$ ${data.unit_amount} + taxes`}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
              <button className="subcription_cancel ms-2" onClick={()=>{handleSubmit()}}>Submit</button>
                <button className="subcription_cancel ms-2" onClick={handleClose}>Close</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default MembershipDues;
