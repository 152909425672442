// import React, { useEffect, useRef, useState } from 'react';
// import { Container, } from 'react-bootstrap';
// import { Outlet } from 'react-router-dom';
// import LeftSidebar from '../../components/sidebar/LeftSidebar';
// import AdminTopBar from './AdminTopBar';
// import '../../styles/leftsidebar.css';
// import '../../styles/adminglobal.css'

// const DasboardLayout = () => {
  
//   const [sidebarToggleData, setSidebarToggleData] = useState(false);
//   const sidebarRef = useRef(null);
//   const sidbarToggle = (e) => {
//     if (e === true) {setSidebarToggleData(true)}
//     else {setSidebarToggleData(false)}
//   }

//   useEffect(() => {
  
//     function handleClickOutside(event) {
//       if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
//         setSidebarToggleData(false);
//       }
//     }

//     if (sidebarToggleData) {
//       window.addEventListener('click', handleClickOutside);
//     }

//     return () => {
//       window.removeEventListener('click', handleClickOutside);
//     };
//   }, [sidebarToggleData]);


//   return (
//     <div className="dashboard-container ">
//       <div ref={sidebarRef}>
//       <AdminTopBar sidbarToggle={sidbarToggle} sidebarToggleData={sidebarToggleData}  />
//       <div className={`left_sidebar  ${sidebarToggleData ? "active" : ""}`}  >
//         <LeftSidebar sidbarToggle={sidbarToggle}/>
//       </div>
//       </div>
//       <div className="main_content ">
//         <Container
//           fluid
//           className=""
//           style={{
//             backgroundColor: "#F8FAFC",
//           }}
//         >
//           <Outlet />
//         </Container>
//       </div>
//     </div>
//   );
// };

// export default DasboardLayout;


import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import LeftSidebar from '../../components/sidebar/LeftSidebar';
import AdminTopBar from './AdminTopBar';
import '../../styles/leftsidebar.css';
import '../../styles/adminglobal.css';

const DashboardLayout = () => {
  const [sidebarToggleData, setSidebarToggleData] = useState(false);
  const sidebarRef = useRef(null);

  const sidbarToggle = () => {
    setSidebarToggleData(prev => !prev); // Toggle the sidebar state
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarToggleData(false); // Close sidebar on outside click
      }
    }

    if (sidebarToggleData) {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [sidebarToggleData]);


  const [sideBarToggle, setSideBarToggle] = useState(true);

  console.log("sideBarTogglesideBarToggle",sideBarToggle)

  return (
    <div className="dashboard-container">
      <div ref={sidebarRef}>
        <AdminTopBar 
        // sidbarToggle={sidbarToggle} sidebarToggleData={sidebarToggleData}
        sideBarToggle={sideBarToggle}  setSideBarToggle={setSideBarToggle}
         />
        <div className={` ${sideBarToggle ? "active left_sidebar" : "left_sidebar-close"}`}>
          {sideBarToggle == true &&
          <LeftSidebar sidbarToggle={sidbarToggle} />
        }
        </div>
      </div>
      <div 
      // className="main_content"
      className={` ${sideBarToggle ? "main_content" : "main_content-close"}`}
      >

        <Container
          fluid
          style={{
            backgroundColor: "#F8FAFC",
          }}
        >
          <Outlet />
        </Container>
      </div>
    </div>
  );
};

export default DashboardLayout;
