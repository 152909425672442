import React, { useState, useEffect } from 'react';
import { Form, FormControl, Button, InputGroup } from 'react-bootstrap';
import { useNavigate, Link, useLocation} from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';

const Login = () => {
  const navigate = useNavigate();
  const logindata = JSON.parse(window.localStorage.getItem("login"));
  const payment_status = localStorage.getItem("payment_status");
  let tokens =null; 
  const locations = useLocation();
  const queryParams = new URLSearchParams(locations?.search);
  const socialAuthToken = queryParams? queryParams?.get('token'): null ;
  const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;

  useEffect(() => {
    redirectToLogin();
  }, [logindata, payment_status]);

  const redirectToLogin = () => {
    tokens = logindata?.tokens || null;
    if (tokens && payment_status) {
      navigate('/');
    }
  };

  useEffect(() => {
    document.title = "PCMA || Member Login";
  }, []);


  const [spinner, setSpinner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    username: '',
    password: '',
    rememberMe: false,
  });

  const [errors, setErrors] = useState({
    username: '',
    password: ''
  })
  let newErrors = { ...errors }


  const { linkedInLogin } = useLinkedIn({
    // clientId: '86phx24npr9fp1',
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {

    },
    onError: (error) => {

    },
  });

  const [latLocation, setLatLocation] = useState('');

  const [longLocation, setLongLocation] = useState('');


  const [location, setLocation] = useState({
    city: '',
    region: '',
    country: '',
    latitude: '',
    longitude: ''
  });


  const getLocation = async () => {
    try {
      const response = await fetch('https://ipinfo.io/json');
      if (!response.ok) {
        throw new Error('Failed to fetch location');
      }
      const data = await response.json();
      const { city, region, country, loc } = data;
      const [latitude, longitude] = loc?.split(',');
      setLocation({
        city,
        region,
        country,
        latitude,
        longitude
      });
      setLatLocation(latitude);
      setLongLocation(longitude);
    } catch (error) {
      console.error('Error fetching location:', error);
      setLocation({
        city: 'Location unavailable',
        region: '',
        country: '',
        latitude: '',
        longitude: ''
      });
    }
  };

  const handleSocilaMedia = (socialAuthToken) => {
    if (socialAuthToken) {
      axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/social-login`, {
        params: {
          token: socialAuthToken 
        },
      }).then((response) => {   
        let data = response.data;
        const id = response?.data?.user_data?._id;
        data = JSON.stringify(data);
        if(response.data.message === 'A password reset link has been sent to your email address. Please check your inbox.'){
          toast.success(response?.data?.message)
        }
        else if (response?.data?.message === 'Login successful!') {
          localStorage.setItem('login', data);
          if (response?.data?.user_data?.membership_status === true) {
            toast.success(`${response?.data?.message}`);
            if (response.data.user_data.membership_status === true) {
              localStorage.setItem("payment_status", response.data.user_data.membership_status);
            } else if (response.data.user_data.membership_status === false) {
              localStorage.setItem("payment_status", response.data.user_data.membership_status);
            }
            navigate('/')
          } else if (response?.data?.user_data?.membership_status === false) {
            window.location.href = `/membership-dues/${id}`;
          }
        }else if (response?.data?.message === "User Deactivated!") {
           toast.error(`${response.data.message}`)
          navigate('/');
        }
      }).catch((error) => {    
        if (error?.response?.data?.message === "User not found!") {
          toast.error(error?.response?.data?.message)
          navigate('/register');
        }
        setTimeout(() => {
          setSpinner(false)
        }, 2000);
        toast.error(error?.response?.data?.message)
        
      });
    }else{
      setTimeout(() => {
        setSpinner(false)
      }, 2000);
    }
  };


  useEffect(() => {
    getLocation();
  }, []);


  useEffect(() => {

    const storedUsername = localStorage.getItem('username');
    const storedPassword = localStorage.getItem('password');
    const storedRememberMe = localStorage.getItem('rememberMe') === 'true';
    
    setFormData({
      username: storedUsername || '',
      password: storedPassword || '',
      rememberMe: storedRememberMe,
    });

  }, []);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePassword = () => {
    setPasswordVisible((prev) => !prev);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
    const filteredValue = value.replace(emojiRegex, '');
    validateField(name, filteredValue);
    if (name === 'rememberMe' && !checked) {
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('username');
      localStorage.removeItem('password');
    }
  };

  const { username, password, rememberMe } = formData;

  let validateField = (fieldName, value) => {
    if (fieldName === 'username') {
      let userRegex = /^(?![0-9]+$)[a-zA-Z0-9\S]{4,20}$/;
      newErrors.username = value?.trim() === '' ? 'Username is required!' : !userRegex?.test(value?.trim()) ? 'Username is not valid! (Username must contains only letters and/or numbers and 4 to 20 characters in length)' : '';
    }
    if (fieldName === 'password') {
      newErrors.password = value?.trim() === '' ? 'Password is required!' : '';
    }
    setErrors(newErrors);
  };

  

  const validateForm = () => {

    for (const fieldName in formData) {
      if (formData.hasOwnProperty(fieldName)) {
        validateField(fieldName, formData[fieldName]);
      }
    }
    // if(username?.length>0 && password?.length>0){
    //   setSpinner(false);
    // }

  };


  // const handleSubmit = (e) => {
  //   setSpinner(true)
  //   e.preventDefault();
  //    validateForm();

  //   const { username, password, rememberMe } = formData;
  //   if (!Object.values(errors).some((error) => error !== '')) {
  //     if (username && password) {
  //       axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/login`
  //         , {
  //           username: username,
  //           password: password,
  //           latitude: latLocation,
  //           longitude: longLocation
  //         }
  //       )
  //         .then(function (response) {
  //           setSpinner(false)
  //           let data = response.data;
  //           const id = response?.data?.user_data?._id;
  //           data = JSON.stringify(data);


  //           if (response.data.message === 'Login successful!') {
  //             localStorage.setItem('login', data);
  //             if (rememberMe) {
  //               localStorage.setItem('username', username);
  //               localStorage.setItem('password', password);
  //               localStorage.setItem('rememberMe', true);
  //             }
  //             if (response?.data?.user_data?.membership_status === true) {
  //               toast.success(`${response.data.message}`);
  //               if (response.data.user_data.membership_status === true) {
  //                 localStorage.setItem("payment_status", response.data.user_data.membership_status);
  //               } else if (response.data.user_data.membership_status === false) {
  //                 localStorage.setItem("payment_status", response.data.user_data.membership_status);
  //               }

  //               setTimeout(() => {
  //                 window.location.href = '/user';

  //               }, 400);
  //             } else if (response?.data?.user_data?.membership_status === false) {
  //               window.location.href = `/membership-dues/${id}`;
  //             }

  //           } else if (response?.data?.message === "Incorrect Username or Password") {

  //             toast.error(`${response.data.message}`)
  //           }

  //         })
  //         .catch(function (error) {
  //           setSpinner(false);
  //           toast.error(error?.response?.data?.message)
  //           navigate("/")
  //         });
  //     }
  //   } else {
  //     setSpinner(false)
  //     setErrors(errors);
  //   } 
  // };


  const handleSubmit = (e) => {

    e.preventDefault();

    validateForm();
    


    if (!Object.values(errors).some((error) => error !== '')  && (username?.length>0 && password?.length>0 ) ) {
      setSpinner(true);
      if (username && password) {
        let login_credential = {
          username: username,
          password: password,
        }
        const publicKey = process.env.REACT_APP_PUBLIC_KEY;
        const encryptor = new JSEncrypt();
        encryptor.setPublicKey(publicKey);
        const windowTime = Date.now();
        let stringify = {
          ...login_credential,
          windowTime: btoa(windowTime)
        }
        var encrypted = encryptor.encrypt(JSON.stringify(stringify));

        axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/login`
          , { encrypted, latitude: latLocation, longitude: longLocation }
        )
          .then(function (response) {
            setTimeout(() => {
              setSpinner(false)
            }, 2000); 
           
            let data = response.data;
            const id = response?.data?.user_data?._id;
            data = JSON.stringify(data);
            if(response.data.message === 'A password reset link has been sent to your email address. Please check your inbox.'){
              toast.success(response?.data?.message)
            }
            else if (response.data.message === 'Login successful!') {
              localStorage.setItem('login', data);
              if (rememberMe) {
                localStorage.setItem('rememberMe', true);

                let credential_local = CryptoJS.AES.encrypt(JSON.stringify({ ...login_credential, rememberMe }), process.env.REACT_APP_BACKEND_SECRET).toString();
                localStorage.setItem("login_credential", credential_local);
              }
              if (response?.data?.user_data?.membership_status === true) {
                toast.success(`${response.data.message}`);
                if (response.data.user_data.membership_status === true) {
                  localStorage.setItem("payment_status", response.data.user_data.membership_status);
                } else if (response.data.user_data.membership_status === false) {
                  localStorage.setItem("payment_status", response.data.user_data.membership_status);
                }
                navigate('/')
                // setTimeout(() => {
                //   window.location.href = '/user';
                // }, 400);
              } else if (response?.data?.user_data?.membership_status === false) {
                window.location.href = `/membership-dues/${id}`;
              }
            } else if (response?.data?.message === "Incorrect Username or Password") {
              toast.error(`${response.data.message}`)
            } else if (response?.data?.message === "User Deactivated!") {
               toast.error(`${response.data.message}`)
              navigate('/');
            }
          })
          .catch(function (error) {
            setTimeout(() => {
              setSpinner(false)
            }, 2000);
            toast.error(error?.response?.data?.message)
            // navigate("/")
          });
      }
    } else {
      setTimeout(() => {
        setSpinner(false)
      }, 2000);
      // setErrors(errors);
    }
  };

  const handleFacebook = () => {
   
    window.location.href = 'https://dev.backendfirm.pcma.klizos.com/login/facebook';
  };

  const handlelinkedInLogin = () => {
    setIsLoading(true);
    window.location.href = 'https://dev.backendfirm.pcma.klizos.com/login/linkedin';
    setIsLoading(false);
  };

  
  useEffect(() => {
    if (localStorage.getItem("login_credential") != null) {
      try {
        let bytes = CryptoJS.AES.decrypt(localStorage.getItem("login_credential"), process.env.REACT_APP_BACKEND_SECRET);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (decryptedData) {
          setFormData({
            username: decryptedData.username,
            password: decryptedData.password,
            rememberMe: decryptedData.rememberMe,
          });
        }
      } catch (error) {
        localStorage.removeItem("login_credential");
        navigate('/login');
      }
    }else if(socialAuthToken){
      handleSocilaMedia(socialAuthToken);
    }
  }, [socialAuthToken]);

  // facebook login

  // const handleFacebookLogin = async () => {
  //   try {
  //     // Initialize the Facebook SDK
  //     window.FB.init({
  //       appId: 'Your-Facebook-App-ID',
  //       autoLogAppEvents: true,
  //       xfbml: true,
  //       version: 'v13.0', // Specify the Facebook Graph API version
  //     });

  //     // Trigger the Facebook login dialog
  //     const response = await window?.FB?.login((response) => {
  //       if (response.authResponse) {
  //         // User is authenticated, get user details
  //         window.FB.api('/me', function (user) {
  //           console.log('Facebook user details:', user);
  //           // Handle the user details as needed
  //         });
  //       } else {
  //         console.log('Facebook login cancelled or failed.');
  //       }4
  //     }, { scope: 'public_profile,email' }); 
  //     console.log("responseresponse",response)
  //   } catch (error) {
  //     console.error('Error during Facebook login:', error);
  //   }
  // };

  // facebook login

  return (
    <>
      <>

        <div className="main_login_body  " style={{ minHeight: '100vh' }}>
          <div className="left_side d-flex flex-column justify-content-between">
            <div className="upper-part text-center text-lg-start  pe-3">
              <h1 className="first-text ">Unleash the Potential</h1>
              <h4 className="second-text ">Where Innovation Meets Capital</h4>
              <p>
                Discover a Vibrant and Dynamic Network of Exempt Market
                Professionals United by Excellence and Innovation.
              </p>
            </div>
            <div className="dashboard-screen-image d-flex justify-content-end"></div>
          </div>

          <div className=" right_side d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className=" d-flex align-items-center justify-content-center">
                    <div className="form_register">
                      <Link to="https://pcma1dev.wpenginepowered.com/">
                        <img
                          src="./asset/logo.png"
                          className="img-fluid"
                          alt=""
                        />
                      </Link>

                      <h1 className="member-heading pt-5">Member Login</h1>
                      <Form
                        onSubmit={handleSubmit}
                        className="pt-4 registaer_form"
                      >
                        <Form.Group className="username d-flex flex-column justify-content-around">
                          <Form.Label htmlFor="username " className="fw-bolder">
                            Username
                            <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>

                          <Form.Control
                            type="text"
                            name="username"
                            id="username"
                            maxLength={20}
                            className={`
                                     ${((errors.username) && (formData.username === '') || (errors.username)) && 'error_active'}
                                     ${((errors.username === '') && (formData.username)) && 'sucess_active'}`}
                            placeholder="Enter Your Username"
                            value={formData?.username?.trim()}
                            onChange={handleChange}
                          />
                          <span style={{ color: "red" }}>
                            {errors.username}
                          </span>
                        </Form.Group>
                        <Form.Group className="password d-flex flex-column justify-content-around py-2">
                          <Form.Label htmlFor="password " className="fw-bolder">
                            Password
                            <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>
                          <div>
                            <div className="position-relative">
                              <Form.Control
                                type={passwordVisible ? "text" : "password"}
                                className={`
                                     ${((errors.password) && (formData.password === '') || (errors.password)) && 'error_active'}
                                     ${((errors.password === '') && (formData.password)) && 'sucess_active'}`}
                                name="password"
                                id="password"
                                maxLength={20}
                                placeholder="Enter Your Password"
                                value={formData?.password?.trim()}
                                onChange={handleChange}
                              />

                              <button
                                type="button"
                                className="bg-transparent border-0 position-absolute eyBtn"
                                onClick={togglePassword}
                              >
                                {!passwordVisible ? (
                                  <i className="fa-regular fa-eye-slash"></i>
                                ) : (
                                  <i className="fa-regular fa-eye"></i>
                                )}
                              </button>
                            </div>
                            <span style={{ color: "red" }}>
                              {errors.password}
                            </span>
                          </div>
                        </Form.Group>
                        <div className="col d-flex justify-content-between p-2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="form2Example34"
                              name="rememberMe"
                              checked={formData?.rememberMe}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="form2Example34"
                            >
                              Remember Me
                            </label>
                          </div>
                          <Link
                            to="/reset-password"
                            style={{ color: "rgb(15 103 182)" }}
                            className="text-decoration-none"
                          >
                            Forgot Password?
                          </Link>
                        </div>

                        <div className="register_login">
                          <Button type="submit" disabled={spinner}>
                            Login 
                            {spinner ? (
                              <i className="fa-solid fa-spinner fa-spin"></i>
                            ) : null}
                          </Button>
                        </div>
                      </Form>
                      <div className="divider d-flex align-items-center my-4">
                        <p className="text-center fw-bold mx-3 mb-0">Or</p>
                      </div>
                     
                      <div className="register_facebook">
                        {/* <Button  onClick={handleFacebook}>
                          <i
                            className="fa-brands fa-square-facebook"
                            style={{ color: `#254b8e`, }}
                          ></i>
                          <span> Continue with Facebook</span>
                        </Button>
                        <br /> */}
                        <Button onClick={handlelinkedInLogin} disabled={isLoading} >
                          <i
                            className="fa-brands fa-linkedin"
                            style={{ color: `#254b8e` }}
                          ></i>
                          <span>Continue with LinkedIn</span>
                        </Button>
                      </div>
                      

                      {/* <div className="divider d-flex align-items-center my-4">
                        <p className="text-center fw-bold mx-3 mb-0">Or</p>
                      </div> */}
                      <div className="note mt-5 w-100">
                        <p>
                          Please note that your member ID is your Username. If
                          you don't remember your member ID, please
                          <span> {' '}
                            <Link style={{ color: '#0F67B6' , textDecoration:"none" }} to="https://pcma1dev.wpenginepowered.com/contact-us/">
                              CONTACT US.
                            </Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* testing */}
          {/* <FacebookLogin
        appId="your-facebook-app-id"
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
      /> */}

          {/* testing */}
        </div>
        
      </>
    </>
  );
}

export default Login