import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import Navbar from "../../components/navbar/NavbarGlobal";
import Select, { components }  from "react-select";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import MemberMenu from "./MemberMenu";
import { set } from "lodash";

const MemberDirectory = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [location, setLocation] = useState("");
  const [industryPreferences, setIndustryPreferences] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => { 
     document.title = "PCMA || Member Directory";
  }, []);


   useEffect(() => {
    verifieduserData();
  }, []);

  

  

  const verifieduserData = async () => {
    setIsLoading(true)
    const response = await axios.get(
      `${process.env.REACT_APP_PCMA_BACKEND}/api/verified-user-filter-date`
    );
    setIsLoading(false)
    const countries = response?.data?.countries;
    const industries = response?.data?.industry_preferences;
    setCountryOptions(countries);
    setIndustryOptions(industries);
  };
    const data = { name,company, city, country, location, industryPreferences };

  const handleSubmit = () => {
     navigate("/users-data", { state: data });
    
  }
 
  const inputStyle = {
    borderRadius: "6px",
    border: "1px solid #0F67B6",
    background: "#E7EFF7",
    color: "#0F66B4",
  };

  const DropdownIndicator = (props) => {
    return components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? <i class="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}
      </components.DropdownIndicator>
    );
  };


  return (
    <>
       { isLoading ? (
      <Container fluid className="loader_ourMember" style={{ display: isLoading ? 'flex ' : 'none' }}>
        <div className="loader_contain">
          <div className="loader_contain_body">
              <img src="/asset/new-footer-logo.png" width="200" height="100" className="logo-img d-inline-block align-text-top" />
              <div className="spinner-container d-flex" style={{ gap:'3px'}}>
                <div className="spinner-grow text-primary" role="status"></div>
                    <div className="spinner-grow text-primary" role="status"></div>
                    <div className="spinner-grow text-secondary" role="status"></div>
                    <div className="spinner-grow text-success" role="status"></div>
                    <div className="spinner-grow text-danger" role="status"></div>
                    <div className="spinner-grow text-warning" role="status"></div>
                    <div className="spinner-grow text-info" role="status"></div>
                    <div className="spinner-grow text-light" role="status"></div>
                    <div className="spinner-grow text-dark" role="status"></div>
              </div>
          </div>
         </div>
      </Container>
      ) : (
          <>
      <Navbar />
      <div className="slider_img_div_box" style={{maxWidth:'1690px', padding:'0px 20px'}}>
        <Container fluid className="pb-4" style={{ marginTop: "120px" }}>
          <div className="row ">
            <div className="col-12 ">
              <div className="approve_about_banner  py-5 d-flex align-items-center">
               

                    <div className="over_layout">
                        <div className="content">

                            <div className="container-fluid p-0">
                                <div className="row g-3 g-lg-2 m-0">
                                    <div className="col-12  p-0 mb-0 mb-md-4 mb-lg-0">
                                        <div className=" d-flex align-items-center justify-content-end">
                                        <ul className="breadcrumb mb-0 py-3 px-4">
                                            <li className="breadcrumb-item active px-2"><Link to="https://pcma1dev.wpenginepowered.com/" className="px-3">Home</Link></li>
                                            <li className="breadcrumb-item active px-2" aria-current="page"><Link to="https://pcma1dev.wpenginepowered.com/membership-benefits/" className="px-3">Membership</Link></li>
                                            <li className="breadcrumb-item active px-2" aria-current="page">Our Members</li>
                                        </ul></div>
                                    </div>
                                    <div className="col-lg-9 col-xl-7  col-xxl-6">
                                        <div className="px-2 px-sm-5 text-center text-sm-start ">
                                     
                                        <h2>Member Directory</h2>
                                       
                                        <p>
                                            Embracing Diversity: Explore PCMA's Rich Tapestry of Members, Representing Varied Sectors within Private Capital Markets.
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
         
          <div className="new_member_text_container mt-5">
            <div className="container">
               <Row className="">
                  <Col xl={8} lg={6} className="pe-lg-4">
                    <div className="pcma_about text-center text-sm-start">
                      <p className="mb-4">
                        Private Capital Markets Association of Canada is proud to
                        represent the exempt market professionals from across Canada and
                        internationally. Exempt Market Dealers, Issuers, legal,
                        accounting, compliance and technology professionals cover a wide
                        range of fields in the exempt market industry. If you are
                        looking for an advice or consultation please connect with one of
                        the member industry professionals for guidance or insight into
                        the exempt market specific issues.
                      </p>
                      <p className="fst-italic mt-3 mb-4">
                        We invite you to connect with other professionals in the private
                        capital/exempt markets across Canada through the PCMA Membership
                        Community. <br />
                        Please use the Member Directory Search filter to find all PCMA members
                        from Coast to Coast.
                      </p>
                    </div>
                    <div
                        style={{ backgroundImage: "url(/asset/Rectangle1.png)", borderRadius: "5px", backgroundPosition:'bottom', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}
                        className="d-flex align-items-center j_input_member justify-content-center">
                  <Form onSubmit={handleSubmit} className="p-4">
                    <Row className="g-3">
                      <Col lg={6} md={6} sm={12}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            style={inputStyle}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6} md={6} sm={12}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Enter Company"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            style={inputStyle}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6} md={6} sm={12}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Enter City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            style={inputStyle}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6} md={6} sm={12} className="j_input_bg">
                        <Form.Group>
                        <Select
                            options={countryOptions?.map((country) => ({
                              value: country,
                              label: country,
                            }))}
                            placeholder="Select Country"
                            value={countryOptions?.find(
                              (option) => option?.value === country
                            )}
                            onChange={(selectedOption) =>
                              setCountry(selectedOption.value)
                            }
                            styles={{
                              ...inputStyle,
                              control: (provided) => ({
                                ...provided,
                                border: "none",
                              }),
                            }}
                            components={{ DropdownIndicator }}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6} md={6} sm={12}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Enter Location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            style={inputStyle}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6} md={6} sm={12} className="j_input_bg">
                        <Form.Group>
                          <Select
                            options={industryOptions.map((industry) => ({
                              value: industry,
                              label: industry,
                            }))}
                            placeholder="Select Industry Preferences"
                            value={industryOptions.find(
                              (option) => option.value === industryPreferences
                            )}
                            onChange={(selectedOption) =>
                              setIndustryPreferences(selectedOption.value)
                            }
                            styles={{
                              ...inputStyle,
                              control: (provided) => ({
                                ...provided,
                                border: "none",
                              }),
                            }}
                            components={{ DropdownIndicator }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="mt-3 mb-sm-3 mt-sm-5">
                      <Button style={inputStyle} type="submit">
                        Search
                          <svg
                            className="ms-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="12"
                          viewBox="0 0 21 12"
                          fill="none"
                        >
                          <path
                            d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75V5.25ZM20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989593 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM1 6.75H20V5.25H1V6.75Z"
                            fill="#0F66B4"
                          />
                        </svg>
                      </Button>
                    </div>
                  </Form>
          </div>
                </Col>
                <Col xl={4} lg={5} className="border_left ps-lg-4 mt-4 mt-lg-0">
                   <MemberMenu />
                </Col>
        </Row>
            </div>
         </div>
       
      </Container>
            </div>
      <Footer Width='1690px' Padding="0px" wordpressUrl='https://pcma1dev.wpenginepowered.com/'/>
      </>
      )}
     
    </>
  );
};
export default MemberDirectory;
