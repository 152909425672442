// LeftSidebar.jsx
import React, { useState} from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import '../../styles/leftsidebar.css';
import toast from "react-hot-toast";
import Collapse from "react-bootstrap/Collapse";

const LeftSidebar = (props) => {
  const { id } = useParams();
  
  let navigate = useNavigate();
  const [navOpen, setNavOpen] = useState(false);

 
  const hanldeLogOut = () => {
    localStorage.removeItem('loginAdmin');
    toast.success('You have logged out successfully.');
    navigate('/admin/login');
  }

  const toggleFlags =  (val) => {
    if(val){
      setNavOpen(val);
     
    }else{
      setNavOpen(val);
    }

  };
  
 
  const location = useLocation()

  const sidebarItems = [
    { icon: 'fa-solid fa-house', name: 'Dashboard', path: '/admin' },
    { icon: 'fa-solid fa-user-group', name: 'PCMA Members', path: '/admin/pcmamembers' },
    { icon: 'fa-solid fa-city', name: 'Registered Firms ', path: '/admin/firms' },
    { icon: 'fa-solid fa-user-group ',name: 'Registered Individuals', path: '/admin/registered-individuals' },
    // { icon: 'fa-solid fa-file-lines', name: 'Notice', path: '/admin/notice' },
    // { icon: 'fa-solid fa-dollar-sign', name: 'Subscription', path: '/admin/subscription' },
    // { icon: 'fa-solid fa-credit-card ', name: 'Payment Logs', path: '/admin/payment' },
    // { icon: 'fa-solid fa-ticket ', name: 'Promo Code', path: '/admin/promo-code' },


  ];

   





  return (
    <div className='d-flex flex-column justify-content-between h-100'>
      <nav>
        <ul className='sidebar_items admin_icon_sidebar'>
          {
            sidebarItems.map((sidebarItem, index) => {
              return (
                <li key={index}>
                  <Link  onClick={() => {
                    toggleFlags(false);
                    props.sidbarToggle(false);
                  }} to={sidebarItem.path} className={`d-flex align-items-center ${location.pathname === sidebarItem.path ||
                    (sidebarItem.path === "/admin/firms" && location.pathname === `/admin/firmupdate/${id}`
                      // || (sidebarItem.path === "/admin/firms" && location.pathname === `/admin/firms/34410`)
                      || (sidebarItem.path === "/admin/firms" && location.pathname === `/admin/firms/${id}`)
                      || (sidebarItem.path === "/admin/pcmamembers" && location.pathname === `/admin/pcmamembers-edit/${id}`)
                      || (sidebarItem.path === "/admin/pcmamembers" && location.pathname === `/admin/pcmamembers`)
                      || (sidebarItem.path === "/admin/pcmamembers" && location.pathname === `/admin/pcmamembers/${id}`)
                      || (sidebarItem.path === "/admin/pcmamembers" && location.pathname === `/admin/pcmamembers/${id}`)
                      // || (sidebarItem.path === "/admin/promo-code" && location.pathname === `/admin/promo-code-add`)
                      // || (sidebarItem.path === "/admin/promo-code" && location.pathname === `/admin/promo-code-edit/${id}`)
                      // || (sidebarItem.path === "/admin/payment" && location.pathname === `/admin/payment/${id}`)
                      || (sidebarItem.path === "/admin/registered-individuals" && location.pathname === `/admin/registered-individuals`)
                      || (sidebarItem.path === "/admin/registered-individuals" && location.pathname === `/admin/registered-individuals-details/${id}`) 
                      // || (sidebarItem.path === "/admin/firms" && location.pathname === `/admin/firms/${id}`)
                    
                    )
                    ? 'active'
                    : ''
                    }`} > <span><i className={sidebarItem.icon}></i> </span> <span className='navs '>{sidebarItem.name}</span></Link>
                </li>
              )
            })
          }
        </ul>
        <ul className='sidebar_dropdown  admin_icon_sidebar' style={{ paddingLeft: '0px' }}>
          
          <li  style={{paddingTop:'0px'}} >
            <Link className={`d-flex align-items-center ${(location.pathname.includes(`/admin/flag/firm-flag`)) || (location.pathname.includes(`/admin/flag/registrant-flag`))?'active':''}`} onClick={() => toggleFlags(!navOpen)}>
                  <span><i className='fa-solid fa-flag'></i> </span> <span className='navs'> Flags </span>
              <small className="dropIcon ms-3">{navOpen ? (<i className="fa-solid fa-angle-up"></i>) : (<i className="fa-solid fa-angle-down"></i>)}</small>
            </Link>   
              <Collapse in={navOpen}>
                <div id="example-collapse-text">
                  <ul className='ps-4'>
                    <li  className='dropdown'>
                    <Link onClick={() => props.sidbarToggle(false)} to={'/admin/flag/firm-flag'} className={`${(location.pathname.includes(`/admin/flag/firm-flag`)) ? 'actives' : ''}`} style={{ fontSize: '14px' }}>Reg. Firm Flag</Link>
                     
                    </li>
                    <li>
                    <Link onClick={() => props.sidbarToggle(false)} to={'/admin/flag/registrant-flag'} className={`${(location.pathname.includes(`/admin/flag/registrant-flag`)) ? 'actives' : ''}`} style={{ fontSize: '14px' }}> Reg. Individuals Flag</Link>
                    </li>
                  </ul>
                  </div>
              </Collapse>
            
          </li>         
        </ul>
      </nav>
      <div className="logtext p-2"  style={{ color: 'rgb(19 124 217)', cursor: 'pointer', fontSize: '22px' }} onClick={() => { hanldeLogOut() }}>
        <span className="logoutIcon"><i className="fa-solid fa-arrow-right-from-bracket pe-3"></i> Logout</span>
       
      </div>
    </div>
  );

}

export default LeftSidebar;
