import axios from "axios";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


const PromoCode = () => {
    let token = null;
    const logindata = JSON.parse(window.localStorage.getItem("loginAdmin"));
    const [spinner, setSpinner] = useState(false);

const redirectToLogin = () => {
   token = logindata?.Token || null;
  if (token === null) {
    navigate('/login');
  }
};


    useEffect(() => {
        redirectToLogin();
    }, [logindata]);

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        code_name: '',
        discount: '',
        duration_in_months: '',
        max_redemptions: ''
    })

    const [errors, setErrors] = useState({
        code_name: '',
        discount: '',
        duration_in_months: '',
        max_redemptions: ''

    })

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     let trimmedValue = value?.trimStart();

    //     if (name === 'duration_in_months'|| name === 'max_redemptions' ) {
    //         trimmedValue = trimmedValue?.replace(/^0+/, "").replace(/\D/g, "");
    //         trimmedValue = trimmedValue?.trim();
    //         setFormData({
    //             ...formData,
    //             [name]: trimmedValue,
    //         });
    //     }
    //     else {
    //         setFormData({
    //             ...formData,
    //             [name]: trimmedValue,
    //         });
    //     }

    //     validateField(name, value)
    // }
    const handleChange = (e) => {
        const { name, value } = e.target;
        const sanitizeInput = (input) => {
            const emojiRegex = /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F1E0}-\u{1F1FF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}]/ug;
            let sanitizedValue = input?.replace(emojiRegex, '');
            const specialCharRegex = /[^\w\s]/g; 
            sanitizedValue = sanitizedValue?.replace(specialCharRegex, '');
            sanitizedValue = sanitizedValue?.trimStart();
            return sanitizedValue;
        };
        let sanitizedValue = sanitizeInput(value?.trim());
        setFormData({
            ...formData,
            [name]: sanitizedValue,
        });
        validateField(name, sanitizedValue);
    };


    const handleFormatChange = (e) => {
        const { name, value } = e.target;
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            let newValue = value?.substring(0, value?.length - 1);
            newValue = newValue?.trim();
            newValue = newValue?.replace(/\D/g, "");
            newValue = newValue?.substring(0, 6);
            var size = newValue?.length;

            if (size >= 1 && size <= 2) {
                newValue = newValue + '%';
            } else if (size > 2) {
                newValue = newValue?.substring(0, 2) + '.' + newValue?.substring(2,) + '%';
            }

            setFormData({
                ...formData,
                [name]: newValue,
            });

            validateField(name, newValue);
        } else {
            let trimmedValue = value?.trimStart();
            let discount = trimmedValue?.replace(/^0+/, "").replace(/\D/g, "");
            discount = discount?.trim();
            discount = discount?.substring(0, 6);
            var size = discount?.length;

            if (size >= 1 && size <= 2) {
                discount = discount + '%';
            } else if (size > 2) {
                discount = discount + '%';
                // discount = discount?.substring(0, 2) + '.' + discount?.substring(2,) + '%'
            }

            setFormData({
                ...formData,
                [name]: discount,
            });

            validateField(name, value);
        }
    };



    let newErrors = { ...errors }

    const validateField = (fieldName, value) => {
        const trimmedValue = value?.trim();
        switch (fieldName) {
          case 'code_name':
            newErrors.code_name = trimmedValue === '' ? 'Code name is required!' : '';
            break;
          case 'discount':
            newErrors.discount = trimmedValue === '' ? 'Discount value is required!' : '';
            break;
          case 'duration_in_months':
            newErrors.duration_in_months = trimmedValue === '' ? 'Duration in months is required!' : '';
            break;
          case 'max_redemptions':
            newErrors.max_redemptions = trimmedValue === '' ? 'Max redemptions is required!' : '';
            break;
          default:
            break;
        }
        setErrors(newErrors);
      };
      


    const validateForm = () => {

        for (const fieldName in formData) {
            if (formData.hasOwnProperty(fieldName)) {
                validateField(fieldName, formData[fieldName]);
            }
        }
        setSpinner(false)
    };

    useEffect(() => {
        document.title = 'PCMA || New Promo Code';
    }, []);





    const handleSubmit = async (e) => {
        e.preventDefault();
        validateForm()
        let discountOutParcen = formData.discount;

        if (discountOutParcen) {
            discountOutParcen = discountOutParcen.slice(0, -1);
        }

        if (!Object.values(newErrors).some((error) => error !== '')) {
        setSpinner(true)
            try {
                const resposne = await axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/create-promo-code`, {
                    name: formData.code_name,
                    discount: discountOutParcen,
                    duration_in_months: formData.duration_in_months,
                    max_redemptions: formData.max_redemptions
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },)
                if (resposne.data.message === "Promo Code Created successfully") {
                    toast.success(resposne.data.message);
                    navigate('/admin/promo-code')
                    setSpinner(false)
                }

            } catch (error) {
                setSpinner(false)
                toast.error(error.response.data.message)
            }
        }

    }

    const popoverClickRootClose = (
        <Popover id="popover-trigger-click-root-close" className="custom-popover-class">
            <p className='popover-note_header mb-1'>Note :</p>
            <p className='popover-note_txt'>Max Redemption indicates the maximum number of people who can use this promo code.</p>
        </Popover>
    );

    const handleBack = () => {
        if (window.history.length === 1) {
            navigate('/login');
        } else {
            navigate(-1);
        }
    };

    return (
        <>
            <Row className="g-2" style={{ paddingBottom: "10px" }} >
                <Col lg={3} md={3} sm={12} >
                    <span
                        onClick={handleBack}
                        style={{
                            cursor: "pointer",
                            background: "#0F66B4",
                            color: "white",
                            fontWeight: "500",
                            display: 'inline-block'
                        }}
                        className=" user_firm_details_back_btn  position-static"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-left"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fillRule="evenodd"
                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                            />
                        </svg>{" "}
                        Back
                    </span>
                </Col>
                <Col lg={9} md={9} sm={12}></Col>
            </Row>
            <div className="row pt-4 mb-4">
                <div className="col-12"
                >
                    <div style={{ background: "#137CD9", color: "#fff", borderRadius: '5px' }}>
                        <h4 className="mb-0 py-2 px-3">Add New Promo Code</h4>
                    </div>
                </div>
                <div className="col-12 mt-4 px-3">
                    <div className="row">
                        <div className="col-md-8 col-xl-6 ">
                            <form className="row g-3 promocode_form mt-1" onSubmit={handleSubmit}>
                                <div className="col-sm-6">
                                    <label className="form-label mb-3">Promo Code Name</label>
                                    <input type="text"
                                     name='code_name'
                                      placeholder="Enter Promo code"
                                      value={formData?.code_name?.trim()}
                                        className={`form-control mb-2 py-2
                                            
                                    ${((errors.code_name) && (formData.code_name === '')) && 'error_active'}
                                    ${((errors.code_name === '') && (formData.code_name)) && 'sucess_active'}`}
                                        onChange={handleChange} 
                                        onInput={(e) => {
                                            if (e.target.value.length > 20) {
                                              e.target.value = e.target.value.slice(0, 20);
                                            }
                                          }}
                                        />
                                    <span style={{ color: "red" }}>{errors.code_name}</span>
                                </div>


                                <div className="col-sm-6">
                                    <label className="form-label mb-3">Discount </label>
                                    <input type="text" name='discount'
                                        maxLength={6}
                                        value={formData?.discount}
                                        placeholder="Enter Discount" className={`form-control mb-2 py-2
                                    ${((errors.discount) && (formData.discount === '')) && 'error_active'}
                                    ${((errors.discount === '') && (formData.discount)) && 'sucess_active'}`}
                                        onChange={handleFormatChange} 
                                        onInput={(e) => {
                                            if (e.target.value.length > 4 ) {
                                                e.target.value = e.target.value.slice(0, 4);
                                            } 
                                            if (parseInt(e.target.value) > 100) {
                                                e.target.value = '100';
                                            }
                                        }}
                                        />
                                    <span style={{ color: "red" }}>{errors.discount}</span>
                                </div>


                                <div className="col-sm-6">
                                    <label className="form-label mb-3">Duration In Months</label>
                                    <select name='duration_in_months'  className={`form-select mb-2 py-2
                                   ${((errors.duration_in_months) && (formData.duration_in_months === '')) && 'error_active'}
                                   ${((errors.duration_in_months === '') && (formData.duration_in_months)) && 'sucess_active'}`}
                                    aria-label="Default select example" onChange={handleChange} >
                                      <option value=" " hidden>Select duration in Months</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                    <span style={{ color: "red" }}>{errors.duration_in_months}</span>
                                </div>


                                <div className="col-sm-6">
                                    <label className="form-label mb-3">Max Redemptions <span>
                                        <OverlayTrigger
                                            trigger="click"
                                            rootClose
                                            placement="right"
                                            overlay={popoverClickRootClose}
                                            containerPadding={10}
                                        >
                                            <i class="fa-solid fa-circle-info" style={{cursor:"pointer"}}></i>
                                        </OverlayTrigger>
                                        
                                    </span></label>
                                    <input type="number" name='max_redemptions'  value={formData?.max_redemptions} placeholder="Enter Numbers of Redemptions"
                                        className={`form-control mb-2 py-2
                                     ${((errors.max_redemptions) && (formData.max_redemptions === '')) && 'error_active'}
                                     ${((errors.max_redemptions === '') && (formData.max_redemptions)) && 'sucess_active'}`}
                                        onChange={handleChange} 
                                        onInput={(e) => {
                                            if (e.target.value.length > 8) {
                                              e.target.value = e.target.value.slice(0, 8);
                                            }
                                          }}
                                        />
                                    <span style={{ color: "red" }}>{errors.max_redemptions}</span>
                                   
                                </div>
                                
                                <div className="col-12 mt-5">
                                    <div className=' d-flex align-items-center  flex-wrap'  style={{ gap: '15px' }}>
                                        {/* <span>button</span>
                                        <span>button</span> */}
                                    <button type="submit" className="promo_add_new " style={{ border: '2px solid #0F66B4' }}  disabled={spinner} >Add Promo Code {spinner ? (<i className="fa-solid fa-spinner fa-spin"></i>) : null}</button>

                                    <Link to="/admin/promo-code" className="promo_add_new fw-bold px-4 text-decoration-none " style={{ background: 'transparent', color: '#0F66B5', border: '2px solid #0F66B5' }} >Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-4 col-xl-6"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PromoCode