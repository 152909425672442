import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Table, Dropdown, Button } from "react-bootstrap";
import ResponsivePagination from 'react-responsive-pagination';
import { BallTriangle } from 'react-loader-spinner';
import { Link, NavLink, useNavigate } from "react-router-dom";
import AdminsersForm from "./AdminsersForm.jsx";
import toast from 'react-hot-toast';
import Swal from "sweetalert2";
import AdminUserView from './adminuser/AdminUserView.jsx';
import { useDispatch } from 'react-redux';
import { setPageNumber } from '../../redux/slice/firmdNumber.js';
import CustomPagination from '../../components/pagination/CustomPagination.jsx';
const
  AdminUser = (props) => {
    let token;
    const render = useRef(0);
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState("");
    const [editId, setEditId] = useState();
    const [editOpen, setEditOpen] = useState(false);
    const [viewOpen, setViewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userLists, setUserList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [sort, setSort] = useState(null);
    const [orderName, setOrderName] = useState("");
    const [sortName, setSortName] = useState(null);
    const [sortStatus, setSortNameStatus] = useState(null);
    const [goToPage, setGoTOPage] = useState(0);
    const [spinner, setSpinner] = useState(false);

    const [shortName, setshortName] = useState(null);

    const dispatch = useDispatch()

    const handlePageSearch = (val) => {
      const inputPage = parseInt(val);
      if (!isNaN(inputPage) && inputPage > 0 && inputPage <= totalPages) {
        setCurrentPage(inputPage);
      } else if (inputPage >= totalPages) {
        toast.error(`Page ${inputPage} does not exist`)
      }
    };
    

    dispatch(setPageNumber(1));

    const userData = JSON.parse(window.localStorage.getItem("loginAdmin"));

    useEffect(() => {
      document.title = "PCMA || PCMA Members Management";
    }, []);

    const ascendingOrder = (event) => {
      setSortNameStatus(null);
      setSortName(null);
      if (event === "name") {
        setOrderName("name");
        setshortName(0);
        setSort(0);
        setCurrentPage(1);
      }
    };

    const descendingOrder = (event) => {
      setSortNameStatus(null);
      setSortName(null);

      if (event === "name") {
        setOrderName("name");
        setSort(1);
        setshortName(1);
        setCurrentPage(1);
      }

    };

    const changeSearchQuery = (e) => {
      e.preventDefault();
      const value = e.target.value;
      // const filteredValue = value.replace(/\s+/g, '');
      // setSearchQuery(e.target.value?.trimStart());
      setSearchQuery(value?.trimStart());
      setCurrentPage(1)

      // const value = event.target.value;
      // Remove spaces from the input value
      // const filteredValue = value.replace(/\s+/g, '');
      // setSearchQuery(filteredValue);
    }






    // const handleSortingName = (event) => {
    //   if (event === "name") {
    //     setOrderName("name");
    //     if (sort === null) {
    //       setSort(0);
    //       setshortName(0);
    //       setCurrentPage(1);
    //     }
    //     else if (sort === 1) {
    //       setSort(0);
    //       setshortName(0);
    //       setCurrentPage(1);
    //     }
    //     else if (sort === 0) {
    //       setSort(1);
    //       setshortName(1);
    //       setCurrentPage(1);
    //     }
    //   }
    // };


    useEffect(() => { redirectToLogin(); });

    const redirectToLogin = () => {
      token = userData?.Token || null;
      if (token === null) {
        navigate('/admin/login');
      }
    };


    const handlePageChange = (page) => { setCurrentPage(page); };

    const fetchData = () => {
      setIsLoading(true);
      axios
        .get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/all-users`, {
          params: {
            page: currentPage,
            search: searchQuery,
            order_name: orderName,
            order_type: sort
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data?.message === "Unauthozied User") {
            localStorage.removeItem('loginAdmin');
            navigate("/admin");
            toast.error(`Unauthozied user !`);
          }

          if (response?.data?.message === "No data found") {
            setUserList([])
          } else {
            setUserList(response?.data?.total_users?.data);
          }

          setTotalPages(response?.data?.total_users?.last_page);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response && error.response.status === 401) {
            localStorage.removeItem("loginAdmin");
            navigate("/admin/login");
          }
        });
    };

    const exportUserCSV = () => {
      setSpinner(true);    
      axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/all-users-export?search=${searchQuery}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },

      }).then(response => {
        let CSVData = response?.data
        const blob = new Blob([CSVData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'PCMA Members Details.csv');
        document.body.appendChild(link);
        link.click();
        setSpinner(false);
      }).catch(error => {
        if (error?.message === 'Request failed with status code 401') {
          localStorage.removeItem("loginAdmin");
          navigate("/admin/login");
          toast.error(`Unauthozied user !`);
        } else {          
          setSpinner(false);
          console.error('Error fetching data:', error);
        }

      });
    };

    // const exportRegistrantCSV = async () => {
    //   setSpinner(true);
    //   try {
    //     const response = await axios.g(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/export-registrants`, {
    //       search: search
    //     }, {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     })
    //     if (response?.data?.message === "Unauthozied user") {
    //       localStorage.removeItem('login');
    //       navigate("/");
    //       toast.error(`Unauthozied user !`);
    //     }
    //     let csvResponseData = response?.data;
    //     const blob = new Blob([csvResponseData], { type: 'text/csv;charset=utf-8;' });
    //     const link = document.createElement('a');
    //     link.href = URL.createObjectURL(blob);
    //     link.download = 'PCMA Registrants Details.csv';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     setSpinner(false);

    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //     if (error?.message === 'Request failed with status code 401') {
    //       localStorage.removeItem("loginAdmin");
    //       navigate("/admin/login");
    //       toast.error(`Unauthozied user !`);
    //     }
    //     setSpinner(false);
    //   }

    // };




    // useEffect(() => {
    //   fetchData();
    // }, []);

    useEffect(() => {
      let timerOut = setTimeout(() => {
        fetchData();
        // if (render.current !== 0) {
        //   if (props?.currentPage === 1) {
        //     fetchData();
        //   } else {
        //     props?.setUserCurrentPage(1);
        //   }
        // }
        // render.current++;

      }, 1000);

      return () => clearTimeout(timerOut)
    }, [searchQuery, sort, currentPage])



    const toggleStatus = (id, currentStatus) => {
      setIsLoading(true);
      axios.put(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/user-status/${id}`, {
        status: !currentStatus
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response?.data?.message === "Unauthozied User") {
            localStorage.removeItem('loginAdmin');
            navigate("/admin");
            toast.error(`Unauthozied user !`);
          }

          const updatedStats = userLists?.map(user => user._id === id ? { ...user, status: !currentStatus } : user);
          setUserList(updatedStats);
          toast.success(response?.data?.message);
          fetchData();
          setIsLoading(false);
        })
        .catch(error => {
          console.log('apiErrors', error);
          setIsLoading(false);
        });
    }


    const editUser = (e) => {
      setEditOpen(!editOpen); setEditId(e);
      if (editOpen == true) {
        fetchData();
      }
    }

    const viewUser = (e) => { setViewOpen(!viewOpen); setEditId(e); }


    const deleteUser = async (id) => {
      try {
        const confirmDeletion = await Swal.fire({
          title: "Delete User",
          text: "Do you want to delete this user? This action cannot be undone.",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#D0D5DD",
          confirmButtonColor: "#D92D20",
          confirmButtonText: "Delete",
          iconHtml: '<i class="fas fa-trash-alt"></i>',
          iconColor: "red",
        });
        if (!confirmDeletion.isConfirmed) return;
        // setIsLoading(true);
        const response = await axios.delete(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/user-delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        );
        fetchData();
        if (response.data.message === "User deleted successfully") {
          toast.success(response?.data?.message);
          setIsLoading(true);
        } else {
          toast.error(response?.data?.message);
        }
        //  toast.success(response?.data?.message);
        //  setIsLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong please try again later.");
      }
    };



    const handleSorting = (event) => {
      let newSort = 1;
      setSortName(null)
      setshortName(null)
      setSortNameStatus(event)

      if (event === "status") {
        setOrderName(event);
        if (sort === null) {
          newSort = 1;
        } else {
          newSort = sort === 1 ? 0 : 1;
        }
        setSort(newSort);
        setCurrentPage(1);
      }
      if (event === "status") {
        setOrderName(event);
        if (sort === null) {
          setSort(0);
          setSortNameStatus(0);
          setCurrentPage(1);
        }
        else if (sort === 1) {
          setSort(0);
          setSortNameStatus(0);
          setCurrentPage(1);
        }
        else if (sort === 0) {
          setSort(1);
          setSortNameStatus(1);
          setCurrentPage(1);
        }
      }
    };

    const verifiedHandleSorting = (event) => {
      setSortNameStatus(null)
      setshortName(null)
      if (event === "verified") {
        setOrderName(event);
        if (sort === null) {
          setSort(0);
          setSortName(0);
          setCurrentPage(1);
        }
        else if (sort === 1) {
          setSort(0);
          setSortName(0);
          setCurrentPage(1);
        }
        else if (sort === 0) {
          setSort(1);
          setSortName(1);
          setCurrentPage(1);
        }
      }

    };

    const handlereset = () => {
      setOrderName("");
      setSort(null);
      setSortName(null);
      setSortNameStatus(null);
      setSearchQuery("");
      setshortName(null)
    };


    return (
      <>

        <>
          {editOpen ? (
            <>
              <AdminsersForm
                editUserId={editId}
                sendEditOpenData={editOpen}
                sendeditOpen={editUser}
              />
            </>
          ) : viewOpen ? (
            <>
              <AdminUserView
                editUserId={editId}
                sendEditOpenData={editOpen}
                sendeditOpen={viewUser}
              />
            </>
          ) : (

            <>
              <Container fluid>
                <Row className="mt-2">
                  <Col style={{ background: "#137CD9", color: "#fff", padding: "8px", marginTop: "35px", borderRadius: "5px", }}>

                        <h2 className="userManagement">PCMA Members Management</h2>
                  </Col>
                </Row>
                <Row className="mt-3 ps-0">
                  <Col className="d-flex flex-wrap align-items-center justify-content-center justify-content-sm-start ps-0">
                    {/* <div className="add_user">
                         <button className='btn_add_user btn'  onClick={() => { navigate("/admin/pcmamembers/add");}}><i className="fa-solid fa-user-plus"></i> Add User </button>
                        </div> */}
                    <div className="admin_user_search m-2 searchOutline wrappppp ms-0">
                      <input
                        type="search"
                        name=""
                        id=""
                        className="adminSearchBar"
                        style={{paddingRight:"32px"}}
                        placeholder="Search"
                        value={searchQuery?.trimStart()}
                        onChange={changeSearchQuery}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            if (searchQuery.length > 0) {
                              fetchData();
                              e.target.blur();
                            };                          
                          }
                        }}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>


                    {/* <div className="admin_user_search m-2 searchOutline wrappppp">
                        <input
                          type="search"
                          name=""
                          id=""
                          className="adminSearchBar"
                          placeholder="Search"
                          value={searchQuery?.trimStart()}
                          onChange={changeSearchQuery}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              fetchData();
                            }
                          }}
                        />
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div> */}

                    {/* <Dropdown className="mx-3">
                        <Dropdown.Toggle
                          className="dropdown_button border-0"
                          style={{ backgroundColor: "#C0C0C0" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="14"
                            viewBox="0 0 24 14"
                            fill="none"
                          >
                            <path
                              d="M1.17188 2.34375H22.2656C22.5764 2.34375 22.8745 2.22029 23.0943 2.00052C23.314 1.78075 23.4375 1.48268 23.4375 1.17188C23.4375 0.861074 23.314 0.563003 23.0943 0.343234C22.8745 0.123465 22.5764 0 22.2656 0H1.17188C0.861074 0 0.563004 0.123465 0.343235 0.343234C0.123466 0.563003 0 0.861074 0 1.17188C0 1.48268 0.123466 1.78075 0.343235 2.00052C0.563004 2.22029 0.861074 2.34375 1.17188 2.34375ZM5.07812 7.8125H18.3594C18.6702 7.8125 18.9682 7.68903 19.188 7.46926C19.4078 7.2495 19.5312 6.95143 19.5312 6.64062C19.5312 6.32982 19.4078 6.03175 19.188 5.81198C18.9682 5.59222 18.6702 5.46875 18.3594 5.46875H5.07812C4.76732 5.46875 4.46925 5.59222 4.24949 5.81198C4.02972 6.03175 3.90625 6.32982 3.90625 6.64062C3.90625 6.95143 4.02972 7.2495 4.24949 7.46926C4.46925 7.68903 4.76732 7.8125 5.07812 7.8125ZM9.76562 13.2812H13.6719C13.9827 13.2812 14.2807 13.1578 14.5005 12.938C14.7203 12.7182 14.8438 12.4202 14.8438 12.1094C14.8438 11.7986 14.7203 11.5005 14.5005 11.2807C14.2807 11.061 13.9827 10.9375 13.6719 10.9375H9.76562C9.45482 10.9375 9.15675 11.061 8.93699 11.2807C8.71722 11.5005 8.59375 11.7986 8.59375 12.1094C8.59375 12.4202 8.71722 12.7182 8.93699 12.938C9.15675 13.1578 9.45482 13.2812 9.76562 13.2812Z"
                              fill="white"
                            />
                          </svg>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <div className="d-flex">
                            <p
                              className="ms-4 mb-0"
                              style={{
                                color: '#137CD9',
                                fontSize: '16px',
                                fontWeight: '700',
                              }}
                            >
                              SORT BY
                            </p>
                          </div>
                          <Dropdown.Item
                            className={`ascending_order ${sort === 0 ? 'selected' : ''}`}
                            style={{
                              color: '#1E1E1E',
                              fontSize: '14px',
                              fontWeight: '400',
                              backgroundColor: sort === 0 ? '#F0F0F0' : 'transparent',
                            }}
                            onClick={() => ascendingOrder('name')}
                          >
                            <p className="mb-0">Ascending (A to Z)</p>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className={`descending_order ${sort === 1 ? 'selected' : ''}`}
                            style={{
                              color: '#1E1E1E',
                              fontSize: '14px',
                              fontWeight: '400',
                              backgroundColor: sort === 1 ? '#F0F0F0' : 'transparent',
                            }}
                            onClick={() => descendingOrder('name')}
                          >
                            <p className="mb-0">Descending (Z to A)</p>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}



                    {/* <div className="">
                        <button
                          className="reset_button btn"
                          onClick={handlereset}
                        >
                          <i
                            className="fa-solid fa-arrow-rotate-right"
                            style={{ color: "#0F66B4" }}
                          ></i>
                        </button>
                      </div> */}

                    <div className="tooltip-container resetTooltip">
                      <button
                        className="reset_button btn"
                        onClick={handlereset}
                        style={{ cursor: 'pointer' }}
                      >
                        <i
                          className="fa-solid fa-arrow-rotate-right"
                          style={{ color: "#0F66B4" }}
                        ></i>
                      </button>
                      <div className="tooltip-text">Reset</div>
                    </div>



                  </Col>
                  <Col sm={12} md={6} lg={6} className="d-flex  py-2 justify-content-sm-end justify-content-center align-items-center ps-0 registrantSearchBar areaFlex">
                      <div className="" style={{ paddingLeft: "10px" }}>
                          {spinner ? (<button className="p-2 adminadd_btn" style={{ color: '#fff', backgroundColor: '#0F66B4', cursor: 'not-allowed', opacity: "0.9" }} disabled={spinner} >Loading<i className="fa-solid fa-spinner fa-spin ms-1"></i></button>) :
                            userLists?.length === 0 ? (<button className="p-2 adminadd_btn" style={{ color: '#fff', backgroundColor: '#0F66B4', cursor: 'not-allowed', opacity: "0.9" }} disabled ><i className="fa-solid fa-download my-1" style={{ color: '#fff', }}></i>Export</button>) :
                              (<button className="p-2 adminadd_btn" style={{ color: '#fff', backgroundColor: '#0F66B4', cursor: 'pointer' }} onClick={() => { exportUserCSV() }}><i className="fa-solid fa-download my-1" style={{ color: '#fff', }}></i>Export</button>)}   
                    </div>
                  </Col> 
                </Row>

                {isLoading ? (
                  <Container fluid className="loader">
                    <Row
                      className="g-2 pb-3 d-flex justify-content-center align-content-center "
                      style={{ height: "79vh" }}
                    >
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        className="d-flex justify-content-center align-content-center"
                      >
                        <BallTriangle
                          height={100}
                          width={100}
                          radius={5}
                          color="#0f66b4"
                          ariaLabel="ball-triangle-loading"
                          wrapperClass={{}}
                          wrapperStyle=""
                          visible={true}
                        />
                      </Col>
                    </Row>
                  </Container>
                ) : (
                  <Row className="mt-2">
                    <Col>
                      <div className=" d-flex justify-content-end"></div>
                    </Col>

                    <Col
                      xs={12}
                      md={12}
                      lg={12}
                      // style={{
                      //   background: "#fff",
                      //   border: "2px solid rgba(15, 102, 180, 0.2)",
                      //   borderRadius: "5px",
                      // }}
                      className="py-1 ps-0"
                    >
                      <div className=" table-responsive firms_card_box">
                        <Table className="notice_table">
                          <thead >
                            <tr class="border-0 mt-2">
                              <th
                                className="ps-4"
                                style={{ background: "#DDEBF2", color: '#137CD9', borderRadius: "5px 0 0 0px", border: "0", fontWeight: "700", fontSize: "17px", borderBottom: "0" }}

                              >
                                NAME

                                <span
                                   style={{ cursor: userLists?.length > 0 ? 'pointer' :"default" }}
                                  className="ms-2"

                                >
                                  {shortName === 0 ? (
                                    <i className="fa-solid fa-arrow-up-a-z" onClick={() => userLists?.length > 0 && descendingOrder('name')}></i>
                                  ) : shortName === 1 ? (
                                    <i className="fa-solid fa-arrow-down-z-a" onClick={() =>userLists?.length > 0 &&  ascendingOrder("name")}></i>
                                  ) : (
                                    <i className="fa-solid fa-arrow-right-arrow-left fa-rotate-90" onClick={() =>userLists?.length > 0 &&  ascendingOrder("name")}></i>
                                  )}
                                </span>

                              </th>
                              <th
                                className="text-center"
                                style={{ background: "#DDEBF2", color: '#137CD9', border: "0", fontWeight: "700", fontSize: "17px", borderBottom: "0", }}
                              >
                                STATUS
                                <span
                                  className="ms-1"
                                 style={{ cursor: userLists?.length > 0 ? 'pointer' :"default" }}
                                   onClick={() => userLists?.length > 0 && handleSorting("status")}
                                >
                                  {sortStatus === 1 ? <i className="fa-solid fa-arrow-up"></i> : sortStatus === 0 ? <i className="fa-solid fa-arrow-down"></i> : <i className="fa-solid fa-arrow-right-arrow-left fa-rotate-90"></i>}
                                </span>
                              </th>
                              <th
                                style={{ background: "#DDEBF2", color: '#137CD9', borderRadius: "0 5px 0 0 ", border: "0", fontWeight: "700", fontSize: "17px", borderBottom: "0", textAlign: "center" }}
                              >
                                VERIFIED
                                <span
                                  className="ms-1"
                                  style={{ cursor: userLists?.length > 0 ? 'pointer' :"default" }}
                                 onClick={() =>userLists?.length > 0 &&  verifiedHandleSorting("verified") }
                                >
                                  {sortName === 1 ? <i className="fa-solid fa-arrow-up"></i> : sortName === 0 ? <i className="fa-solid fa-arrow-down"></i> : <i className="fa-solid fa-arrow-right-arrow-left fa-rotate-90"></i>}
                                </span>
                              </th>
                              <th
                                className="text-center"
                                style={{ background: "#DDEBF2", color: '#137CD9', borderRadius: "0 5px 0 0", border: "0", fontWeight: "700", fontSize: "17px", borderBottom: "0" }}
                              >
                                ACTION
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {userLists?.length === 0 ? (
                              <tr>
                                <td colSpan="4" className="text-center">
                                  <div className="d-flex align-items-center justify-content-center w-100">
                                    No Data Found
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              userLists?.map((useList, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="">
                                      <div className="user_image d-flex align-items-center fw-bold">
                                        {useList?.profile_picture ? (
                                          <img
                                            src={useList?.profile_picture}
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src="../asset/users.png"
                                            alt=""
                                          />
                                        )}

                                        <span>{useList.name}</span>
                                      </div>
                                    </td>
                                    <td className="text-center ps-0">
                                      <div className="h-100 d-flex justify-content-center align-items-center">
                                        <Form className="form-check form-switch">
                                          <input
                                            className="form-check-input"
                                            onChange={() =>
                                              toggleStatus(
                                                useList._id,
                                                useList.status
                                              )
                                            }
                                            type="checkbox"
                                            role="switch"
                                            id={`flexSwitchCheckChecked${index}`}
                                            checked={useList?.status}
                                          />
                                          {useList?.status
                                            ? "Enabled"
                                            : "Disabled"}
                                        </Form>
                                      </div>
                                    </td>

                                    <td
                                      className="ps-0"
                                      style={{
                                        textAlign: "center",
                                        color: useList?.verified
                                          ? "green"
                                          : "red",
                                      }}
                                    >
                                      <span>
                                        {useList?.verified
                                          ? "Verified"
                                          : "Not Verified"}
                                      </span>
                                    </td>

                                    <td className="text-center">
                                      <div className=" d-flex align-items-center justify-content-center">
                                        <NavLink
                                          to={`/admin/pcmamembers/${useList._id}`}
                                          className=""
                                        >
                                          <i
                                            className="fa-solid fa-eye me-3"
                                            style={{
                                              color: "#0F66B4",
                                              cursor: "pointer",
                                            }}
                                            
                                          ></i>
                                        </NavLink>
                                        {/* <i
                                          className="fa-solid fa-eye me-3"
                                          style={{
                                            color: "#0F66B4",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            viewUser(useList?._id);
                                          }}
                                        ></i> */}
                                        <NavLink
                                          to={`/admin/pcmamembers-edit/${useList?._id}`}
                                          className=""
                                        >
                                          <i
                                            className="fa-solid fa-edit  me-3"
                                            style={{
                                              cursor: "pointer",
                                              color: "#0F66B4",
                                            }}
                                          ></i>
                                        </NavLink>
                                        <NavLink
                                          onClick={() =>
                                            deleteUser(useList?._id)
                                          }
                                        >
                                          {" "}
                                          <i
                                            className="fa-solid fa-trash"
                                            style={{
                                              cursor: "pointer",
                                              color: "#FF6969",
                                            }}
                                          ></i>
                                        </NavLink>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                )}
              </Container>
              <Container fluid className="py-2 mb-3">
                {userLists?.length === 0 ? (
                  <p> </p>
                ) : (
                  <>
                    <Row>

                      {/* <Col
                        sm={12}
                        className="d-flex justify-content-start global_pagenation"
                        style={{ padding: 0 }}
                      >
                        <CustomPagination
                          maxWidth={7}
                          total={totalPages}
                          current={currentPage}
                          onPageChange={(page) => handlePageChange(page)}
                        />
                      </Col> */}

<Col
                      sm={12}
                      className="d-flex justify-content-between pagenation_bar mt-3"
                      style={{ padding: 0 }}
                    >
                      <div>
                        <CustomPagination
                          maxWidth={7}
                          total={totalPages}
                          current={currentPage}
                          onPageChange={(page) => handlePageChange(page)}
                        />

                      </div>

                      <div>
                        {totalPages > 3 ?
                          <ul className=" list-unstyled new_side_pagination  justify-content-center mt-3">
                            <li style={{ whiteSpace: 'nowrap' }}>Go to page</li>
                            <li>
                              <label htmlFor="pageNumber">
                                <input
                                  type="number"
                                  name=""
                                  id="pageNumber"
                                  placeholder={currentPage}
                                  onChange={(e) => {
                                    setGoTOPage(e.target.value);
                                  }} />
                              </label>
                            </li>
                            <li>
                              <div onClick={() => { handlePageSearch(goToPage) }}>
                                <i className="fa-solid fa-arrow-right"></i>
                              </div>
                            </li>
                          </ul>
                          : ""}
                      </div>


                    </Col>
                    </Row>
                  </>
                )}
              </Container>
            </>
          )}
        </>

      </>
    );
  }

export default AdminUser;
// editUserId={editId}
// sendEditOpenData={editOpen}
// sendeditOpen={editUser}