import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button, NavLink, Modal } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../redux/slice/userData";
import { fetchNavs } from "../../redux/slice/navbar.js";
import "./Menu.css";

const Navbar = (props) => {
  let localUserId;
  let token;

  const navigate = useNavigate();
  const userData = JSON.parse(window.localStorage.getItem("login"));

  const [notificationName, setNotificationName] = useState("");
  const [notificationDate, setNotificationDate] = useState("");
  const [notificationmessage, setNotificationmessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [noticeDatas, setNoticeData] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const menuData = useSelector((state) => state?.menus?.data);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  if (userData) {
    token = userData.token;
  }
  
  if (userData?.user_data?._id) {
    localUserId = userData?.user_data?._id;
  }

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  useEffect(() => {
    dispatch(fetchNavs());
  }, []);

  const handleMenuClick = (url) => {
    if (!url) return;
    // Check if the URL is for the WordPress site
    if (url.includes("wpenginepowered.com")) {
      const loginData = localStorage.getItem("login");

      if (loginData) {
        try {
          const { token } = JSON.parse(loginData);

          // Append the token as a query parameter
          const separator = url.includes("?") ? "&" : "?";
          const finalUrl = `${url}${separator}auth_token=${encodeURIComponent(
            token
          )}`;

          console.log("Redirecting to:", finalUrl); // Debug log
          window.location.href = finalUrl; // Redirect to the URL with the token
        } catch (error) {
          console.error("Error processing login data:", error);
          window.location.href = url; // Fallback if there's an error
        }
      } else {
        console.log("No login data found, redirecting without token");
        window.location.href = url; // Fallback if no login data
      }
    } else {
      navigate(url); // For internal links, use normal navigation
    }
  };

  useEffect(() => {
    dispatch(fetchUserData({ localUserId, token }));
    //remove logout menu
    // document.addEventListener("click", () => {
    //   const dropdownMenu = document.querySelector(".profile_menu");
    //   dropdownMenu.classList.remove("active");
    // });
  }, [dispatch, localUserId, token]);

  const userDatas = useSelector((state) => state.user);
  const fetchNoticData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/all-notice-data-user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem("login");
        navigate("/");
        // toast.error("Unauthozied user !");
      }
      setNoticeData(response?.data?.notice_data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchNoticData();
    // fetchDataGet();
  }, []);

  const formatNoticeData = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return (
        date.getDate() +
        (date.getDate() % 10 === 1 && date.getDate() !== 11
          ? "st"
          : date.getDate() % 10 === 2 && date.getDate() !== 12
          ? "nd"
          : date.getDate() % 10 === 3 && date.getDate() !== 13
          ? "rd"
          : "th") +
        " " +
        date.toLocaleDateString("en-US", { month: "long", year: "numeric" })
      );
    }
  };

  const handleNoticeModal = (data) => {
    setShowModal(true);
    const noticeDate = formatNoticeData(data?.updated_at);
    setNotificationDate(noticeDate);
    setNotificationName(data?.name);
    setNotificationmessage(data?.message);
  };

  const sidbarToggle = () => {
    props.sidbarToggle(!props.sidebarToggleData);
  };

  const logout = () => {
    localStorage.removeItem("login");
    toast.success("You have logged out successfully.");
    navigate("/login");
  };

  const [activeFirstMenu, setActiveFirstMenu] = useState(null);
  const [activeSecondMenu, setActiveSecondMenu] = useState(null);
  const [activeThirdMenu, setActiveThirdMenu] = useState(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleFirstMenu = (index) => {
    setActiveFirstMenu(activeFirstMenu === index ? null : index);
  };

  const toggleSecondMenu = (index) => {
    setActiveSecondMenu(activeSecondMenu === index ? null : index);
  };

  const toggleThirdMenu = (index) => {
    setActiveThirdMenu(activeThirdMenu === index ? null : index);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const checkSiblings = (index) => {
    const menuItems = document.querySelectorAll(".main_menu > li");
    const currentItem = menuItems[index];
    let siblingCount = 0;
    let nextSibling = currentItem.nextElementSibling;

    while (nextSibling) {
      siblingCount++;
      nextSibling = nextSibling.nextElementSibling;
    }

    return siblingCount < 4;
  };

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMobileMenuOpen(false);
      }
    };

    document.body.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.body.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const menuRef2 = useRef(null);

  useEffect(() => {
    const handleClickOutside2 = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveFirstMenu(false);
        setActiveSecondMenu(false);
        setActiveThirdMenu(false);
      }
    };

    document.body.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.body.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  return (
    <>
      <Row className="gx-2 w-100">
        <Col className="col-2  order-1 d-flex align-items-center justify-content-start py-3">
          <Link to="/" className="ms-2">
            <img src="/asset/logo.png" className="header_img_logo" alt="" />
          </Link>
        </Col>

        {/* <Col className="col-9 col-md-6 col-lg-4 order-3 order-lg-2  d-flex align-items-center justify-content-start">
          <div className="search_box"></div>
        </Col> */}

        <Col className=" col-10  col-lg-10 order-2 order-lg-3 d-flex align-items-center justify-content-end">
          {/* <div className="icons-top-bar d-flex align-items-center justify-content-around"></div> */}
          <div
            ref={menuRef}
            className={`menu_area justify-content-xl-end ${
              isMobileMenuOpen ? "show" : ""
            }`}
          >
            <div className="menu_area_menu" ref={menuRef2}>
              <ul className="main_menu mb-0">
                {menuData?.map((menu, index) =>
                  menu?.children?.length > 0 ? (
                    <li className="has_menu_first" key={index}>
                      <Link
                        to={menu?.url ? menu?.url : "#"}
                        style={{ cursor: menu?.url ? "pointer" : "default" }}
                        className={`has_menu_link ${
                          activeFirstMenu === index ? "active" : ""
                        }`}
                        onClick={() => toggleFirstMenu(index)}
                      >
                        {menu?.title}
                      </Link>
                      {activeFirstMenu === index && (
                        <ul
                          className={`second_menu ${
                            checkSiblings(index) && window.innerWidth > 1199
                              ? "left"
                              : ""
                          } ${activeFirstMenu === index ? "show" : ""}`}
                        >
                          {menu?.children?.map((subMenu, subIndex) =>
                            subMenu?.children?.length > 0 ? (
                              <li className="has_menu_second" key={subIndex}>
                                <Link
                                  // to={subMenu?.url ? subMenu?.url : ""}
                                  style={{
                                    cursor: subMenu?.url
                                      ? "pointer"
                                      : "default",
                                  }}
                                  className={`has_menu_link ${
                                    activeSecondMenu === subIndex
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => toggleSecondMenu(subIndex)}
                                >
                                  {subMenu?.title}
                                </Link>
                                {activeSecondMenu === subIndex && (
                                  <ul
                                    className={`third_menu ps-0 py-2 ${
                                      activeSecondMenu === subIndex
                                        ? "show"
                                        : ""
                                    }`}
                                  >
                                    {subMenu?.children?.map(
                                      (items, itemIndex) =>
                                        items?.children?.length > 0 ? (
                                          <li
                                            key={itemIndex}
                                            className="has_menu_third"
                                          >
                                            <Link
                                              to={items?.url ? items?.url : "#"}
                                              style={{
                                                cursor: items?.url
                                                  ? "pointer"
                                                  : "default",
                                              }}
                                              className={`has_menu_link ${
                                                activeThirdMenu === itemIndex
                                                  ? "active"
                                                  : ""
                                              }`}
                                              onClick={() =>
                                                toggleThirdMenu(itemIndex)
                                              }
                                            >
                                              {items?.title}
                                            </Link>
                                            {activeThirdMenu === itemIndex && (
                                              <ul
                                                className={`last_menu ${
                                                  activeThirdMenu === itemIndex
                                                    ? "show"
                                                    : ""
                                                }`}
                                              >
                                                {items?.children?.map(
                                                  (hoverMenu, hoverIndex) => (
                                                    <li key={hoverIndex}>
                                                      <Link
                                                        to={hoverMenu?.url}
                                                        style={{
                                                          cursor: hoverMenu?.url
                                                            ? "pointer"
                                                            : "default",
                                                        }}
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          handleMenuClick(
                                                            hoverMenu?.url
                                                          );
                                                        }}
                                                      >
                                                        {hoverMenu?.title}
                                                      </Link>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            )}
                                          </li>
                                        ) : (
                                          <li key={itemIndex}>
                                            <Link
                                              to={items?.url ? items?.url : "#"}
                                              style={{
                                                cursor: items?.url
                                                  ? "pointer"
                                                  : "default",
                                              }}
                                              className="has_only_link"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleMenuClick(items?.url);
                                              }}
                                            >
                                              {items?.title}
                                            </Link>
                                          </li>
                                        )
                                    )}
                                  </ul>
                                )}
                              </li>
                            ) : (
                              <li key={subIndex}>
                                <Link
                                  to={subMenu?.url ? subMenu?.url : "#"}
                                  style={{
                                    cursor: subMenu?.url
                                      ? "pointer"
                                      : "default",
                                  }}
                                  className="has_only_link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleMenuClick(subMenu?.url);
                                  }}
                                >
                                  {subMenu?.title}
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </li>
                  ) : (
                    <li key={index}>
                      <Link
                        to={menu?.url ? menu?.url : "#"}
                        style={{ cursor: menu?.url ? "pointer" : "default" }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleMenuClick(menu?.url);
                        }}
                      >
                        {menu?.title}
                      </Link>
                    </li>
                  )
                )}

                {/* <li className="has_menu_first ms-xl-2">
                  <Link to="#" className="btn btn-primary py-2 px-3 text-white">
                    Contact Us
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="menu_area_button d-flex justify-content-end align-items-center">
            {/* <button className="btn btn-primary">Sign In</button>
            <button className="btn btn-outline-primary">Join Now</button> */}
            <div className="dropdown align-items-center mt-3">
              <div
                data={noticeDatas?.length}
                className="notice_svg me-3"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  style={{ cursor: "pointer" }}
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    d="M23.4375 12.1375V11.2563C23.4375 6.42 19.6575 2.5 15 2.5C10.3425 2.5 6.5625 6.42 6.5625 11.2563V12.1375C6.56401 13.1894 6.26381 14.2198 5.6975 15.1062L4.3125 17.2625C3.04875 19.2313 4.01375 21.9075 6.2125 22.53C11.9576 24.1587 18.0424 24.1587 23.7875 22.53C25.9863 21.9075 26.9513 19.2313 25.6875 17.2638L24.3025 15.1075C23.7358 14.2211 23.4351 13.1908 23.4363 12.1388L23.4375 12.1375Z"
                    fill="#A7ADB4"
                  />
                  <path
                    d="M9.375 22.75C10.1937 24.935 12.4025 27.5 15 27.5C17.5975 27.5 19.8062 24.935 20.625 22.75"
                    fill="#A7ADB4"
                  />
                </svg>
              </div>
              <ul
                className="dropdown-menu notice_data py-0"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="p-3 border-bottom">
                  <p className="mb-0 d-flex align-items-center justify-content-between">
                    Notifications
                  </p>
                </li>

                <li>
                  <ul className=" list-unstyled p-0 notice_data_item_box">
                    {noticeDatas?.map((noticeData, index) => {
                      return (
                        <li key={index}>
                          <Link
                            className="dropdown-item"
                            onClick={() => {
                              handleNoticeModal(noticeData);
                            }}
                          >
                            <p className="notice_data_item mb-0">
                              {noticeData?.name}
                            </p>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </div>
            <div className="d-flex flex-row top-bar-dashboard">
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle p-0 ms-2 drop_down_dasboard d-flex justify-content-between align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    backgroundColor: "transparent",
                    border: "transparent",
                  }}
                >
                  {/* <div className="data-user text-dark d-flex justify-content-center flex-column me-3">
                    <h5 className="text-end mb-0">
                      {capitalizeFirstLetter(userDatas.name)}
                    </h5>
                    <h6 className="mb-0 text-end">{userDatas.address}</h6>
                  </div> */}
                  {userDatas?.profilePicture?.length > 0 ? (
                    <span
                      style={{
                        border: "1px solid black",
                        borderRadius: "50%",
                        transition: "0.3s",
                      }}
                      className="j_header_profile_img"
                    >
                      <img
                        className="fluid"
                        src={`${userDatas?.profilePicture}`}
                        alt="Profile"
                        style={{
                          height: "3rem",
                          width: "3rem",
                          borderRadius: "100px",
                        }}
                      />
                    </span>
                  ) : (
                    <span
                      className="j_header_profile_img"
                      style={{
                        border: "1px solid black",
                        borderRadius: "50%",
                        transition: "0.3s",
                      }}
                    >
                      <img
                        src="/asset/users.png"
                        className="fluid"
                        alt="Default Profile"
                        style={{
                          height: "3rem",
                          width: "3rem",
                          borderRadius: "100px",
                        }}
                      />
                    </span>
                  )}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <a
                      className="dropdown-item d-flex justify-content-between align-items-center"
                      href="/bookmarks"
                    >
                      Bookmark
                      <span>
                        <i
                          style={{ fontSize: "14px", color: "#898b8d" }}
                          className="fa-solid fa-bookmark"
                        ></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex justify-content-between align-items-center"
                      href="/settings"
                    >
                      Manage Profile
                      <span>
                        <i
                          style={{ fontSize: "14px", color: "#898b8d" }}
                          className="fa-solid fa-gear"
                        ></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dropdown-item" onClick={() => logout()}>
                      <button className="logout w-100">Logout</button>
                    </span>
                  </li>
                </ul>
                {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ padding: "1rem" }}>
                  <li>
                    <h6 className="text-start mb-3">
                      {capitalizeFirstLetter(userDatas?.name)}
                    </h6>
                  </li>
                  <li>
                    <p className="text-start mb-2">{userDatas?.address}</p>
                  </li>
                  <li>
                    <span onClick={() => logout()} className="text-start">
                      <button className="logout">
                        <i className="fas fa-sign-out-alt" style={{ color: "#ffffff" }}></i> Logout
                      </button>
                    </span>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
          <button
            className="btn mobile_menu_button d-block d-xl-none"
            onClick={toggleMobileMenu}
          >
            {/* <i className="fa-solid fa-bars"></i> */}
            <i className="fas fa-ellipsis-v"></i>
          </button>
        </Col>
      </Row>

      <div className="d-flex justify-content-center align-items-center">
        <Modal
          show={showModal}
          onHide={handleClose}
          centered
          size="lg"
          id="notificaion-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#0F66B4" }}>Notifications</span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="model-content">
            <div className="notification-img">
              <div className="img_container ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 39 39"
                  fill="none"
                  className=""
                >
                  <path
                    d="M14.6091 26.6064L19.5 23.6384L24.3909 26.6064L23.095 21.0467L27.4425 17.2844L21.7155 16.8246L19.5 11.5575L17.2844 16.8246L11.5575 17.2844L15.905 21.0467L14.6091 26.6064ZM19.5 38.3947L13.8984 32.8768H6.12316V25.1015L0.605225 19.5L6.12316 13.8984V6.12316H13.8984L19.5 0.605225L25.1015 6.12316H32.8768V13.8984L38.3947 19.5L32.8768 25.1015V32.8768H25.1015L19.5 38.3947ZM19.5 33.7128L23.6802 29.5326H29.5326V23.6802L33.7128 19.5L29.5326 15.3197V9.46736H23.6802L19.5 5.28711L15.3197 9.46736H9.46736V15.3197L5.28711 19.5L9.46736 23.6802V29.5326H15.3197L19.5 33.7128Z"
                    fill="#0F66B4"
                  />
                </svg>
              </div>
              <span>{notificationDate}</span>
            </div>

            <div className="modal_body">
              <h4>{notificationName}</h4>
              <div className="notification_message overflow-auto">
                <p>{notificationmessage}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Navbar;
