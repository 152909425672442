import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Button  } from 'react-bootstrap';
import { BallTriangle } from 'react-loader-spinner';
import axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setPageNumber } from '../../redux/slice/firmdNumber.js';
import { DateRangePicker } from 'react-date-range';
import { FaCalendarAlt } from 'react-icons/fa';
import { format } from 'date-fns';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const Dashboard = () => {
  const navigate = useNavigate();
  const [totalFirms, setTotalFirms] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [firms, setFirms] = useState([]);
  const [flags, setFlags] = useState([]);
  const [registarnt, setRegistarnt] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: null,
    endDate: null,
    key: 'selection'
  });
  const [showModal, setShowModal] = useState(false);
  const [dateSelected, setDateSelected] = useState(false);
  // const [userGraph, setUserGraph] = useState([]);
  const [firmGraph, setFirmGraph] = useState([]);
  const [registrantGraph, setRegistrantGraph] = useState([]);
  const [flagGraph, setFlagGraph] = useState([]);
  const specificDate = new Date(); 
  const TodayDate = format(specificDate, "do MMMM yyyy");
  

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    setDateSelected(true);
  };

  const handleApply = () => {
    fetchData();
    fetchGraphsData();
    setShowModal(false);
  };
console.log("totalUsers", totalUsers);


  const [reset, setReset] = useState(false);

  const handleReset=()=>{
      setSelectionRange({
        startDate: null,
        endDate: null,
        key: 'selection'
      });
    setDateSelected(false);
    setReset(true);
  // }
  }

  const statusColors = {
    pending: "flag_action--yellow",
    reject: "flag_action--red",
    resolved: "flag_action--green",
  };


  const formatDate = (date) => {
    if (!date) return '';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const data = firmGraph?.map(item => item.count);
  const registrantData = registrantGraph?.map(item => item.count);
  const flagData = flagGraph?.map(item => item.count);

  // const userdatas = userGraph?.map(item => item?.count);
  // const usermonth = userGraph?.map(item => item?.month);
  // const useryear = userGraph?.map(item => item?.year);
  // const userDate = userGraph?.map(item => `${item?.month} ${item?.year}`);
  const firmDate = firmGraph?.map(item => `${item?.key1} ${item?.key2}`);
  const registrantDate = registrantGraph?.map(item => `${item?.key1} ${item?.key2}`);
  const flagDate  = flagGraph?.map(item => `${item?.key1} ${item?.key2}`);
  

  const combinedDates = [...new Set([...firmDate, ...registrantDate , ...flagDate])].sort(
    (a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateA - dateB;
    }
  );

  const chartOptions = {
    chart: {
      type: "line",
      style: {
        fontFamily: "Lato",
      },
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: combinedDates,
      // crosshair: true,
      gridLineWidth: 1,
    },

    yAxis: {
      labels: {
        enabled: true,
      },
      title: {
        text: "No. Registered Firms , Registered Individuals and Flags",
      },
      gridLineWidth: 0,
      min: 0,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: true,
          allowPointSelect: true,
        },
        pointStart: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "horizontal",
      x: 0,
      y: 0,
      itemStyle: {
        fontFamily: "Lato",
      },
    },
    series: [
      {
        name: "Registered Firms",
        data: data,
        color: "#0F66B4",
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(15, 102, 180, 1)"],
            [1, "rgba(15, 102, 180, 0.1)"],
          ],
        },
      },
      {
        name: "Registered Individuals",
        data: registrantData,
        color: "#DE8F00",
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(222, 143, 0, 1)"],
            [1, "rgba(222, 143, 0, 0.1)"],
          ],
        },
      },
      {
        name: "Flags",
        data: flagData,
        color: "#FE6254",
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(254, 98, 84, 1)"],
            [1, "rgba(254, 98, 84, 0)"],
          ],
        },
      },
    ],
  };




  // useEffect(() => {
  //   document.title = "PCMA || Admin Dashboard";
  // }, []);

  const userData = JSON.parse(window.localStorage.getItem("loginAdmin"));
  let token = '';

  useEffect(() => {
    redirectToLogin();
  }, [userData]);

  const redirectToLogin = () => {
     token = userData?.Token || null;
  
    if (token === null) {
      navigate('/admin/login');
    }
  };
  

  useEffect(() => { 
    fetchData()
  }, []);

  useEffect(() => {
    fetchGraphsData()
  }, []);

  const fetchData = async () => {
    setLoader(true);
    if (token) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/dashboard/v2`, {
          params: {
            start_date: formatDate(selectionRange?.startDate),
            end_date: formatDate(selectionRange?.endDate),
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
         console.log("response1", response);

        if(response?.data?.message === "Unauthozied User"){
          localStorage.removeItem('loginAdmin');
          navigate("/admin");
          toast.error(`Unauthozied user !`);
        } 
       
        
        setTotalFirms(response?.data?.total_firms);
        setTotalUsers(response?.data?.total_registrants);
        setRegistarnt(response?.data?.registrants);
        setFirms(response?.data?.firms);
        setFlags(response?.data?.flags);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        if (error?.message === 'Request failed with status code 401') {
          localStorage.removeItem('loginAdmin');
          navigate('/admin/login')
        }
      }
    }


  };
 
  const fetchGraphsData = async () => {
    // setLoader(true);
    if (token) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/dashboard/graph/v2`, {
          params: {
            start_date: formatDate(selectionRange?.startDate),
            end_date: formatDate(selectionRange?.endDate),
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response?.data?.message === "Unauthozied User") {
          localStorage.removeItem('loginAdmin');
          navigate("/admin");
          toast.error(`Unauthozied user !`);
        }
        console.log("response",response.data.data);
        
        // setUserGraph(response?.data?.users);
        setFirmGraph(response?.data?.data?.firm_chart_data);
        setRegistrantGraph(response?.data?.data?.registrant_chart_data);
        setFlagGraph(response?.data?.data?.flag_chart_data);
        // setLoader(false);
      } catch (error) {
        // setLoader(false);
        if (error?.message === 'Request failed with status code 401') {
          localStorage.removeItem('loginAdmin');
          navigate('/admin/login')
        }
      }
    }


  };


  const dispatch = useDispatch()
  

  dispatch(setPageNumber(1));

  const handleFirmStatusToggle = async (firmId, status) => {
    const valueofStatus = !status; 
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/firm-status/${firmId}`,
        { status: valueofStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error("Unauthorized user!");
      } else if (response?.data?.message === "Firm updated successfully") {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.warning(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleSwitchToggle = async (firmId, status) => {
    const valueofStatus = !status; 
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/user-status/${firmId}`,
        {
          "status": valueofStatus
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if(response?.data?.message === "Unauthozied User"){
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error(`Unauthozied user`);
      }
      if (response.data.message === "User updated successfully") {
        toast.success(`${response.data.message}`)
        fetchData();
      } else {
        toast.warning(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {loader ? (
        <Container fluid className="loader">
          <Row
            className="g-2 pb-3 d-flex justify-content-center align-content-center "
            style={{ height: "79vh" }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-content-center"
            >
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0f66b4"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid className="pb-3">
          <Row className="mb-4">
            <Col lg="12 ps-0" className="areassss">
              <div className="adminDashboardTop ">
                <Row className="align-items-center">
                  <Col xs="auto" className="me-auto">
                    <h5 className="mb-0">
                      Welcome to <span>PCMA Admin Dashboard</span>
                    </h5>
                  </Col>
                  <Col xs="auto">
                    <Col xs="auto">
                      <Button
                        variant="transparent"
                        className="border-0"
                        onClick={() => setShowModal(true)}
                      >
                        {dateSelected ? (
                          <>
                            <FaCalendarAlt />{" "}
                            {formatDate(selectionRange?.startDate)} -{" "}
                            {formatDate(selectionRange?.endDate)}{" "}
                          </>
                        ) : (
                          <>
                            <FaCalendarAlt /> Select Date
                          </>
                        )}
                      </Button>
                      <Modal
                        show={showModal}
                        centered
                        size=""
                        onHide={() => setShowModal(false)}
                        className="dateRangeModal"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Select Date Range</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="d-flex justify-content-center">
                          <DateRangePicker
                            ranges={[selectionRange]}
                            onChange={handleSelect}
                            maxDate={new Date()}
                          />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            className="btn_close"
                            onClick={() => setShowModal(false)}
                          >
                            Close
                          </Button>
                          <Button className="btn_apply" onClick={handleApply}>
                            Apply
                          </Button>

                          <div className="tooltip-container">
                            <button
                              className="reset_button btn"
                              onClick={handleReset}
                              style={{ cursor: "pointer" }}
                            >
                              <i
                                className="fa-solid fa-arrow-rotate-right"
                                style={{ color: "#0F66B4" }}
                              ></i>
                            </button>
                            <div className="tooltip-text">Reset</div>
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </Col>
                  </Col>
                  {/* <Col xs="auto" data-bs-theme="dark">
                    <Row className='g-3 align-items-center'>
                        <Col xs="auto">
                          <label htmlFor=""><span className='me-3'>|</span> Sort by:</label>
                        </Col>
                        <Col xs="auto">
                          <Form.Select aria-label="Default" className='border-0 bg-transparent  shadow-none'>
                            <option className='text-dark'>Default</option>
                            <option value="1" className='text-dark'>One</option>
                            <option value="2"  className='text-dark'>Two</option>
                            <option value="3"  className='text-dark'>Three</option>
                          </Form.Select>
                        </Col>
                    </Row>
                  </Col> */}
                </Row>
              </div>
            </Col>
            <Col sm={12} className="mt-3">
              <div className="today_date d-flex align-items-center justify-content-sm-end justify-content-center p-1">
                <h1 className="mb-0">Today’s Date -</h1>
                <p className="mb-0">{TodayDate}</p>
              </div>
            </Col>
            <Col
              xs={12}
              xxl={4}
              xl={6}
              lg={6}
              md={12}
              className="g-2 user-component-1 order-lg-1 order-1 ps-0"
            >
              <div className="">
                <div className="user-component-1-a mb-4">
                  <div
                    className="boxArea"
                    style={{
                      background: "#fff",
                      padding: "1rem",
                      height: "50%",
                    }}
                  >
                    <div className="d-flex justify-content-between ">
                      <h2 className="wrapTitle">Total Registered Firms</h2>
                      <p className="view-all">
                        {" "}
                        <Link to="/admin/firms">View All</Link>
                      </p>
                    </div>
                    <div
                      style={{
                        color: "#0F66B4",
                        fontSize: "40px",
                        fontWeight: "800",
                      }}
                    >
                      <p className="dash_frem_p">{totalFirms}</p>
                    </div>
                  </div>
                  <div
                    className="boxArea2"
                    style={{
                      background: "#fff",
                      padding: "1rem",
                      height: "50%",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <h2 className="wrapTitle">
                        Total Registered Individuals
                      </h2>
                      <p className="view-all">
                        <Link to="/admin/pcmamembers">View All</Link>
                      </p>
                    </div>
                    <div
                      style={{
                        color: "#0F66B4",
                        fontSize: "40px",
                        fontWeight: "800",
                      }}
                    >
                      {" "}
                      <p className="dash_frem_p">{totalUsers}</p>{" "}
                    </div>
                  </div>
                </div>
                <div
                  className="user-component-1-a mb-2"
                  style={{ maxHeight: "100%" }}
                >
                  <div
                    className="boxArea"
                    style={{
                      background: "#fff",
                      padding: "1rem",
                      // height: "50%",
                      // marginTop: "20px",
                      borderRadius: "10px",
                      maxHeight: "auto",
                      height: "501px",
                    }}
                  >
                    <div className="position-relative">
                      {/* <Row className='g-3 align-itmes-center'>
                        <Col xs="auto" className='me-auto'>
                          <h6 className="addedUser mb-0" style={{ fontSize: 20 }}>
                            Overview
                          </h6>
                        </Col>
                        <Col xs="auto">
                          <Button variant="transparent" className='border-0 p-0' onClick={() => setShowModal(true)}>
                            {dateSelected ? <><FaCalendarAlt />  {formatDate(selectionRange.startDate)} - {formatDate(selectionRange.endDate)} </> : (
                              <>
                                <FaCalendarAlt /> Select Date
                              </>
                            )}
                          </Button>
                        </Col>
                      </Row> */}
                      <h6 className="addedUser mb-2" style={{ fontSize: 20 }}>
                        Overview
                      </h6>
                      {/* <Button variant="transparent" className='border-0 p-0' onClick={() => setShowModal(true)} disabled>
                        {dateSelected ? <><FaCalendarAlt />  {formatDate(selectionRange.startDate)} - {formatDate(selectionRange.endDate)} </> : (
                          <>
                            <FaCalendarAlt /> Select Date
                          </>
                        )}
                      </Button> */}
                    </div>

                    <HighchartsReact
                      highcharts={Highcharts}
                      options={chartOptions}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col
              md={12}
              xxl={4}
              xl={6}
              lg={6}
              className="g-2 order-lg-2 order-3"
            >
              <div
                className="user-component-2 h-auto"
                style={{
                  background: "#fff",
                  borderRadius: "5px",
                  maxHeight: "auto",
                }}
              >
                <div className="d-flex justify-content-between px-2 pt-3">
                  <h6 className="addedUser" style={{}}>
                    Recently Added Registered Firms
                  </h6>
                  <p className="view-all">
                    <Link to="/admin/firms">View All</Link>
                  </p>
                </div>
                <div
                  className="dashboard_firms_card_box "
                  style={{ maxHeight: "758px" }}
                >
                  <table className="w-100">
                    <tbody>
                      {firms?.length == 0 ? (
                        <p className="px-4">No Data Found</p>
                      ) : (
                        ""
                      )}
                      {firms?.map((firm, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {" "}
                              <div className="firms_card  py-1 px-2 px-lg-0">
                                <Container fluid className="my-3">
                                  <Row>
                                    <Col sm={4}>
                                      {firm?.firm_image ? (
                                        <img src={firm?.firm_image} alt="" />
                                      ) : (
                                        <img
                                          src="../asset/firmdemoimage.png"
                                          alt=""
                                        />
                                      )}
                                    </Col>
                                    <Col sm={8} className="ps-0">
                                      <h3>{firm?.firm}</h3>
                                      <p>
                                        {firm?.other_names &&
                                        firm?.other_names?.length > 0 ? (
                                          <>
                                            Alternative Name:{" "}
                                            {firm?.other_names.join(", ")}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          id="flexSwitchCheckChecked"
                                          checked={firm.status}
                                          onChange={() =>
                                            handleFirmStatusToggle(
                                              firm?._id,
                                              firm.status
                                            )
                                          }
                                        />
                                        <div>
                                          {firm?.status
                                            ? "Enabled"
                                            : "Disabled"}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>

            <Col
              xs={12}
              xxl={4}
              xl={12}
              lg={12}
              md={12}
              className="g-2 order-lg-3 order-2"
            >
              <div className="h-100">
                <div
                  className="user-component-3 h-auto mb-3"
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: "5px",
                    // maxHeight:'821px'
                  }}
                >
                  <div className="d-flex justify-content-between ">
                    <h6 className="addedUser" style={{ fontSize: "20px" }}>
                      Recently Added Flags
                    </h6>
                    <p className="view-all">
                      <Link to="/admin/flag/firm-flag">View All</Link>
                    </p>
                  </div>
                  <div
                    className="firms_card_box mt-2"
                    style={{ maxHeight: "100%", height: "300px" }}
                  >
                    <table className="w-100 notice_table">
                      <thead style={{ background: "#DDEBF2" }}>
                        <tr className="d-flex justify-content-between ">
                          {/* <th className="mx-2">NAME</th>
                          <th className="mx-2 status_hide">STATUS</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {flags?.length == 0 ? (
                          <p className="px-4">No Data Found</p>
                        ) : (
                          ""
                        )}
                        {flags?.map((flag, index) => (
                          <>
                            <tr
                              key={index}
                              className="d-flex add_user_responsive justify-content-between"
                            >
                              <td className="py-3 mx-2">
                                {flag?.flag_type === "Firm" ? (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M9.75 8.25H13.35C14.1901 8.25 14.6101 8.25 14.931 8.41349C15.2132 8.5573 15.4427 8.78677 15.5865 9.06901C15.75 9.38988 15.75 9.80992 15.75 10.65V15.75M9.75 15.75V4.65C9.75 3.80992 9.75 3.38988 9.58651 3.06901C9.4427 2.78677 9.21323 2.5573 8.93099 2.41349C8.61012 2.25 8.19008 2.25 7.35 2.25H4.65C3.80992 2.25 3.38988 2.25 3.06901 2.41349C2.78677 2.5573 2.5573 2.78677 2.41349 3.06901C2.25 3.38988 2.25 3.80992 2.25 4.65V15.75M16.5 15.75H1.5M4.875 5.25H7.125M4.875 8.25H7.125M4.875 11.25H7.125"
                                        stroke="#3E3C3D"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    <span className="ms-2">
                                      {flag?.target_name}
                                    </span>
                                  </>
                                ) : flag?.flag_type === "Registrant" ? (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M13.5001 11.8777C14.592 12.4263 15.5282 13.3065 16.2116 14.4073C16.3469 14.6253 16.4145 14.7343 16.4379 14.8852C16.4855 15.1919 16.2757 15.569 15.9901 15.6903C15.8495 15.7501 15.6914 15.7501 15.3751 15.7501M12.0001 8.64924C13.1114 8.09698 13.8751 6.95021 13.8751 5.62506C13.8751 4.29992 13.1114 3.15314 12.0001 2.60088M10.5001 5.62506C10.5001 7.48902 8.98908 9.00006 7.12512 9.00006C5.26116 9.00006 3.75012 7.48902 3.75012 5.62506C3.75012 3.7611 5.26116 2.25006 7.12512 2.25006C8.98908 2.25006 10.5001 3.7611 10.5001 5.62506ZM1.91954 14.2038C3.11527 12.4085 5.00215 11.2501 7.12512 11.2501C9.24809 11.2501 11.135 12.4085 12.3307 14.2038C12.5926 14.5971 12.7236 14.7938 12.7085 15.045C12.6968 15.2406 12.5686 15.4801 12.4123 15.5983C12.2116 15.7501 11.9355 15.7501 11.3834 15.7501H2.86689C2.31475 15.7501 2.03868 15.7501 1.83795 15.5983C1.68166 15.4801 1.55344 15.2406 1.5417 15.045C1.52662 14.7938 1.65759 14.5971 1.91954 14.2038Z"
                                        stroke="#3E3C3D"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    <span className="ms-2">
                                      {flag?.target_name}
                                    </span>
                                  </>
                                ) : null}
                              </td>
                              <td className="py-3 mx-2">
                                <div className="h-100 d-flex align-items-center justify-content-center add_responsive_div">
                                  {flag?.flag_type === "Firm" ? (
                                    <div
                                      className={`flag_action status-${flag?.status?.toLowerCase()}`}
                                    >
                                      {flag?.status}
                                    </div>
                                  ) : flag?.flag_type === "Registrant" ? (
                                    <div
                                      className={`flag_action status-${flag?.status?.toLowerCase()}`}
                                    >
                                      {flag?.status}
                                    </div>
                                  ) : null}
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="user-component-1-b h-auto"
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: "5px",
                    maxHeight: "419px",
                  }}
                >
                  <div className="d-flex justify-content-between ">
                    <h6 className="addedUser" style={{ fontSize: "20px" }}>
                      Recently Added Registered Individuals
                    </h6>
                    <p className="view-all">
                      <Link to="/admin/registered-individuals">View All</Link>
                    </p>
                  </div>
                  <div
                    className="firms_card_box mt-2"
                    style={{ maxHeight: "100%", height: "300px" }}
                  >
                    <table className="w-100 notice_table">
                      <thead style={{ background: "#DDEBF2" }}>
                        <tr className="d-flex justify-content-between ">
                          {/* <th className="mx-2">NAME</th>
                          <th className="mx-2 status_hide">STATUS</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {registarnt?.length == 0 ? (
                          <p className="px-4">No Data Found</p>
                        ) : (
                          ""
                        )}
                        {registarnt?.map((registarnt, index) => (
                          <>
                            <tr
                              key={index}
                              className="d-flex add_user_responsive justify-content-between"
                            >
                              <td className="py-3 mx-2">
                                <div className="user_image d-flex align-items-center">
                                  {registarnt?.linkedin_details
                                    ?.profile_picture ? (
                                    <img
                                      src={
                                        registarnt?.linkedin_details
                                          ?.profile_picture
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <img src="../asset/users.png" alt="" />
                                  )}
                                  <span className="ms-2">
                                    {registarnt?.name}
                                  </span>
                                </div>
                              </td>
                              <td className="py-3 mx-2">
                                <div className="h-100 d-flex align-items-center justify-content-center add_responsive_div">
                                  {registarnt?.featured && (
                                    <>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="37"
                                        height="35"
                                        viewBox="0 0 37 35"
                                        fill="none"
                                      >
                                        <rect
                                          opacity="0.25"
                                          width="36.0294"
                                          height="35"
                                          rx="4"
                                          fill="#FAA815"
                                        />
                                        <path
                                          d="M18.4556 8.09542C18.4556 7.60247 18.059 7.20588 17.5661 7.20588C17.0731 7.20588 16.6765 7.60247 16.6765 8.09542V9.28148H15.4905C14.9975 9.28148 14.6009 9.67807 14.6009 10.171C14.6009 10.664 14.9975 11.0606 15.4905 11.0606H16.6765V12.5431H11.4727C10.2533 12.5431 9.26367 13.5327 9.26367 14.7522C9.26367 15.0561 9.32668 15.3563 9.44529 15.6343L11.4542 20.2525H13.3926L11.0798 14.9227C11.0576 14.8671 11.0428 14.8115 11.0428 14.7522C11.0428 14.515 11.2355 14.3222 11.4727 14.3222H23.6594C23.8966 14.3222 24.0894 14.515 24.0894 14.7522C24.0894 14.8115 24.0782 14.8708 24.0523 14.9227L21.7358 20.2525H23.6742L25.6831 15.6343C25.8054 15.3563 25.8647 15.0561 25.8647 14.7522C25.8647 13.5327 24.8751 12.5431 23.6557 12.5431H18.4556V11.0606H19.6417C20.1346 11.0606 20.5312 10.664 20.5312 10.171C20.5312 9.67807 20.1346 9.28148 19.6417 9.28148H18.4556V8.09542ZM13.0146 23.2176H22.1175L22.7328 24.4037H12.403L13.0183 23.2176H13.0146ZM23.5297 22.0798C23.3258 21.6869 22.9181 21.4386 22.4771 21.4386H12.655C12.2103 21.4386 11.8063 21.6869 11.6024 22.0798L10.6202 23.9775C10.509 24.1924 10.4497 24.4296 10.4497 24.6706C10.4497 25.5082 11.1243 26.1828 11.9619 26.1828H23.1702C24.0041 26.1828 24.6824 25.5082 24.6824 24.6706C24.6824 24.4296 24.6231 24.1924 24.5119 23.9775L23.5297 22.0798Z"
                                          fill="#FAA815"
                                        />
                                      </svg>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Dashboard;
