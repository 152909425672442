import { createSlice } from '@reduxjs/toolkit';

const pageNumberSlice = createSlice({
  name: "pageNumber",
  initialState: { value: 1 , firmValue: 1 , registarntValue: 1 },
  reducers: {
    setPageNumber(state, action) {
      state.value = action.payload;
    },
    setFirmPageNumber(state, action) {
      state.firmValue = action.payload;
    },
    setRegistrantPageNumber(state, action) {
      state.registarntValue = action.payload;
    },
  },
});

export const { setPageNumber, setFirmPageNumber, setRegistrantPageNumber } = pageNumberSlice.actions;
export default pageNumberSlice.reducer;
