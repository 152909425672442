import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ResponsivePagination from "react-responsive-pagination";
import { BallTriangle } from "react-loader-spinner";
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import { setPageNumber } from '../../redux/slice/firmdNumber.js';
import CustomPagination from "../../components/pagination/CustomPagination.jsx";

const Message = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const navigate = useNavigate();
  let token;
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const [modelId, setmodelId] = useState("");
  const [modalMessage, setmodalMessage] = useState("");
  const [modalName, setModalName] = useState("");
  const [modalDate, setModalDate] = useState("");
  const [modalStatus, setModalStatus] = useState("");
  const [sort, setSort] = useState(null);
  const [ButtonDisable, setButtonDisable] = useState(false);
  const [orderName, setOrderName] = useState("");
  const [formData, setFormData] = useState({
    notice_name: '',
    notice_purpose: '',
    message: '',
  });
  const handleClose = () => {
    setShow(false);
    setFormData("");
    setButtonDisable(false);
  };
  const handleShow = () => setShow(true);

  const handlePageChange = (data) => {
    setCurrentPage(data);
  };

  useEffect(() => {
    document.title = "PCMA || Notice";
  }, []);

  const dispatch = useDispatch()

  dispatch(setPageNumber(1));

  const handleChange = (e) => {

    if (e.target.name === "notice_name" && e.target.value?.length <= 30) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [e.target.name]: e.target.value,
      }));
    }

    // if (e.target.name === "notice_purpose" && e.target.value?.length <= 250) {
    //   setFormData((prevFormData) => ({
    //     ...prevFormData,
    //     [e.target.name]: e.target.value,
    //   }));
    // }

    if (e.target.name === "message" && e.target.value?.length <= 350) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [e.target.name]: e.target.value,
      }));
    }

    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [e.target.name]: e.target.value,
    // }));
  };


  const [notice, setNotice] = useState([])
  const userData = JSON.parse(window.localStorage.getItem("loginAdmin"));

  const handleAddNotice = async (e) => {
    e.preventDefault();
    if (formData.notice_name?.trim().length === 0) { toast.error("Notice name cannot be empty"); return false; }
    // if (formData.notice_purpose?.trim().length === 0) { toast.error("Notice Purpose cannot be empty"); return false; }
    if (formData.message?.trim().length === 0) { toast.error("Message cannot be empty"); return false; }
    try {
      setButtonDisable(true);
      const response = await axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/notice`,
        {
          "name": formData.notice_name,
          "purpose": formData.notice_purpose,
          "message": formData.message
        }
        , {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setButtonDisable(true);
      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error(`Unauthozied user`);
      }
      if (response?.data?.message === "Notice stored successfully") {
        setButtonDisable(false);
        toast.success(response?.data?.message);
        noticeApi();
        setShow(false);
        setCurrentPage(1)
        setFormData("");
        setOrderName("");
        setSort(null)
      } else {
        toast(response?.data?.message);
      }
      setShow(false)
    } catch (error) {
      if (error?.response?.data?.errors?.purpose) {
        toast.error(error?.response?.data?.errors?.purpose)
        return false;
      }
      else if (error?.response?.data?.errors?.name[0] === 'The name field is required.') {
        toast.error(error?.response?.data?.errors?.name);
        return false;
      } else if (error.response.data.message === 'This notice already exists.') {
        toast.error(error.response.data.message);
        return false;

      }
      setButtonDisable(false);
    }
  }
  useEffect(() => {
    redirectToLogin();
  });

  const redirectToLogin = () => {
    token = userData ? userData.Token : null;
    if (token === null) {
      navigate('/admin/login');
    }
  };


  useEffect(() => {
    noticeApi()
  }, [currentPage, sort])

  const noticeApi = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/all-notice-data`,
        {
          params: {
            page: currentPage,
            order_name: orderName,
            order_type: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error(`Unauthozied user`);
      }

      setNotice(response.data.notice_data.data);
      setTotalPage(response.data.notice_data.last_page);
      setButtonDisable(false);
    } catch (error) {
      setIsLoading(false)
      setButtonDisable(false);
    }
    setIsLoading(false);
  }

  const [loadingToggles, setLoadingToggles] = useState({});

  const handleToggle = async (id, status) => {
    setLoadingToggles((prev) => ({ ...prev, [id]: true }));
    try {
      const status_value = !status;

      const response = await axios.put(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/notices-status/${id}`, {
        "status": status_value
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      noticeApi();
      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error(`Unauthozied user`);
      }
      if (response.data.message === "Status updated successfully") {
        toast.success(response.data.message)
      } else {
        toast(response.data.message)
      }
    } catch (error) {
      console.log("error", error);
    }
    finally {
      setLoadingToggles((prev) => ({ ...prev, [id]: false }));
    }
  };


  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const deleteNotification = async (id) => {



    try {
      const confirmDeletion = await Swal.fire({
        title: "Delete Notification",
        text: "Do you want to delete this notification? This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#D0D5DD",
        confirmButtonColor: "#D92D20",
        confirmButtonText: "Delete",
        iconHtml: '<i class="fas fa-trash-alt"></i>',
        iconColor: "red"

      });


      if (!confirmDeletion.isConfirmed) return;

      const response = await axios.delete(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/notices-delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      noticeApi();
      // setCurrentPage(1);
      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error(`Unauthozied user`);
      }

      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 2000);
      if (response.data.message === "Notice deleted successfully") {
        toast.success(response.data.message);
        setShowModal(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting notification:", error);
      toast.error("Something went wrong please try again later.");
    }
  };

  const handleSorting = (event) => {
    if (event === "status") {
      setOrderName("status");
      if (sort === null) {
        setSort(1);
        setCurrentPage(1);
      }
      if (sort === 1) {
        setSort(0);
        setCurrentPage(1);
      }
      if (sort === 0) {
        setSort(1);
        setCurrentPage(1);
      }
    }

  };

  const handleModelData = (data) => {
    setShowModal(true);
    setmodelId(data?._id);
    setmodalMessage(data?.message);
    setModalName(data?.name);
    setModalDate(data?.updated_at);
    setModalStatus(data?.status);
  };

  return (
    <>
      {isLoading ? (
        <Container fluid className="loader">
          <Row
            className="g-2 pb-3 d-flex justify-content-center align-content-center "
            style={{ height: "79vh" }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-content-center"
            >
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0f66b4"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Container fluid>
            <Row>
              <Col
                xs={12}
                md={12}
                lg={12}
                style={{ background: "#137CD9", color: "#fff", padding: "15px", marginTop: "35px", borderRadius: "5px" }}
              >
                <h2 className="mb-0 userManagement2 area-wrappingBtn">Notice</h2>
              </Col>
            </Row>
            <Row className="d-flex justify-content-md-end py-2">

              <Col xs={12}
                md={12}
                lg={12} className="p-2 d-flex justify-content-end area-wrappingBtn">
                <button className="p-2 adminadd_btn" onClick={handleShow} ><i className="fa-solid fa-circle-plus my-1"></i>Create New Notice</button>
                {/* <button
                  className="p-2"
                  style={{
                    border: "2px solid #137CD9",
                    color: "#137CD9",
                    cursor: "pointer",
                    borderRadius: "5px",
                    fontWeight: "700"
                  }}
                  onClick={handleShow}
                >
                  <i className="fa-solid fa-circle-plus mx-2"></i>Create New Notice
                </button> */}
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={12}
                lg={12}
                // style={{
                //   background: "#fff",
                //   border: "2px solid rgba(15, 102, 180, 0.2)",
                //   borderRadius: "5px",
                // }}
                className=" ps-0"
              >
                <div className="table-resposive firms_card_box ps-0">
                  <Table className="mb-0 notice_table_body">

                    <thead className="wrappingBox">
                      <tr className="notice_table_heading">
                        <th className="text-left" style={{
                          borderRadius: "5px 0 0 0", paddingLeft: "28px",
                          background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: "0"
                        }}>Date</th>

                        <th className="text-center"
                          style={{ background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: "0" }}
                        >  Notice Name
                        </th>
                        <th className="text-center "
                          style={{ background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: "0" }}
                        >  Message
                        </th>

                        <th className="text-center" style={{ minWidth: '170px', background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: "0" }}>
                          Status
                          <span
                            style={{ cursor: 'pointer' }}
                            className="ms-2"
                            onClick={() => handleSorting("status")}
                          >
                            {sort === 1 ? <i className="fa-solid fa-arrow-up" style={{ color: '#137CD9' }}></i> : sort === 0 ? <i className="fa-solid fa-arrow-down" style={{ color: '#137CD9' }}></i> : <i className="fa-solid fa-arrow-right-arrow-left fa-rotate-90" style={{ color: '#137CD9' }}></i>}
                          </span></th>
                        <th className="text-center"
                          style={{ borderRadius: "0 5px 0 0", background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: "0" }}
                        >  Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="notice_table_body">
                      {notice?.length !== 0 ?
                        (notice?.map((notices, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className="text-left cursor-pointer ps-4"
                              // onClick={() => {
                              //   handleModelData(notices);
                              // }}
                              >
                                <p
                                  style={{ whiteSpace: "nowrap" }}
                                  className="mb-0"
                                >
                                  {notices?.updated_at.split("T")[0]}
                                </p>
                              </td>
                              <td
                                className="text-center ps-0"
                                style={{ fontWeight: "500" }}
                              // onClick={() => {
                              //   handleModelData(notices);
                              // }}
                              >
                                <p className="mb-0 notice_text_name_dots ps-0">
                                  {notices?.name}
                                </p>
                              </td>

                              <td
                                className="text-center"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  handleModelData(notices);
                                }}
                              >
                                <div className="d-flex align-items-center justify-content-center w-100">
                                  <p className="notice_message text-truncate mb-0 ps-0">
                                    {notices?.message}
                                  </p>
                                </div>
                              </td>
                              <td className="text-center ps-0 pe-0">
                                <div className="form-check ps-0 form-switch d-flex justify-content-center align-items-center">
                                  <input
                                    className="form-check-input me-2"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={notices?.status}
                                    disabled={loadingToggles[notices?._id]}
                                    onChange={() => {
                                      handleToggle(notices?._id, notices?.status);
                                    }}
                                  />
                                  {notices?.status ? "Enabled" : "Disabled"}
                                </div>
                              </td>
                              <td className="text-center cursor-pointer">
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (!isButtonDisabled) {
                                      deleteNotification(notices?._id);
                                    }
                                  }}
                                >
                                  <i className="fa-solid fa-trash px-2" style={{ cursor: 'pointer', color: '#FF6969' }} ></i>
                                </span>
                              </td>
                            </tr>
                          );
                        })) :


                        <tr>
                          <td colSpan={8} style={{ textAlign: 'center' }}>
                            <div className="d-flex align-items-center justify-content-center w-100">
                              No Data Found
                            </div>
                          </td>
                        </tr>

                      }
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="true"
                keyboard={false}
                size="lg"
                centered
              >

                <Modal.Title className="model_title text-center fw-bolder mb-3 d-flex justify-content-between" style={{ backgroundColor: 'rgb(16 104 183)', borderRadius: '6px 6px 0 0' }}>

                  <h4 className="title mb-0 py-2 ps-4" style={{ color: "#fff", }}>
                    Send Notice To All Users
                  </h4>
                </Modal.Title>
                <Modal.Body>
                  <Container fluid className="">
                    <Row>
                      <Col lg={12} md={12}>
                        <Form.Group className="Name py-2">
                          <Form.Label className="form_label fw-bolder">
                            Notice Name <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              type="text"
                              name="notice_name"
                              value={formData.notice_name}
                              placeholder="Write Notice Name Here"
                              onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid-feedback text-start p-0 ps-sm-2"></Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>

                      {/* <Form.Group className="Name py-2">
                        <Form.Label className="form_label fw-bolder ">
                          Notice Purpose
                          
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>

                        </Form.Label>
                        <div className="input_field">
                          <Form.Control
                            type="text"
                            name="notice_purpose"
                            placeholder="Write Notice Purpose Here"
                            value={formData.notice_purpose}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid-feedback text-start p-0 ps-sm-2"></Form.Control.Feedback>
                        </div>
                      </Form.Group> */}
                      <Col lg={12} md={12}>
                        <Form.Group className="Name py-2">
                          <Form.Label className="form_label fw-bolder">
                            Message <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              as="textarea"
                              rows={7}
                              name="message"
                              value={formData.message}
                              placeholder="Write Message Here"
                              onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid-feedback text-start p-0 ps-sm-2"></Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer className="justify-content-start border-0 pt-0 pb-0">
                  <div className="container-fluid">
                    <Row>
                      <Col sm={12} className="text-end">
                        <Button
                          className="btn-primary btn_notice"
                          onClick={handleAddNotice}
                          disabled={ButtonDisable}
                        >
                          Send Notice To Everyone
                        </Button>
                        <Button className="btn_cancel ml-2" onClick={handleClose} style={{
                          border: '1px solid rgba(15, 102, 180, 1)',
                          backgroundColor: 'transparent',
                          color: 'rgba(15, 102, 180, 1)',
                          marginLeft: '10px'
                        }}>
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  {/* <Button
                    className="btn-primary btn_notice"
                    onClick={handleAddNotice}
                    disabled={ButtonDisable}
                  >
                    Send Notice To Everyone
                  </Button>
                  <Button className="btn_cancel" onClick={handleClose} style={{
                    border: '1px solid rgba(15, 102, 180, 1)',
                    backgroundColor: 'transparent',
                    color: 'rgba(15, 102, 180, 1)'
                  }}>
                    Cancel
                  </Button> */}
                </Modal.Footer>
              </Modal>
            </Row>
          </Container>
          <Container fluid className="py-2">
            <Row>
              <Col
                sm={12}
                className="d-flex justify-content-start"
                style={{ padding: 0 }}
              >
                <CustomPagination
                  maxWidth={7}
                  total={totalPage}
                  current={currentPage}
                  onPageChange={(page) => handlePageChange(page)}
                />
              </Col>
            </Row>
          </Container>
        </>
      )
      }

      <Modal
        show={showModal}
        backdrop="true"
        keyboard={false}
        size="lg"
        centered
        onHide={handleCloseModal}
      >
        <Modal.Header className="d-block">
          <Modal.Title>
            <div className="d-flex justify-content-between align-items-center ">
              <div className="date_title">
                <p className="boxAreaWrap mb-0"
                  style={{ color: "rgba(20, 125, 219, 1)", fontSize: "16px", borderRadius: "10px", }}
                >
                  DATE
                </p>
                <div className="notice_date ">
                  <p
                    className="mb-0"
                    style={{
                      fontSize: "24px",
                      color: "rgba(120, 120, 120, 1)",
                    }}
                  >
                    {modalDate.split("T")[0]}
                  </p>
                </div>
              </div>
              <div className="status_title">
                <p
                  className="mb-0"
                  style={{ color: "rgba(20, 125, 219, 1)", fontSize: "16px" }}
                >
                  STATUS
                </p>
                <div>
                  <div className=""></div>
                  <label
                    className="status_display"
                    style={{
                      fontSize: "16.41px",
                    }}
                  >
                    {modalStatus ? "Enabled" : "Disabled"}
                  </label>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid className="p-2">
            <Row className="g-2">
              <Col xs={12} md={12} lg={12}>
                <div className="notice_heading">
                  <p className="fw-bold">{modalName}</p>
                </div>
              </Col>
              <Col xs={12} md={12} lg={12} >
                <div className="notice_body overflow-auto" style={{ maxHeight: "300px" }}>
                  <p className="">{modalMessage}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn_delete"
            style={{
              border: "1px solid rgba(254, 100, 100, 0.6)",
              borderRadius: "3px",
              backgroundColor: "transparent",
            }}
            onClick={() => {
              deleteNotification(modelId);
            }}
          >
            <i
              className="fa-solid fa-trash-can"
              style={{ color: "#FE6464" }}
            ></i>
          </Button>
          <Button className="btn_cancel" onClick={handleCloseModal}>
            <i
              className="fa-solid fa-xmark mx-1"
              style={{ color: "rgba(15, 102, 181, 1)" }}
            ></i>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Message;
