import React, { useEffect, useState } from 'react';
import './styles/Global.css';
import { Routes, Route } from 'react-router-dom';
import Login from './components/login/Login';
import Register from './components/register/Register';
import DashboardLayout from './pages/adminDashboard/DasboardLayout';
import Dashboard from './pages/adminDashboard/Dashboard';
import Message from './pages/adminDashboard/Message';
import MemberType from './pages/members/MemberType';
import MemberShipInformation from './pages/members/MemberShipInformation';
import MembershipDues from './pages/members/membershipDues';
import RegisterForm from './components/register/RegisterForm';
import User from './pages/User';
import Error from './pages/error/Error';
import NoInternet from './pages/error/NoInternet';
import ForgotPassword from './components/forgotPassword/ForgotPassword';
import FirmDetails from './pages/firmdetails/FirmDetails';
import ProfileSettings from './pages/userDashboard/ProfileSettings';
import BookmarkPage from './pages/bookmark/BookmarkPage';
import ChangePassword from './pages/changepassword/ChangePassword';
import AdminUser from './pages/adminDashboard/AdminUser';
import AdminsersForm from './pages/adminDashboard/AdminsersForm';
import AdminFirms from './pages/adminDashboard/AdminFirms';
import AdminLogin from './pages/adminDashboard/AdminLogin';
import AdminProfile from './pages/adminDashboard/AdminProfile';
import AdminFirmUpdate from './pages/adminDashboard/AdminFirmUpdate';
import ApprovedMember from './pages/members/ApprovedMember';
import ApprovedMemberData from './pages/members/ApprovedMemberData';
import AdminAddUser from './pages/adminDashboard/adminuser/AdminAddUser';
import UserDashboardLayout from './pages/userDashboard/UserDashboardLayout';
import UserLogoDownload from './pages/userDashboard/UserLogoDownload';
import UserInvoice from './pages/userDashboard//UserInvoice';
import UserMemberShip from './pages/userDashboard//UserMemberShip';
import UserNetwork from './pages/userDashboard//UserNetwork';
import UserMessage from './pages/userDashboard//UserMessage';
import RegistrantDetails from './pages/registrant/RegistrantDetails';
import ApprovedMemberDatails from "./pages/members/ApprovedMemberDatails";
import RegistrantSearch from './pages/registrant/RegistrantSearch';
import GlobalDashboard from './pages/globalDashboard/GlobalDashboard';
import RequiredAuth from './routes/RequiredAuth';
import Nearme from './pages/nearme/Nearme';
import RegistrantNearme from './pages/nearme/RegistrantNearme';
import MemberShipInformationModal from './pages/paymentsuccess/MemberShipInformationModal';
import Subscription from './pages/adminDashboard/Subscription';
import Payment from './pages/adminDashboard/Payment';
// import EditSubscription from './pages/adminDashboard/EditSubscription';
import PromoCode from './pages/adminDashboard/PromoCode';
import PromoCodeAdd from './pages/adminDashboard/PromoCodeAdd';
import PromoCodeEdit from './pages/adminDashboard/PromoCodeEdit';
import AdminUserView from './pages/adminDashboard/adminuser/AdminUserView';
import AdminTransaction from './components/AdminTransaction/AdminTransaction';
import UserManagement from './pages/userDashboard/UserManagement';
import UserAdd from './pages/userDashboard/UserAdd';
import MemberSearch from './pages/memberDashboard/MemberSearch';
import MemberDetails from './pages/memberDashboard/MemberDetails';
import UserBlogSection from './pages/userDashboard/UserBlogSection';
import JobPosting from './pages/job/JobPosting';
import FirmBookmarkpage from './pages/bookmark/FirmBookmarkpage';
import RegistrantBookmarkpage from './pages/bookmark/RegistrantBookmarkpage';
import AdminRegistrantDetails from './pages/adminDashboard/adminRegistrant/AdminRegistrantDetails';
import Firmdetail from './pages/adminDashboard/Firmdetail';
import FirmFlag from './pages/adminDashboard/Flag/FirmFlag';
import FirmFlagDetails from './pages/adminDashboard/Flag/FirmFlagDetails';
import RegistrantFlag from './pages/adminDashboard/Flag/RegistrantFlag';
import RegistrantFlagDetails from './pages/adminDashboard/Flag/RegistrantFlagDetails';
import AdminRegistrant from './pages/adminDashboard/AdminRegistrant';
import TaxManagement from './pages/adminDashboard/Pricing/TaxManagement';
import ErrorBoundary from './components/ErrorBoundry/ErrorBoundry';
import useDisableTabKey from './components/disableTabKey/disableTabKey';


const App = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [registrantPage, setRegistrantPage] = useState(window?.history?.state?.registrantPage || 1);
  // const [memberPage, setMemberPage] = useState(1);
  const [search, setSearch] = useState('');
  const [checkBack, setCheckBack] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // const handleOnline = () => setIsOnline(true);
    const handleOnline = () => {
      setTimeout(() => {
        setIsOnline(true);
      }, 15000);
    };
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // useDisableTabKey();

  return (
    
    <div className="App">
      <ErrorBoundary>
        {isOnline ?
          <Routes>
            {/* guest */}
            <Route path="/our-members" element={<ApprovedMember />} />
            <Route path="/job-posting" element={<JobPosting />} />
            <Route path="/users-data" element={<ApprovedMemberData />} />
            <Route path="/user-details/:id" element={<ApprovedMemberDatails />} />

            {/* guest */}

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<MemberType />} />
            <Route path="/membership-dues/:id" element={<MembershipDues />} />
            <Route path="/membership-information" element={<MemberShipInformation />} />
            <Route path="/membership-information-success" element={<MemberShipInformationModal />} />

            <Route path="/register-page" element={<Register />} />
            <Route path="/register-form" element={<RegisterForm />} />

            <Route path="/firm" element={
              <RequiredAuth>
                <User currentPage={currentPage} setCurrentPage={setCurrentPage} search={search} setSearch={setSearch} setCheckBack={setCheckBack} checkBack={checkBack} />
              </RequiredAuth>
            } />

            <Route path="/" element={<RequiredAuth>  <GlobalDashboard searchValue={search} setSearchValue={setSearch} setCurrentPage={setCurrentPage}   setRegistrantPage={setRegistrantPage}/>  </RequiredAuth>} />

            <Route path="/firm/nearby" element={<RequiredAuth><Nearme /></RequiredAuth>} />
            <Route path="/registrant/nearby" element={<RequiredAuth><RegistrantNearme /></RequiredAuth>} />
            <Route path="/firm/firm-details/:id" element={<RequiredAuth> <FirmDetails setCheckBack={setCheckBack} /> </RequiredAuth>} />

            <Route path="/firm/registrant-details/:id" element={<RequiredAuth> <RegistrantDetails /> </RequiredAuth>} />
            <Route path="registered-individual-search" element={<RequiredAuth> <RegistrantSearch setRegistrantPage={setRegistrantPage} registrantPage={registrantPage} search={search} setSearch={setSearch} /> </RequiredAuth>} />
            {/* <Route path="/members-search" element={<RequiredAuth>  <MemberSearch setMemberPage={setMemberPage} memberPage={memberPage} searchD={search} setSearchD={setSearch} /> </RequiredAuth>} /> */}
            {/* <Route path="/firm/member-details/:id" element={<RequiredAuth>  <MemberDetails /> </RequiredAuth>} /> */}

            <Route path="/settings" element={<RequiredAuth>  <UserDashboardLayout /> </RequiredAuth>}>
              <Route path="/settings" element={<RequiredAuth>  <ProfileSettings />  </RequiredAuth>} />
              <Route path="/settings/downloadlogo" element={<RequiredAuth>  <UserLogoDownload />  </RequiredAuth>} />
              <Route path="/settings/invoice" element={<RequiredAuth>    <UserInvoice />  </RequiredAuth>} />
              <Route path="/settings/membership" element={<RequiredAuth>  <UserMemberShip />  </RequiredAuth>} />
              <Route path="/settings/networks" element={<RequiredAuth>   <UserNetwork />  </RequiredAuth>} />
              <Route path="/settings/messaging" element={<RequiredAuth>  <UserMessage />  </RequiredAuth>} />
              <Route path="/settings/user-management" element={<RequiredAuth>  <UserManagement /> </RequiredAuth>} />
              <Route path="/settings/usermanagement/adduser" element={<RequiredAuth>   <UserAdd />  </RequiredAuth>} />
              <Route path="/settings/blog-management" element={<RequiredAuth>  <UserBlogSection />  </RequiredAuth>} />
            </Route>

            <Route path="/bookmarks" element={<RequiredAuth>  <BookmarkPage />  </RequiredAuth>} />
            <Route path="/bookmarks/firm" element={<RequiredAuth>  <FirmBookmarkpage />  </RequiredAuth>} />
            <Route path="/bookmarks/registered-individuals" element={<RequiredAuth>  <RegistrantBookmarkpage />  </RequiredAuth>} />

            <Route path="/reset-password" element={<ForgotPassword />} />
            <Route path="/change-password/:token" element={<ChangePassword />} />

            <Route path="*" element={<RequiredAuth>  <Error />  </RequiredAuth>} />
            <Route path="/not-found" element={<RequiredAuth><Error /></RequiredAuth>} />
            <Route path="/admin/not-found" element={<RequiredAuth><Error /></RequiredAuth>} />


            <Route path="/admin/login" element={<AdminLogin />} />

            <Route path="/admin" element={<DashboardLayout />}>
              <Route path="/admin" element={<Dashboard />} />
              <Route path="/admin/pcmamembers" element={<AdminUser />} />
              <Route path="/admin/pcmamembers/:id" element={<AdminUserView />} />
              <Route path="/admin/pcmamembers-edit/:id" element={<AdminsersForm />} />
              <Route path="/admin/firms" element={<AdminFirms />} />
              <Route path="/admin/firms/:id" element={<Firmdetail />} />
              <Route path="/admin/firmupdate/:id" element={<AdminFirmUpdate />} />
              <Route path="/admin/registered-individuals-details/:id" element={<AdminRegistrantDetails />} />
              <Route path="/admin/notice" element={<Message />} />
              <Route path="/admin/adminprofile" element={<AdminProfile />} />
              <Route path="/admin/pcmamembers/add" element={<AdminAddUser />} />
              <Route path="/admin/pricing/subscription" element={<Subscription />} />
              <Route path="/admin/pricing/tax-management" element={<TaxManagement />} />
              <Route path="/admin/payment" element={<Payment />} />
              <Route path="/admin/payment/:id" element={<AdminTransaction />} />
              <Route path="/admin/promo-code" element={<PromoCode />} />
              <Route path="/admin/registered-individuals" element={<AdminRegistrant />} />
              <Route path="/admin/promo-code-add" element={<PromoCodeAdd />} />
              <Route path="/admin/promo-code-edit/:id" element={<PromoCodeEdit />} />
              <Route path="/admin/flag/firm-flag" element={<FirmFlag />} />
              <Route path="/admin/flag/firm-flag/details/:id" element={<FirmFlagDetails />} />
              <Route path="/admin/flag/registrant-flag" element={<RegistrantFlag />} />
              <Route path="/admin/flag/registrant-flag/details/:id" element={<RegistrantFlagDetails />} />
            </Route>
          </Routes>:
          <Routes>           
            <Route path="/" element={<NoInternet />} />
          </Routes>
        }
      </ErrorBoundary>

    </div>
  );
}

export default App;
