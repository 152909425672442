import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchFirms = createAsyncThunk('firms/fetchFirms', async (data) => {

    let token;
    const logindata = JSON.parse(window.localStorage.getItem("login"));

    if (logindata) { token = logindata.token; }
  else { token = null; }
  try {
    const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/firms/search`,
        {
          params: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

    return response.data;
    
  } catch (error) {
    // console.log('Error in Redux:', error);
    // throw error.response.data;
    return error;
  }
});

const firmSlice = createSlice({
  name: 'firms',
  initialState: {
    data: [],
    total: 0,
    total_pages: 0,
    isLoading: false,
    error: null,
    message:''
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFirms.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchFirms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.total = action.payload.total;
        state.total_pages = action.payload.total_pages;
        state.message = action.payload.message;
      })
      .addCase(fetchFirms.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default firmSlice.reducer;
