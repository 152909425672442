import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import axios from "axios";
import { Container, Row, Col, Form, Table, Button, Modal } from "react-bootstrap";
import toast from 'react-hot-toast';
import ResponsivePagination from "react-responsive-pagination";
import AdminTransaction from "../../components/AdminTransaction/AdminTransaction";
import { BallTriangle } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setPageNumber } from '../../redux/slice/firmdNumber.js';
import CustomPagination from "../../components/pagination/CustomPagination.jsx";

const Payment = () => {
  const loginData = JSON.parse(window.localStorage.getItem("loginAdmin"));
  const token = loginData.Token;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewData, setViewData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState('');
  const [show, setShow] = useState(false);
  const [transactionValue, setTransactionValue] = useState(true);
  const [userID, setUserID] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [invoiceNo, setInvoiceNo] = useState('');
  const [name, setName] = useState('');
  const [organization, setOrganization] = useState('');

  useEffect(() => {
        document.title = "PCMA || Payment History";
    }, []);

  useEffect(() => {
    paymentapi();
  }, [currentPage]);

  const dispatch = useDispatch();
  dispatch(setPageNumber(1));

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filterEmojis = (input) => {
    const emojiRegex = /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{2600}-\u{26FF}|\u{2300}-\u{23FF}|\u{2B50}|\u{23CF}|\u{2934}|\u{2935}|\u{25AA}|\u{25AB}|\u{2B06}|\u{2194}-\u{21AA}|\u{2B50}]/ug;
    const specialCharRegex = /[^a-zA-Z0-9\s@#-_]/g; 
    let sanitizedValue = input.replace(emojiRegex, ''); 
    sanitizedValue = sanitizedValue.replace(specialCharRegex, ''); 
    sanitizedValue = sanitizedValue.trimStart();
    return sanitizedValue;
};

  
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const filteredValue = filterEmojis(value);
    setInvoiceNo(filteredValue);
  };
  
  const handleInputChangeName = (e) => {
    const value = e.target.value;
    const filteredValue = filterEmojis(value);
    setName(filteredValue);
  };
  
  const handleInputChangeOrganization = (e) => {
    const value = e.target.value;
    const filteredValue = filterEmojis(value);
    setOrganization(filteredValue);
  };
  


  const useTransactionOpen = () => {
    setTransactionValue(!transactionValue);
  };

  const navigate = useNavigate();

  const handleTransaction = async (ids) => {
    navigate(`/admin/payment/${ids}`)
    
    // setTransactionValue(!transactionValue);
    // setUserID(ids);
  };


  const convertUnixTimeToCanadaDate = (unixTimestamp, canadaTimeZone) => {
    const utcDate = new Date(unixTimestamp * 1000);
    const canadaDate = new Date(utcDate.toLocaleString('en-US', { timeZone: canadaTimeZone }));
    const canadaDateString = canadaDate.toISOString().split('T')[0];
    return canadaDateString;
  };
  
  const [modalLoader, setModalLoader] = useState(false);
  

  const handleShow = async (ids) => {
      
        
    try {
      const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/subscriber-payment-logs/${ids}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.product) {
        setViewData([response?.data?.product]);
        setShow(true);
      }
    } catch (error) {
      console.log(error);
    }

  };

  const handleClose = () => {
    setShow(false);
  }

  const handleDownload = (url) => {
    window.open(url);
  };

  const [emailLoader, setEmailLoader] = useState({});


  const sendMail = async (id) => {
    setEmailLoader(prevState => ({
      ...prevState,
      [id]: true
    }));

    try {
      const response = await axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/payment-details-send`, {
        subscription_id: id
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.status === true && response.data.message === "Send") {
        toast.success("Mail sent");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }

    setEmailLoader(prevState => ({
      ...prevState,
      [id]: false
    }));
  };
  const [disbaleFilter, setDisbaleFilter] = useState(false);

  const reset =async()=>{
    if(selectedOption !== null || invoiceNo?.length>0 || name?.length>0 || organization?.length>0){
      setDisbaleFilter(false)
    
    setIsLoading(true);
    setSelectedOption(null);
    setInvoiceNo('');
    setName('')
    setOrganization('');
    try {
      const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/subscription-logs`, {
        params: {
          // page: Number(currentPage),
          subscription_status: null,
          invoice_number: '',
          member_name: '',
          organization:''
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data) {
        setData(response?.data?.data);
        setTotalPage(response?.data?.last_page);
        setCurrentPage(1);
      }
      setIsLoading(false);
    } catch (error) {
      // console.log("error:", error);
      setIsLoading(false);
    }
  }

  }



  const handleSearch =async ()=>{
    if(selectedOption !== null || invoiceNo?.length>0 || name?.length>0 || organization?.length>0){
      setDisbaleFilter(true)
      setCurrentPage(1);
      paymentapi();
      
    }
  };

  const DropdownIndicator = (props) => {
    return components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? <i class="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}
      </components.DropdownIndicator>
    );
  };



  const paymentapi = async () => {
    setIsLoading(true);
    // if(selectedOption !== null || invoiceNo?.length>0 || name?.length>0 || organization?.length>0){
    //   setCurrentPage(1);
    // }
    try {

      const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/subscription-logs`, {
        params: {
          page: Number(currentPage),
          subscription_status: selectedOption?.value,
          invoice_number: invoiceNo,
          member_name: name,
          organization:organization
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data) {
        setData(response?.data?.data);
        setTotalPage(response?.data?.last_page);
      }
      setIsLoading(false);
    } catch (error) {
      // console.log("error:", error);
      setIsLoading(false);
    }
  };


  const [isDisable, setIsDisable] = useState(false)

  const downloadCsv = async () => {

    try {
      setIsDisable(true)
      const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/export-subscriptions-logs`, {
        params: {
          // page: Number(currentPage),
          subscription_status: selectedOption?.value,
          invoice_number: invoiceNo,
          member_name: name,
          organization: organization
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });
  
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Payment List.csv');
      document.body.appendChild(link);
      link.click();
  
      setIsLoading(false);
      setTimeout(() => {
        setIsDisable(false);
       }, 3000);
    } catch (error) {
      // console.log("error:", error);
      setIsLoading(false);
      setTimeout(() => {
              setIsDisable(false);
      }, 3000);

    }
  }
  const options = [
    { value: 'active', label: 'Active' },
    { value: 'incomplete', label: 'Incomplete' },
    { value: 'past due', label: 'Past due' },
    { value: 'canceled', label: 'Canceled' },
    { value: 'ended', label: 'Ended' },
    { value: 'trialing', label: 'Trialing' },
    { value: 'unsubscribed', label: 'Unsubscribed' },
    { value: 'scheduled to cancel', label: 'Scheduled to cancel' },
    { value: 'on hold', label: 'On hold' },
  ];


  return (
    <>
      {isLoading ? (
        <Container fluid className="loader">
          <Row
            className="g-2 pb-3 d-flex justify-content-center align-content-center "
            style={{ height: "79vh" }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-content-center"
            >
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0f66b4"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Col>
          </Row>
        </Container>
      ) : (


        <>
          {
           
            <>

              <div className="row pt-4">
                <div className="col-12 mt-2"
                >
                  <div style={{ background: "#137CD9", color: "#fff", borderRadius: '5px' , margintop: "20px", }}>
                    <h4 className="mb-0 py-2 px-3">Payment</h4>
                  </div>
                </div>
              </div>
              <div className="row  g-3 mt-4 areaWrapping">

                <div className="col-sm-6 col-md-4 col-lg-3 ">
                  <div className={`payment_search filter_div w-100 ${selectedOption ? 'active' : ''}`} style={{ maxWidth: '100%' }}>
                    <label htmlFor="">Status:</label>
                    <Select
                      value={selectedOption}
                      onChange={handleChange}
                      options={options}
                      style={{
                        background: "#FFF",
                      }}
                      isDisabled={disbaleFilter}
                      components={{ DropdownIndicator }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-auto col-xl-3">
                  <div className={` payment_search filter_div ${false ? 'active' : ''}`} style={{ maxWidth: '100%' }}>
                    <label htmlFor="">Invoice No.:</label>
                    <div>
                      <input
                        type="text"
                        className="form-control payment_search_input"
                        id="formGroupExampleInput"
                        placeholder="Enter Invoice Number"
                        value={invoiceNo}
                        onChange={handleInputChange}
                        disabled={disbaleFilter}
                      />
                    </div>
                  </div>
                </div>


                <div className="col-sm-6 col-md-auto col-xl-3">
                  <div className={` payment_search filter_div ${false ? 'active' : ''}`} style={{ maxWidth: '100%' }}>
                    <label htmlFor="">Member Name (Person):</label>
                    <div>

                      <input type="text" className="form-control payment_searc_input" id="formGroupExampleInput" placeholder="Enter Member Name" value={name} 
                        onChange={handleInputChangeName} disabled={disbaleFilter} />

                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-auto col-xl-3">
                  <div className={` payment_search filter_div ${false ? 'active' : ''}`} style={{ maxWidth: '100%' }}>
                    <label htmlFor="">Organization (Entity):</label>
                    <div>

                      <input type="text" className="form-control payment_searc_input" id="formGroupExampleInput" placeholder="Enter Organization Name" value={organization}
                       onChange={handleInputChangeOrganization} disabled={disbaleFilter} />

                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="h-100 d-flex  align-items-end " style={{gap:'10px'}}>
                    <button className="payment_search_btn" onClick={handleSearch}>Search</button>
                    {/* <button
                  className="reset_button btn"
                  onClick={reset}
                  style={{ cursor: 'pointer' }}
                >
                  <i
                    className="fa-solid fa-arrow-rotate-right"
                    style={{ color: "#0F66B4" }}
                  ></i>
*/}
                     <div
                      className="tooltip-container resetTooltip pl-3"
                      >
                      <button
                        className="reset_button btn"
                        onClick={reset}
                        style={{ cursor: 'pointer' }}
                      >
                        <i
                          className="fa-solid fa-arrow-rotate-right"
                          style={{ color: "#0F66B4" }}
                        ></i>
                      </button>
                      <div className="tooltip-text">Reset</div>
                    </div> 
                {/* </button>  */}
                  </div>

                   {/* <div className="tooltip-container pl-3">
                      <button
                        className="reset_button btn"
                        onClick={reset}
                        style={{ cursor: 'pointer' }}
                      >
                        <i
                          className="fa-solid fa-arrow-rotate-right"
                          style={{ color: "#0F66B4" }}
                        ></i>
                      </button>
                      <div className="tooltip-text">Reset</div>
                    </div> */}
                </div>
{
  data?.length ==0 ? 
  
  <div className="col-12">
  <div className=" d-flex align-content-center justify-content-end">
    <button className="payment_csv_btn" onClick={downloadCsv} disabled style={{cursor:'not-allowed'}}>Export CSV <i className="fa-solid fa-download"></i></button>
  </div>
</div>
:

<div className="col-12">
<div className=" d-flex align-content-center justify-content-end">
  <button className="payment_csv_btn" onClick={downloadCsv} disabled={isDisable}>Export CSV <i className="fa-solid fa-download"></i></button>
</div>
</div>
}
                {/* <div className="col-12">
                  <div className=" d-flex align-content-center justify-content-end">
                    <button className="payment_csv_btn" onClick={downloadCsv} disabled={isDisable}>Export CSV <i className="fa-solid fa-download"></i></button>
                  </div>
                </div> */}
              </div>
              {data?.length === 0 ? (
                <div className="d-flex align-items-center justify-content-center">

                  <h3>No Data Found </h3>
                </div>

              ) : (
                <div className="row  mt-3">
                  <div className="col-12 py-2" 
                  // style={{ background: "#fff", border: "2px solid rgba(15, 102, 180, 0.2)", borderRadius: "5px" }} 
                  >
                    <div className="subcription_table">
                    <div className="table-responsive firms_card_box">
                        <Table className="w-100">
                          <thead>
                            <tr>

                              <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:"0"}}>Subscription Name</th>
                              <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:"0"}}>Member Name</th>
                              <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:"0"}}>Organization</th>
                              <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:"0"}}>Payment Option</th>
                              <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:"0"}}>Invoice Date</th>
                              <th className="text-center" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:"0"}}>Invoice No</th>
                              <th className="text-center" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:"0"}}>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => (
                              <tr key={index}>

                                <td>{item.subscription_name}</td>
                                <td>{item.member_name}</td>
                                <td>{item.organization}</td>
                                <td>Credit card</td>
                                <td> {item.invoice_date}</td>
                                <td style={{ textAlign:"center"}}>{item.invoice_number}</td>
                                <td>
                                  <div className="payment_table_btn d-flex align-items-center justify-content-center">
                                    <button type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="View latest invoice">
                                      <i className="fa-regular fa-eye" onClick={() => { handleShow(item.subscription_id) }}></i>
                                    </button>
                                    <button data-bs-toggle="tooltip" data-bs-placement="left" title="Download latest invoice">
                                    <i className="fa fa-download"  aria-hidden="true" onClick={() => { handleDownload(item?.invoice_pdf) }}></i>
                                    </button>
                                    <button data-bs-toggle="tooltip" data-bs-placement="left" title="Transaction history">
                                      <i className="fa-regular fa-file-lines" onClick={() => { handleTransaction(item?.member_id) }}></i>
                                    </button>
                                    <button data-bs-toggle="tooltip" data-bs-placement="left" title="Send email ">
                                    {emailLoader[item.subscription_id] ? (
                                    <div className="spinner-border" style={{maxWidth: "20px", maxHeight: "20px",color:"#0f66b4", textAlign:"center"}}></div>
                                     ) : (
                                    <i className="fa-regular fa-envelope" onClick={() => { sendMail(item.subscription_id) }}></i>
                                    )}
                                    </button>
                                  </div>
                                </td>

                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              )}


              <Container fluid className="py-2 mb-4">

                {data?.length === 0 ? (
                  <h3> </h3>
                ) : (
                  <Row>
                    <Col
                      sm={12}
                      className="d-flex justify-content-start"
                      style={{ padding: 0 }}
                    >

                      <CustomPagination
                        total={totalPage}
                        maxWidth={7}
                        current={currentPage}
                        onPageChange={(page) => handlePageChange(page)}
                      />
                    </Col>
                  </Row>
                )}
              </Container>

            </> 
          }

          <Modal centered backdrop="true" keyboard={false} size="lg" show={show} onHide={handleClose} >
            <Modal.Body>
              <div className="form p-3" >
                {viewData && viewData?.map((data, i) => (
                  <div className="row" key={i}>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Customer Name</Form.Label>
                        <Form.Control type="text" 
                          value={data.customer_name || ''}
                          //   onChange={handleChanges} 
                          readOnly
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Invoice Date</Form.Label>
                        <Form.Control type="text"
                          value={convertUnixTimeToCanadaDate(data?.invoice_date) || ''}
                          //   onChange={handleChanges} 
                          readOnly
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Invoice Number</Form.Label>
                        <Form.Control type="text"
                          value={data?.invoice_number || ''}
                          //   onChange={handleChanges} 
                          readOnly
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Order Total </Form.Label>
                        <Form.Control type="text"
                          value={data?.order_total?.toFixed(2) || ''}
                          //   onChange={handleChanges} 
                          readOnly
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Subscription Name</Form.Label>
                        <Form.Control type="text"
                          value={data?.subscription_name || ''}
                          //   onChange={handleChanges} 
                          readOnly
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Subscription Status</Form.Label>
                        <Form.Control type="text"
                          value={data?.subscriptions_status || ''}
                          //   onChange={handleChanges} 
                          readOnly
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>

                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Current Period End</Form.Label>
                        <Form.Control type="text"
                          value={convertUnixTimeToCanadaDate(data?.current_period_end) || ''}
                          //   onChange={handleChanges} 
                          readOnly
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>

                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Current Period Start</Form.Label>
                        <Form.Control type="text"
                          value={convertUnixTimeToCanadaDate(data?.current_period_start) || ''}
                          //   onChange={handleChanges} 
                          readOnly
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-center align-items-center">

                <button className="subcription_cancel ms-2" onClick={handleClose}>Close</button>

              </div>
            </Modal.Body>
          </Modal>

        </>


      )
      }</>)
}
export default Payment