// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .loading-box {
     display: grid;
     place-items: center;
     height: 100px;

 }

 .loaders div {
     height: 20px;
     width: 20px;
     border-radius: 50%;
     transform: scale(0);
     animation: animate 1.5s ease-in-out infinite;
     display: inline-block;
     margin: .5rem;
 }

 .loaders div:nth-child(0) {

     animation-delay: 0s;
 }

 .loaders div:nth-child(1) {

     animation-delay: 0.2s;
 }

 .loaders div:nth-child(2) {

     animation-delay: 0.4s;
 }

 .loaders div:nth-child(3) {

     animation-delay: 0.6s;
 }

 .loaders div:nth-child(4) {

     animation-delay: 0.8s;
 }

 .loaders div:nth-child(0) {

     animation-delay: 1s;
 }

 .loaders div:nth-child(0) {

     animation-delay: 1.2s;
 }

 @keyframes animate {

     0%,
     100% {
         transform: scale(0.2);
         background-color: #d3e2f1;
     }

     40% {
         transform: scale(1);
         background-color: rgba(15, 102, 180, 1);

     }

     50% {
         transform: scale(1);
         background-color: #0056b3;
     }

 }`, "",{"version":3,"sources":["webpack://./src/components/loader/CustomLoader.css"],"names":[],"mappings":"CAAC;KACI,aAAa;KACb,mBAAmB;KACnB,aAAa;;CAEjB;;CAEA;KACI,YAAY;KACZ,WAAW;KACX,kBAAkB;KAClB,mBAAmB;KACnB,4CAA4C;KAC5C,qBAAqB;KACrB,aAAa;CACjB;;CAEA;;KAEI,mBAAmB;CACvB;;CAEA;;KAEI,qBAAqB;CACzB;;CAEA;;KAEI,qBAAqB;CACzB;;CAEA;;KAEI,qBAAqB;CACzB;;CAEA;;KAEI,qBAAqB;CACzB;;CAEA;;KAEI,mBAAmB;CACvB;;CAEA;;KAEI,qBAAqB;CACzB;;CAEA;;KAEI;;SAEI,qBAAqB;SACrB,yBAAyB;KAC7B;;KAEA;SACI,mBAAmB;SACnB,uCAAuC;;KAE3C;;KAEA;SACI,mBAAmB;SACnB,yBAAyB;KAC7B;;CAEJ","sourcesContent":[" .loading-box {\n     display: grid;\n     place-items: center;\n     height: 100px;\n\n }\n\n .loaders div {\n     height: 20px;\n     width: 20px;\n     border-radius: 50%;\n     transform: scale(0);\n     animation: animate 1.5s ease-in-out infinite;\n     display: inline-block;\n     margin: .5rem;\n }\n\n .loaders div:nth-child(0) {\n\n     animation-delay: 0s;\n }\n\n .loaders div:nth-child(1) {\n\n     animation-delay: 0.2s;\n }\n\n .loaders div:nth-child(2) {\n\n     animation-delay: 0.4s;\n }\n\n .loaders div:nth-child(3) {\n\n     animation-delay: 0.6s;\n }\n\n .loaders div:nth-child(4) {\n\n     animation-delay: 0.8s;\n }\n\n .loaders div:nth-child(0) {\n\n     animation-delay: 1s;\n }\n\n .loaders div:nth-child(0) {\n\n     animation-delay: 1.2s;\n }\n\n @keyframes animate {\n\n     0%,\n     100% {\n         transform: scale(0.2);\n         background-color: #d3e2f1;\n     }\n\n     40% {\n         transform: scale(1);\n         background-color: rgba(15, 102, 180, 1);\n\n     }\n\n     50% {\n         transform: scale(1);\n         background-color: #0056b3;\n     }\n\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
