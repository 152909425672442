import axios from "axios";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, } from "react-bootstrap";
import { BallTriangle } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Select, { components } from "react-select";
import { Country, State } from "country-state-city";




const AdminAddUser = () => {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [industryError, setIndustryError] = useState(false);
  const [pwdType, setPwdType] = useState("password");
  const [confirmpwdType, setConfirmpwdType] = useState("password");
  const [selectedPhone_code, setSelectedPhone_code] = useState("");
  const [address, setAddress] = useState({
    country: null,
    state: null,
  });
  const [isEmailValid, setIsEmailValid] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState("");
  const [isUsernameValid, setIsUsernameValid] = useState("");
  const [countryCode, setCountryCode] = useState("IN");
  const countries = Country.getAllCountries();
  const capitalizeFirstLetter = (str) => { return str?.charAt(0)?.toUpperCase() + str?.slice(1); };
  const { Token } = JSON.parse(localStorage.getItem("loginAdmin"));
  let countryName = "";
  let stateName = "";
  let olderror;

  const openEye = (value) => {
    if (value === 1) {
      setPwdType("text");
    } else if (value === 2) {
      setPwdType("password");
    }

    if (value === 3) {
      setConfirmpwdType("text");
    } else if (value === 4) {
      setConfirmpwdType("password");
    }
  };


  let regsiter_data = JSON.parse(
    window.localStorage.getItem("register_data")
  );
  let user = regsiter_data?.username;
  let orgination = regsiter_data?.organizationName;




  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    username: "",
    password: "",
    website: "",
    address: "",
    city: "",
    organization: "",
    membership_type: '',
    postal_code: "",
    phone_code: '',
    phone: "",
    fax: "",
    company_description: "",
    confirmPassword: "",
    address_contact: "",
    confirmEmail: "",
    province: "",
    country: "",
    gender: ""
  });

  const [errors, setErrors] = useState({
    full_name: "",
    email: "",
    username: "",
    organization: "",
    password: "",
    website: "",
    address: "",
    city: "",
    phone_code: '',
    phone: '',
    phone_code: '',
    membership_type: '',
    fax: "",
    company_description: "",
    confirmPassword: "",
    address_contact: "",
    confirmEmail: "",
    Industry_Preferences: "",
    province: "",
    country: "",
  });

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));

  const updatedStates = Object.values(
    State.getStatesOfCountry(countryCode)
  ).map((state) => ({
    label: state.name,
    value: state.id,
    ...state,
  }));


  const membershipType = [
    "Accounting Firm",
    "Exempt Market Dealer",
    "Venture Capitalist",
    "Mortgage Investment Entity",
    "Angel Investors",
    "Exempt Market Professional",
    "Law Firm",
    "Consulting Firm",
    "Compliance Firm",
    "Financial Industry Marketing Firm",
    "Issuer of Exempt Market Products",
    "Individual / EMD Dealing Representative",
  ];

  const DropdownIndicator = (props) => {
    return components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? <i class="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}
      </components.DropdownIndicator>
    );
  };

  const MembershipTypes = membershipType.map((value, index) => ({
    value: value.replace(/\s+/g, "_"),
    label: value,
  }));

  const handleCountryChange = (value) => {
    countryName = value.name;
    setFormData({
      ...formData,
      country: value.name,
    });
    countryValidateField("country", countryName);
    setCountryCode(value.isoCode);
    handleAddressChange({ country: value, state: null });
  };

  const handleStateChange = (label) => {
    setFormData({
      ...formData,
      province: label.name,
    });
    stateName = label.name;

    stateValidateField("province", stateName);
    handleAddressChange({ ...address, state: label });
  };

  const stateValidateField = (fieldName, value) => {
    if (fieldName === 'province') {
      errors.province = value === '' ? 'The province field is required.' : '';
    }
  };

  const countryValidateField = (fieldName, value) => {
    if (fieldName === 'country') {
      errors.country = value === '' ? 'The country field is required.' : '';
    }
  };

  function phoneFormat(input) {
    input = input?.replace(/\D/g, "");
    input = input?.trim();
    input = input?.substring(0, 10);
    var size = input?.length;
    if (size === 0) {
      input = input;
    }
    else if ((size < 4) && (size > 1)) {
      input = "(" + input;
    }
    else if ((size < 7) && (size > 1)) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    }
    else if ((size <= 11) && (size > 1)) {
      input =
        "(" +
        input.substring(0, 3) +
        ") " +
        input.substring(3, 6) +
        "-" +
        input.substring(6, 11);

    }
    return input;
  }

  const phoneCode = [
    { value: "+1", label: "+1 USA/CAN" },
    { value: "+44", label: "+44 GBR" },
    { value: "+46", label: "+46 SWE" },
    { value: "+852", label: "+852 HKG" }
  ];

  const handlePhoneCode = (code) => {
    setSelectedPhone_code(code?.value)
    validateField('phone_code', code.value)
  };

  const handleKeyPress2 = (e) => {
    const char = String.fromCharCode(e.keyCode || e.which);
    const regex = /^[a-zA-Z\s.]+$/;
    if (!regex.test(char)) {
      e.preventDefault();
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value?.trimStart();
    setFormData({
      ...formData,
      [name]: trimmedValue,
    });
    passwordhandal(name, value)
    validateField(name, value);
    if (name === 'email') {
      checkEmail(value);
   }
   if (name === 'username') {
      checkUsername(value);
    }
    if (name === "phone") {
      checkPhone(value);
    }
    if (name === 'username') {
      checkUsername(value);
    }

  }

  let newErrors = { ...errors };

  const validateField = (fieldName, value) => {


    if (fieldName === 'name') {
      newErrors.name = value?.trim() === '' ? 'Name is required.' : '';
    }



    if (fieldName === 'email') {
      newErrors.email =
        value?.trim() === ''
          ? 'Email is required.'
          : !isValidEmail(value)
            ? 'Invalid email address.'
            : '';
    } else if (fieldName === 'confirmEmail') {
      newErrors.confirmEmail =
        value !== formData.email
          ? 'Emails do not match.'
          : value?.trim() === ''
            ? 'Confirm Email is required.'
            : '';
    }

    if (fieldName === 'membership_type') {
        newErrors.membership_type = value === '' ? 'Please select a Membership Type' : '';
    }
    if (fieldName === 'phone_code') {
      if (!selectedPhone_code) {
        newErrors.phone_code = value === '' ? 'Please select a Phone Code' : '';
      }
    }
    if (fieldName === 'full_name') {
      newErrors.full_name = value?.trim() === '' ? 'Full Name is required.' : (value?.length > 30 ? 'Full name should be maximum 30 characters long' : '');
    }
    if (fieldName === 'username') {
      newErrors.username = value?.trim() === '' ? 'Username is required.' : '';
    }
    if (fieldName === 'address') {
      newErrors.address = value?.trim() === '' ? 'Address is required.' : '';
    }
    if (fieldName === 'city') {
      newErrors.city = value?.trim() === '' ? 'City is required.' : '';
    }
    if (fieldName === 'postal_code') {
      let pattern = /^(?=.*[0-9])[0-9a-zA-Z\s-]{5,10}(?<!-)$/ ;
      if (value.length =='') {
        newErrors.postal_code = 'Postal code is required.'
      }
      else if (!pattern.test(value)) {
        newErrors.postal_code = 'Invalid  postal code';
      } else {
        newErrors.postal_code = ''
      }
    }
    if (fieldName === 'company_description') {
      newErrors.company_description = value?.trim() === '' ? 'Company description is required.' : '';
    }
    if (fieldName === "organization") {
      newErrors.organization = value?.trim() === "" ? "Organization is required." : "";
    }
    if (fieldName === 'province') {
      if (formData.province === '') {
        newErrors.province = 'The Province is required.';
      }
      else {
        newErrors.province = ''
      }
    }
    if (fieldName === 'fax') {
      let pattern = /^[0-9+]+$/;
      if (formData.fax === '') {
        newErrors.fax = ''
      }
      else if (!pattern.test(value)) {
        newErrors.fax = 'Use only numbers or plus';
      } else {
        newErrors.fax = ''
      }
    }

    if (fieldName === 'country') {
      newErrors.country = formData.country?.trim() === '' ? 'The country field is required' : '';
    }

    if (fieldName === 'phone') {
      newErrors.phone = value === '' ? 'Phone is required.' : '';
    }

    if (fieldName === "fax") {
      const faxRegex = /^\+?[0-9]*$/;
      if (!faxRegex.test(value)) {
        newErrors.fax = "Invalid fax number";
      }
    }
    setErrors(newErrors);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const checkUsername = async (username) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/users-check`,
        {
          username: username,
        }
      );

      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error(`Unauthozied user`);
      }

      if (response.data.message === "Already Exists") {
        setIsUsernameValid(response.data.message);
      } else {
        setIsUsernameValid("");
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };

  const checkEmail = async (email) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/users-check`,
        {
          email: email,
        }
      );

      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error(`Unauthozied user`);
      }

      if (response.data.message === "Already Exists") {
        setIsEmailValid(response.data.message);
      } else {
        setIsEmailValid("");
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };

  const checkPhone = async (phone) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/users-check`,
        {
          phone: phone,
        }
      );
      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error(`Unauthozied user`);
      }

      if (response.data.message === "Already Exists") {
        setIsPhoneValid(response.data.message);
      } else {
        setIsPhoneValid("");
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };

  const passwordhandal = (name, value) => {
    if (name === 'password') {
      formData.password = value;
      const isValidPassword = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$_#^~!%*?&])[A-Za-z\d@$!%_#^~*?&]{8,}$/.test(value);
      if (value) {
        newErrors.password = '';
        if (!isValidPassword) {
          newErrors.password = 'Password must be 8 to 20 characters and one Number, one Special character and one Capital letter.';
        }
        else {
          newErrors.password = '';
        }
      } else {
        newErrors.password = 'Password is required.';
      }
    }

    if (name === 'confirmPassword') {
      formData.confirmPassword = value;
      if (value) {
        if (formData?.password === value) {
          newErrors.confirmPassword = '';
        }
        else {
          newErrors.confirmPassword = 'Password does not match';
        }
      }
      else {
        newErrors.confirmPassword = 'Confirm Password is required.'
      }
    }

  }

  const handleKeyPress = (e) => {
    const char = String.fromCharCode(e.keyCode || e.which);
    if (!/^[0-9+]+$/.test(char)) {
      e.preventDefault();
    }
  };
  

  const handleCheckboxChange = (event) => {
    const { value } = event.target;

    if (selectedCheckboxes?.length === 0) {
      setIndustryError(true);
    }

    if (value === "Select All / None") {
      if (selectedCheckboxes?.includes(value)) {
        setSelectedCheckboxes([]);
      } else {
        setSelectedCheckboxes([
          "Select All / None",
          "Private Placements Mining",
          "Private Placements Oil and Gas",
          "Private Placements - Other",
          "Portfolio Management with Exempt Clients",
          "Mergers and Acquisitions",
          "Private Placements - Real Estate",
          "Distributor of Exempt Investments",
          "Other"
        ]);
      }
    } else {
      const updatedCheckboxes = selectedCheckboxes?.includes(value)
        ? selectedCheckboxes.filter((checkbox) => checkbox !== value)
        : [...selectedCheckboxes, value];

      setSelectedCheckboxes(updatedCheckboxes);
    }
  };
  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleSelectChange = (e) => {
    let selectedValue = e?.value
    formData.membership_type=selectedValue
    validateField('membership_type',selectedValue)
  };




  const handleCancel = (e) => {
    navigate('/admin/pcmamembers')
  };

  const validateForm = () => {
    for (const fieldName in formData) {
      if (formData.hasOwnProperty(fieldName)) {
        validateField(fieldName, formData[fieldName]);
        passwordhandal(fieldName, formData[fieldName]);
        countryValidateField(fieldName, formData[fieldName]);
        stateValidateField(fieldName, formData[fieldName]);
      }
    }

    setSpinner(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSpinner(true);
    validateForm();
    let country;
    let province;
    if (address.country) {
      country = address.country.name;
    }
    if (address.state) {
      province = address.state.name;
    }

    if (selectedCheckboxes.length === 0) {
      newErrors.Industry_Preferences = 'Industry Preference is required.'
    }
    else {
      newErrors.Industry_Preferences = ''
    }
    if (country === undefined) {
      country = ''
    }
    if (province === undefined) {
      province = ''
    }

    if (!Object.values(newErrors).some((error) => error !== '') && isUsernameValid === '' && isEmailValid === '' && isPhoneValid === '') {
      setSpinner(true);
      if (formData) {
        axios
          .post(
            `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/add-user`,
            {
              name: formData?.full_name,
              email: formData?.email,
              username: formData?.username,
              password: formData?.password,
              organization: formData?.organization,
              website: formData?.website,
              address: formData?.address,
              city: formData?.city,
              country: country,
              province: province,
              membership_type: formData.membership_type,
              postal_code: formData?.postal_code,
              phone_code: selectedPhone_code,
              phone: formData?.phone,
              fax: formData?.fax,
              company_description: formData?.company_description,
              industry_preferences: selectedCheckboxes,
              password_confirmation: formData?.confirmPassword,
              address_cont: formData?.address_contact,
              gender: formData?.gender
            },
            {
              headers: {
                Authorization: `Bearer ${Token}`,
              },
            }
          )
          .then(function (response) {
            if (response?.data?.message === "Unauthozied User") {
              localStorage.removeItem("loginAdmin");
              navigate("/admin");
              toast.error(`Unauthozied user`);
            }

            if (response?.data) {
              toast.success(`${response.data.message}`);
              navigate("/admin/pcmamembers");
              setSpinner(false);
            } else {
              toast.success(`${response}`);
            }
          })
          .catch(function (error) {
            setSpinner(false);
          });
      }
    } else {
      setSpinner(false);
      setErrors(newErrors);
    }
  };


  return (
    <>
      <Container fluid>
        <div className="member-information">
          <Row>
            <Col lg={3} md={3} sm={12}>
              <div className="mb-2">
                <Link to="/admin/pcmamembers">
                  <span
                    // onClick={handleBack}
                    style={{
                      cursor: "pointer",
                      background: "#D9EBFB",
                      color: "#086CC5",
                      fontWeight: "600",
                      borderRadius: "6px",
                    }}
                    className="p-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="12"
                      viewBox="0 0 16 12"
                      fill="none"
                    >
                      <path
                        d="M3.825 6.9H3.58358L3.75429 7.07071L7.28295 10.5994L6.00062 11.8592L0.141421 6L6.00062 0.140798L7.28295 1.40063L3.75429 4.92929L3.58358 5.1H3.825H15.9V6.9H3.825Z"
                        fill="#086CC5"
                        stroke="#B2DAFD"
                        stroke-width="0.2"
                      />
                    </svg>
                    Back
                  </span>
                </Link>
              </div>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <h3>Member Information</h3>
              <Row>
                <Col xxl={12} lg={12} md={12} sm={12}>
                  <p>
                    Please fill out the following information in order to become a
                    member. Please Note: All information entered below will be
                    visible to site administrators. If you wish for information to
                    be excluded from your public profile (as seen by other site
                    visitors), uncheck the box(es) next to the field(s) you wish
                    to be hidden. If you uncheck the box next to “e-mail address”
                    it will not be displayed, but members can still contact you
                    via email through the site's group email system if permitted
                    by the group administrator.
                  </p>
                </Col>
                <Col lg={5} md={12} sm={12}></Col>
              </Row>
              <div className="account-information d-flex align-items-center px-4 mb-3">
                General Information
              </div>
              <Row className="mb-3">
                <Col xxl={4} md={6} sm={12} className="mb-3 mb-md-0">
                  <label htmlFor="name">
                    Full Name
                    <svg
                      className="ms-1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                      fill="none"
                    >
                      <path
                        d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                        fill="#F92828"
                      />
                    </svg>
                  </label>
                  <input
                    className={`form-control
                          ${((errors.full_name) && (formData.full_name === '')) && 'error_active'}
                          ${((errors.full_name === '') && (formData.full_name)) && 'sucess_active'}`}
                    id="full_name"
                    type="text"
                    name="full_name"
                    onKeyPress={handleKeyPress2}
                    value={capitalizeFirstLetter(formData?.full_name)}
                    onChange={handleChange}
                    placeholder="Enter Your Full Name"
                    maxLength={30}
                  />
                  <span style={{ color: "red" }}>{errors.full_name}</span>
                </Col>
                <Col xxl={4} md={6} sm={12}>
                  <div className="d-flex align-items-center">
                    <Form.Group className="Name py-2">
                      <label htmlFor="name" style={{ position: 'relative', top: '-7px', }}>
                        Gender
                      </label>
                      <div className="flex-wrap flex-sm-nowrap " >
                        <div className="form-check form-check-inline ">
                          <input className="form-check-input" type="radio" name="gender" id="inlineRadio1" value="male" onChange={handleChange} checked={formData?.gender === "male"} />
                          <label className="form-check-label" htmlFor="inlineRadio1">Male</label>
                        </div>
                        <div className="form-check form-check-inline ">
                          <input className="form-check-input" type="radio" name="gender" id="inlineRadio2" value="female" onChange={handleChange} checked={formData?.gender === "female"} />
                          <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="gender" id="inlineRadio3" value="others" onChange={handleChange} checked={formData?.gender === "others"} />
                          <label className="form-check-label" htmlFor="inlineRadio3">Others</label>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Col>
              </Row>

              <div className="account-information d-flex align-items-center px-4">
                Account Information
              </div>
            </Col>

            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col>
                  <Form onSubmit={handleSubmit}>
                    <div
                      className=" row g-3 account-informations"
                      style={{ padding: "2rem 0rem" }}
                    >
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Username
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <input
                          type="text"
                          id="username"
                          name="username"
                          value={formData?.username}
                          className={`form-control
                          ${((errors.username) && (formData.username === '') || (errors.username) || (isUsernameValid === "Already Exists")) && 'error_active'}
                          ${((errors.username === '') && (formData.username) && (isUsernameValid === '')) && 'sucess_active'}`}
                          onChange={handleChange}
                          placeholder="Enter Your Username"
                        />
                        {isUsernameValid === "Already Exists" ? (
                          <span className="text-danger mt-2">
                            Username is Already Exists. Please choose a different
                            one.
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>{errors?.username}</span>
                        )}
                      </Col>
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Password
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <div className=" position-relative password_eye">
                          <input
                            type={pwdType}
                            id="password"
                            name="password"
                            className={`form-control
                            ${(((errors.password) && (formData.password === '')) || (errors.password)) && 'error_active'}
                            ${((errors.password === '') && (formData.password)) && 'sucess_active'}`}
                            value={formData?.password}
                            onChange={handleChange}
                            placeholder="Enter Password"
                          />
                          {pwdType === "text" ? (
                            <i
                              className="fa-regular fa-eye"
                              onClick={() => openEye(2)}
                            ></i>
                          ) : (
                            <i
                              className=" fa-regular fa-eye-slash"
                              onClick={() => openEye(1)}
                            ></i>
                          )}
                        </div>
                        <span style={{ color: "red" }}>{errors?.password}</span>
                      </Col>
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Confirm Password
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <div className=" position-relative password_eye">
                          <input
                            type={confirmpwdType}
                            id="confirmPassword"
                            className={`form-control
                            ${(((errors.confirmPassword) && (formData.confirmPassword === '')) || (errors.confirmPassword)) && 'error_active'}
                            ${((errors.confirmPassword === '') && (formData.confirmPassword)) && 'sucess_active'}`}
                            name="confirmPassword"
                            placeholder="Enter Confirm Password"
                            value={formData?.confirmPassword}
                            onChange={handleChange}
                          />

                          {confirmpwdType === "text" ? (
                            <i
                              className="fa-regular fa-eye"
                              onClick={() => openEye(4)}
                            ></i>
                          ) : (
                            <i
                              className="fa-regular fa-eye-slash"
                              onClick={() => openEye(3)}
                            ></i>
                          )}
                        </div>
                        <span style={{ color: "red" }}>
                          {errors?.confirmPassword}
                        </span>
                      </Col>
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Email Address
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <input
                        className={`
                        form-control
                        ${(((errors.email) && (formData.email === '')) || (isEmailValid === 'Already Exists') || (errors.email)) && 'error_active'}
                        ${((errors.email === '') && (formData.email) && (isEmailValid === '')) && 'sucess_active'}`}
                          id="email"
                          type="text"
                          name="email"
                          value={formData?.email}
                          onChange={handleChange}
                          placeholder="Enter Your email"
                        />
                        {isEmailValid === "Already Exists" ? (
                          <span className="text-danger mt-2">
                            Email is Already Exists. Please choose a different
                            one.
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>{errors?.email}</span>
                        )}
                      </Col>
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Confirm Email Address
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <input
                         className={`
                         form-control
                         ${(((errors.confirmEmail) && (formData.confirmEmail === '')) || (errors.confirmEmail)) && 'error_active'}
                         ${((errors.confirmEmail === '') && (formData.confirmEmail)) && 'sucess_active'}`}
                          id="confirmEmail"
                          type="text"
                          name="confirmEmail"
                          value={formData?.confirmEmail}
                          onChange={handleChange}
                          placeholder="Enter Confirm Email"
                        />
                        <span style={{ color: "red" }}>
                          {errors.confirmEmail}
                        </span>
                      </Col>

                    </div>
                  </Form>
                </Col>
              </Row>
            </Col>

            <Col lg={12} md={12} sm={12}>
              <div className="account-information d-flex align-items-center px-4">
                Professionals Information
              </div>

              <Row>
                <Col>
                  <Form onSubmit={handleSubmit}>
                    <div
                      className=" row g-3 account-informations"
                      style={{ padding: "2rem 0rem" }}
                    >
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Organization
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <input
                          className={`form-control
                          ${(((errors.organization) && (formData.organization === '')) || (errors.organization)) && 'error_active'}
                          ${((errors.organization === '') && (formData.organization)) && 'sucess_active'}`}
                          id="organization"
                          type="text"
                          name="organization"
                          value={formData?.organization}
                          onChange={handleChange}
                          placeholder="Enter Your organization"
                        />
                        <span style={{ color: "red" }}>
                          {errors?.organization}
                        </span>
                      </Col>
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="membership">
                          Membership Type
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <Select
                          isSearchable
                          className={`
                          ${(((errors.membership_type) && (formData.membership_type === '')) || (errors.membership_type)) && 'error_active'}
                          ${((errors.membership_type === '') && (formData.membership_type)) && 'sucess_active'}`}
                          name="membership_type"
                          onChange={handleSelectChange}
                          options={MembershipTypes}
                        />
                        <span style={{ color: "red" }}>{errors?.membership_type}</span>
                      </Col>
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name"> Website</label>
                        <input
                          className={`form-control
                          ${((errors.website === '') && (formData.website)) && 'sucess_active'}`}
                          id="Website"
                          type="url"
                          name="website"
                          value={formData?.website}website
                          onChange={handleChange}
                          placeholder="Enter Your Website"
                        />
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Address
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <input
                          className={`form-control
                          ${(((errors.address) && (formData.address === '')) || (errors.address)) && 'error_active'}
                          ${((errors.address === '') && (formData.address)) && 'sucess_active'}`}
                          id="address"
                          type="text"
                          name="address"
                          value={formData?.address}
                          onChange={handleChange}
                          placeholder="Enter Your Address"
                        />

                        <span style={{ color: "red" }}>{errors?.address}</span>
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name"> Address Cont.</label>
                        <input
                          className={`form-control
                          ${((errors.address_contact === '') && (formData.address_contact)) && 'sucess_active'}`}
                          id="address_contact"
                          type="text"
                          name="address_contact"
                          value={formData?.address_contact}
                          onChange={handleChange}
                          placeholder="Enter Your Address"
                        />
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          City / Town
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <input
                           className={`form-control
                           ${(((errors.city) && (formData.city === '')) || (errors.city)) && 'error_active'}
                           ${((errors.city === '') && (formData.city)) && 'sucess_active'}`}
                          id="city"
                          type="text"
                          name="city"
                          value={formData?.city}
                          onChange={handleChange}
                          placeholder="Enter Your city"
                        />

                        <span style={{ color: "red" }}>{errors?.city}</span>
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Country
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <Select
                        className={`
                        ${(((errors.country) && (formData.country === '')) || (errors.country)) && 'error_active'}
                        ${((errors.country === '') && (formData.country)) && 'sucess_active'}`}
                          id="country"
                          name="country"
                          label="country"
                          options={updatedCountries}
                          value={address?.country}
                          onChange={handleCountryChange}
                          components={{ DropdownIndicator }}
                        />
                        <span style={{ color: "red" }}>{errors?.country}</span>
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Province
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <Select
                        className={`
                        ${(((errors.province) && (formData.province === '')) || (errors.province)) && 'error_active'}
                        ${((errors.province === '') && (formData.province)) && 'sucess_active'}`}
                          id="province"
                          name="province"
                          options={updatedStates}
                          value={address?.province}
                          onChange={handleStateChange}
                          disabled
                          components={{ DropdownIndicator }}
                        />

                        <span style={{ color: "red" }}>{errors?.province}</span>
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Postal Code
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <input
                         className={`form-control
                         ${(((errors.postal_code) && (formData.postal_code === '')) || (errors.postal_code)) && 'error_active'}
                         ${((errors.postal_code === '') && (formData.postal_code)) && 'sucess_active'}`}
                          id="postal_code"
                          type="text"
                          maxLength={10}
                          name="postal_code"
                          value={formData?.postal_code}
                          onChange={handleChange}
                          placeholder="Enter Your Postal Code"
                        />

                        <span style={{ color: "red" }}>{errors?.postal_code}</span>
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Phone
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <div className="d-flex align-content-center">
                          <div className="select_code">
                            <Select
                               className={`
                               ${(((errors.phone_code) && (formData.phone_code === '')) || (errors.phone_code)) && 'error_active'}
                               ${((errors.phone_code === '') && (formData.phone_code)) && 'sucess_active'}`}
                              type="text"
                              name="code"
                              value={phoneCode?.find(option => option?.value === selectedPhone_code)}
                              onChange={handlePhoneCode}
                              options={phoneCode}
                              isSearchable
                              placeholder="Code"
                              components={{ DropdownIndicator }}
                            />
                          </div>
                          <input
                            className={`
                            form-control ms-1
                              ${((errors.phone) && (formData.phone === '') || (isPhoneValid === 'Already Exists')) && 'error_active'}
                              ${((errors.phone === '') && (formData.phone) && (isPhoneValid === '')) && 'sucess_active'}
                            `}
                            id="phone"
                            type="text"
                            name="phone"
                            maxLength={14}
                            value={phoneFormat(formData?.phone)}
                            onKeyPress={handleKeyPress}
                            onChange={handleChange}
                            placeholder="Enter Your Phone Number "
                          />
                        </div>
                        <span style={{ color: "red" }}> {errors?.phone_code}</span>
                        {isPhoneValid === "Already Exists" ? (
                          <span className="text-danger mt-2">
                            Phone Number Already Exists. Please choose a different
                            one.
                          </span>
                        ) : (
                          <span className="ms-3" style={{ color: "red" }}>{errors?.phone}</span>
                        )}
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name"> Fax</label>
                        <input
                        className={`
                        form-control
                          ${((errors.fax) && (formData.fax === '') || (errors.fax)) && 'error_active'}
                          ${((errors.fax === '') && (formData.fax)) && 'sucess_active'}
                        `}
                          id="fax"
                          type="text"
                          name="fax"
                          maxLength={10}
                          value={formData?.fax}
                          onChange={handleChange}
                          onKeyPress={handleKeyPress}
                          placeholder="Enter Your Fax "
                        />
                        <span style={{ color: "red" }}>{errors.fax}</span>
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Company Description
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <input
                          className={`
                          form-control
                            ${((errors.company_description) && (formData.company_description === '') || (errors.company_description)) && 'error_active'}
                            ${((errors.company_description === '') && (formData.company_description)) && 'sucess_active'}
                          `}
                          id="company_description"
                          type="text"
                          name="company_description"
                          value={formData?.company_description}
                          onChange={handleChange}
                          placeholder="Enter Company Description "
                        />

                        <span style={{ color: "red" }}>
                          {errors?.company_description}
                        </span>
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Industry Preferences
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <div className="w-100">
                          <div className="input_checkbox form-control border p-2 rounded Preferences_check">
                            <div className="form-check mb-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckDefault1"
                                value="Select All / None"
                                onChange={handleCheckboxChange}
                                checked={selectedCheckboxes?.length === 3}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault1"
                              >
                                (Select All / None)
                              </label>
                            </div>
                            {[
                              "Private Placements Mining",
                              "Private Placements Oil and Gas",
                              "Private Placements - Other",
                              "Portfolio Management with Exempt Clients",
                              "Mergers and Acquisitions",
                              "Private Placements - Real Estate",
                              "Distributor of Exempt Investments",
                              "Other"
                            ].map((label, index) => (
                              <div className="form-check mb-2" key={index}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={label}
                                  id={`flexCheckChecked${index + 2}`}
                                  onChange={handleCheckboxChange}
                                  checked={selectedCheckboxes?.includes(label)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`flexCheckChecked${index + 2}`}
                                >
                                  {label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                        {industryError ? (
                          ""
                        ) : (
                          <span style={{ color: "red" }}>
                            {errors?.Industry_Preferences}
                          </span>
                        )}
                      </Col>
                    </div>

                    <div className="row ">
                      <Col lg={6} md={6} sm={12} className="d-flex">
                        <Button
                          className="submit_btn mx-2"
                          type="submit"
                          disabled={spinner}
                        >
                          Submit{" "}
                          {spinner ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : null}
                        </Button>
                        <Button
                          onClick={handleCancel}
                          className="mx-2"
                          style={{
                            background: "white",
                            color: "#0F66B5",
                            borderColor: " 2px solid #0F66B5",
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );

}
export default AdminAddUser;