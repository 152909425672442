import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Col, Container, Row, Form, Table } from "react-bootstrap";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { setPageNumber } from '../../redux/slice/firmdNumber.js';

const Subscription = () => {
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadings, setIsLoadings] = useState(false);
    const [isPopular, setIsPopular] = useState(false);
    const [subscription, setSubscription] = useState([]);
    const navigate = useNavigate();
    const storedAdmin = JSON.parse(window.localStorage.getItem("loginAdmin"));
    const [price_id, setPrice_id] = useState('')
    const [data, setData] = useState({
        amount: '',
        interval: '',
        subscription_name: '',
        tax: '',
    });
    const capitalizeFirstLetter = (str) => { return str?.charAt(0)?.toUpperCase() + str?.slice(1); };
    useEffect(() => {
        document.title = "PCMA || Subscription Management";
    }, []);

    const handleEditClose = () => {
        setShowEdit(false);
        setData(' ');
    }

    const dispatch = useDispatch()
    dispatch(setPageNumber(1));

    const handleEditShow = (data) => {
        const { subscription_name, unit_amount, is_popular, price_id } = data;
        setShowEdit(true);
        setData({
            amount: unit_amount,
            interval: 'year',
            subscription_name: subscription_name,
            tax: '',
        })
        setIsPopular(is_popular);
        setPrice_id(price_id);
    }


    const handleClose = () => {
        setShow(false);
        setData(' ');
        setIsPopular(false);
    }

    const handleShow = () => {
        setShow(true);
        setData(' ');
        setIsPopular(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(() => ({ ...data, [e.target.name]: e.target.value }))
    };

    const handleCheckbox = (e) => {
        var checked = e.target.checked;
        setIsPopular(checked);
    };

    useEffect(() => {
        allSubscription();
    }, [])

    const allSubscription = () => {
        setIsLoading(true)
        axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/subscription-lists`,
            {
                headers: {
                    Authorization: `Bearer ${storedAdmin?.Token}`,
                },
            }).then((response) => {
                setSubscription(response?.data);
                setIsLoading(false)
                if (response?.data?.message === "Unauthozied User") {
                    localStorage.removeItem('loginAdmin');
                    navigate("/admin");
                    toast.error(response?.data?.message);
                }
            }).catch((error) => {
                setIsLoading(false)
                if (error?.response?.data?.message === "Unauthozied User") {
                    localStorage.removeItem('loginAdmin');
                    navigate("/admin");
                    toast.error(error?.response?.data?.message);
                }
            });
    }

    const handleKeyPress = (e) => {
        const char = String.fromCharCode(e.keyCode || e.which);
        if (!/^[0-9+]+$/.test(char)) {
            e.preventDefault();
        }
    };

    const handleSubmitEdit = async () => {
        const showError = (field, message) => {
            toast.error(`${field} cannot be empty`);
            return false;
        };

        // const checkEmpty = (field, value) => {
        //   if (!value?.trim()) return showError(field);
        //   return true;
        // };

        const fieldsToCheck = [
            { field: 'Subscription Name', value: data?.subscription_name },
            { field: 'Amount', value: data?.amount },
            { field: 'Product Name', value: data?.product_name },
            { field: 'Product Id', value: data?.product_id }
        ];

        // for (const { field, value } of fieldsToCheck) {
        //   if (!checkEmpty(field, value)) return;
        // }

        try {
            const response = await axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/update-subscriptions`, {
                subscription_name: data?.subscription_name,
                amount: data?.amount,
                interval: data?.interval,
                tax: data?.tax,
                is_popular: isPopular,
                price_id
            }, { headers: { Authorization: `Bearer ${storedAdmin?.Token}` } });

            const { message } = response?.data || {};
            if (message === "Unauthozied User") {
                toast.error(message);
                localStorage.removeItem('loginAdmin');
                navigate("/admin");
            } else {
                toast[message === "Subscription Updated!" ? 'success' : 'success'](message);
            }

            setShowEdit(false);
            allSubscription();
        } catch (error) {
            if(error?.response?.data?.error?.includes('No such price')){
                toast.error(error?.response?.data?.error)
            }else{
                toast.error(error?.response?.data?.message)
            }
            
        }
    };

    const handleSubmit = async () => {
        setIsLoadings(true)

        if (!data || Object.values(data).every(value => value.trim() === '')) {
            toast.error("Subscription details cannot be empty");
            setTimeout(() => {
                 setIsLoadings(false)
            }, 1000);
           
            return false;
        }
         if (!data?.subscription_name?.trim() || data?.subscription_name?.trim().length === 0) {
            toast.error("Subscription name cannot be empty");
            setTimeout(() => {
                setIsLoadings(false)
           }, 1000);
            return false;
        } 
         if (!data?.interval || data?.interval == undefined) {
            toast.error("Select subscription duration");
            setTimeout(() => {
                setIsLoadings(false)
           }, 1000);
            return false;
        } 
        if (!data?.amount?.trim() || data?.amount?.trim().length === 0) {
            toast.error("Amount cannot be empty");
            setTimeout(() => {
                setIsLoadings(false)
           }, 1000);
            return false;
        }  
            try {
                const response = await axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/create-subscriptions`,
                    {
                        subscription_name: data?.subscription_name,
                        amount: data?.amount,
                        interval: data?.interval,
                        tax: data?.tax,
                        is_popular: isPopular
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${storedAdmin?.Token}`,
                        }
                    }
                );
                if (response?.data?.message === "Subscription Created Sucessfully!") {
                    toast.success(response?.data?.message)
                }else {
                    toast.success(response.data.message)
                }
                setShow(false);
                allSubscription();
                setIsLoadings(false)
            } catch (error) {
                if (error?.response?.data?.message === "Unauthozied User") {
                    toast.error(error?.response?.data?.message)
                    localStorage.removeItem('loginAdmin');
                    navigate("/admin");
                }  
                toast.error(`${error.response?.data?.message}`)
                setIsLoadings(false)
            }
        

    };

    const handleDelete = async (id) => {
        try {
            const { isConfirmed } = await Swal.fire({
                title: "Delete Subscription",
                text: "Do you want to delete this Subscription? This action cannot be undone.",
                icon: "warning", showCancelButton: true,
                cancelButtonColor: "#D0D5DD",
                confirmButtonColor: "#D92D20",
                confirmButtonText: "Delete",
                iconHtml: '<i class="fas fa-trash-alt"></i>', iconColor: "red"
            });
            if (!isConfirmed) return;
            const response = await axios.delete(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/subscription-delete?price_id=${id}`,
                { headers: { Authorization: `Bearer ${storedAdmin?.Token}` } });
            if (response?.data?.product?.deleted) toast.success("Subscription deleted");
            else if (response?.data?.message === "Unauthozied User") { localStorage.removeItem('loginAdmin'); navigate("/admin"); toast.error("Unauthorized user !"); }
            allSubscription();
        } catch (error) { toast.error("Something went wrong please try again later."); }
    };


    return (
        <>
            {isLoading ? (
                <Container fluid className="loader">
                    <Row className="g-2 pb-3 d-flex justify-content-center align-content-center " style={{ height: "79vh" }}>
                        <Col lg={12} md={12} sm={12} className="d-flex justify-content-center align-content-center">
                            <BallTriangle
                                height={100}
                                width={100}
                                radius={5}
                                color="#0f66b4"
                                ariaLabel="ball-triangle-loading"
                                wrapperClass={{}}
                                wrapperStyle=""
                                visible={true}
                                centered
                            />
                        </Col>
                    </Row>
                </Container>
            ) : (<>
                <div className="row pt-5 mb-1">
                    <div className="col-12">
                        <div style={{ background: "#137CD9", color: "#fff", borderRadius: '5px' }}>
                            <h4 className="mb-0 py-2 px-3">Membership Subscriptions</h4>
                        </div>
                    </div>
                    <div className="col-12">
                        <p className="px-4 my-4">
                            PCMA works closely and tirelessly with its members to spot trends, anticipate changes in the private capital markets, and identify efficiencies.
                            Our mission at PCMA, along with our small, yet mighty team, is to continue providing our members with the best service and most comprehensive industry solutions, strengthen our brand, advocate, bring further awareness, and do our part to keep the economy moving forward.
                            <br />
                            Our long-term value will come through and be reflected in the pride of membership; the PCMA is your industry association! By selecting corporate membership, any member can add two additional members (3 in total) with the corporate membership.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-12" 
                        // style={{ background: "#fff", border: "2px solid rgba(15, 102, 180, 0.2)", borderRadius: "5px" }} 
                        >
                            <div className="subcription_table">
                                <div className="table-responsive firms_card_box">
                                    <Table className="w-100 mb-0 notice_table_body">
                                        <thead className="wrappingBox">
                                            <tr className="notice_table_heading">
                                                <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:"0"}}>Subscriptions Name</th>
                                                <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:"0" , paddingLeft:"13px",}}>Amount</th>
                                                {/* <th>Tax Amount</th> */}
                                                <th className="text-center" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:"0"}}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subscription?.map((item, index) => (
                                                <tr key={item?._id}>
                                                    <td>{capitalizeFirstLetter(item?.subscription_name)} {item?.is_popular ? (
                                                        <>
                                                            {" "}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                            </svg>
                                                        </>
                                                    ) : ""}</td>
                                                    <td>${item?.unit_amount?.toFixed(2)}</td>
                                                    {/* <td>N/A</td> */}
                                                    <td>
                                                        <div className="subcription_table_btn d-flex align-items-center justify-content-center">
                                                            <button className="me-1" onClick={() => handleEditShow(item)}>
                                                                <i className="fa-solid fa-edit " style={{ cursor: 'pointer', color: '#0F66B4' }}></i>
                                                            </button>
                                                            <button onClick={() => handleDelete(item?.price_id)}>
                                                                <i className="fa-solid fa-trash" style={{ cursor: 'pointer', color: '#FF6969' }}></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                           
                        </div>
                        
                        
                    </div>
                    
                </div>
                <Row >
                        <Col lg={12} md={12} sm={12} className="d-flex justify-content-end align-content-center " style={{paddingRight:'2rem'}}>
                        <button className="p-2 adminadd_btn mb-3" onClick={handleShow}><i className="fa-solid fa-circle-plus my-1"></i>Add New </button>
                        </Col>
                        </Row>

            </>)}

            <Modal centered backdrop="true" keyboard={false} size="lg" show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="form p-3" >
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className="fw-bolder">Subscription Name
                                        <svg
                                            className="ms-1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828" />
                                        </svg>
                                    </Form.Label>
                                    <Form.Control type="text"
                                        value={data?.subscription_name || ''}
                                        onChange={handleChange}
                                        name="subscription_name"
                                        placeholder="Enter subscription name"
                                        onInput={(e) => {
                                            if (e.target.value.length > 50) {
                                                e.target.value = e.target.value.slice(0, 50);
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-12 col-sm-6">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <label htmlFor="interval" className="form-label fw-bolder">Subscription Duration
                                        <svg
                                            className="ms-1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828" />
                                        </svg>
                                    </label>
                                    <select className="form-select" name="interval" onChange={handleChange} value={data?.interval} >
                                        <option value="" hidden>Select Subscription Duration</option>
                                        <option value="year">1 Year</option>
                                    </select>
                                </Form.Group>
                            </div>
                            <div className="col-12 col-sm-6">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className="fw-bolder">Amount
                                        <svg
                                            className="ms-1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828" />
                                        </svg>
                                    </Form.Label>
                                    <Form.Control 
                                    type="number" 
                                    value={data?.amount || ''}
                                     onChange={handleChange} name="amount"
                                      onKeyPress={handleKeyPress} 
                                      placeholder="Enter amount ($)" 
                                      onInput={(e) => {
                                        if (e.target.value.length > 4) {
                                          e.target.value = e.target.value.slice(0, 4);
                                        }
                                      }}
                                      />
                                </Form.Group>
                            </div>

                            <div className="col-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isPopular} onChange={handleCheckbox} />
                                    <label className="form-check-label" htmlFor="flexCheckDefault"> Most popular</label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <button className="subcription_add_new" onClick={handleSubmit} disabled={isLoadings}>Add</button>
                        <button className="subcription_cancel ms-2" onClick={handleClose}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>


            {/* edit modal */}
            <Modal centered backdrop="true" keyboard={false} size="lg" show={showEdit} onHide={handleEditClose}>
                <Modal.Body>
                    <div className="form p-3" >
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className="fw-bolder">Subscription Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={data?.subscription_name || ''}
                                        onChange={handleChange} name="subscription_name"
                                        onInput={(e) => {
                                            if (e.target.value.length > 50) {
                                                e.target.value = e.target.value.slice(0, 50);
                                            }
                                        }}
                                        placeholder="Enter subscription name" />
                                </Form.Group>
                            </div>
                            <div className="col-12 col-sm-6">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <label htmlFor="interval" className="form-label fw-bolder">Subscription Duration</label>
                                    <select disabled className="form-select" name="interval" onChange={handleChange} value={data?.interval} >
                                        <option value="year" >1 Year</option>
                                    </select>
                                </Form.Group>
                            </div>
                            <div className="col-12 col-sm-6">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                                    <Form.Label className="fw-bolder">Amount</Form.Label>
                                    <Form.Control type="text" 
                                    value={data?.amount ? Number(data.amount).toFixed(2) : ''} onChange={handleChange} name="amount" placeholder="Enter amount ($)" disabled />
                                </Form.Group>
                            </div>

                            <div className="col-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isPopular} onChange={handleCheckbox} />
                                    <label className="form-check-label" htmlFor="flexCheckDefault"> Most popular</label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <button className="subcription_add_new" onClick={handleSubmitEdit}>Save</button>
                        <button className="subcription_cancel ms-2" onClick={handleEditClose}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* edit modal */}

        </>
    )
}
export default Subscription;