import React, { useState, useEffect } from 'react';
import { Container, Row, Col,Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { BallTriangle } from "react-loader-spinner";


const TaxManagement = () => {

    let token = '';
    const navigate = useNavigate()
    const [taxRates, setTaxRates] = useState([]);
    const [taxPercentage, setTaxPercentage] = useState(null);
    const [remarks, setRemarks] = useState(null);
    const [updatedPercentage, setUpdatedPercentage] = useState(0);
    const [updatedRemarks, setUpdatedRemarks] = useState('');
    const [taxId, setTaxId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const adminData = JSON.parse(window.localStorage.getItem("loginAdmin"));
    // const [editdata, setEditData] = useState({
    //     taxPercentage: 0,
    //     remarks: ''
    // });
    // const [errors, setErrors] = useState({
    //     taxPercentage: '',
    //     remarks: ''
    // });
    // let newErrors = { ...errors };
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
    if (adminData) { token = adminData?.Token; } else { token = null; };

    useEffect(() => {
        taxRateList();
    }, []);

    const taxRateList = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/tax-rate-list`, {
            headers: {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },

        }).then((response) => {
            setTaxRates(response?.data?.data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false)
            if (error?.response?.data?.message === "Request failed with status code 401") {
                localStorage.removeItem('loginAdmin');
                navigate("/admin/login");
                toast.error(`Unauthozied user !`);
            }
        });
    };


    // const handleChangePercentage = (e) => {
    //     let value = e.target.value;
    //     if (!value) {
    //        e.preventDefault(); 
           
    //     }else if (!/^(\d+(\.\d{0,2})?)?$/?.test(value)) {
    //         e.preventDefault();
            
    //     }
    //     else if (parseFloat(updatedPercentage) > 30) {
    //         e.preventDefault(); 
             
    //     }
    //     setUpdatedPercentage(e?.target?.value);
    // };


    const handleChangePercentage = (e) => {
        let value = e.target.value;

        const regex = /^\d*\.?\d{0,2}$/;
    
        if (!value) {
            e.preventDefault();
            setUpdatedPercentage('');
            return;
        }
    
        if (!regex.test(value)) {
            e.preventDefault();
            return;
        }

        if (parseFloat(value) > 30) {
            e.preventDefault();
            toast.error("Percentage cannot be greater than 30%");
            return;
        }

        setUpdatedPercentage(value);
    };

    
    const handleChangeRemarks = (e) => {
        let value = e?.target?.value?.trimStart();
        setUpdatedRemarks(value);
    };



    const handleEdit = (data) => { 
        setRemarks(null);
        setUpdatedPercentage(data?.percentage);
        setTaxId(data?.stripe_tax_rate_id);
        setTaxPercentage(data?._id);      
    };

    const handleEditRemark = (data) => {
        setTaxPercentage(null);
        setUpdatedPercentage(data?.percentage);
        setUpdatedRemarks(data?.remarks);
        setTaxId(data?.stripe_tax_rate_id);
        setRemarks(data?._id);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const updatedPercentageStr = updatedPercentage.toString();  
       if (!updatedPercentage){
        toast.error("Percentage is required");
        return;
       }else if (updatedPercentageStr?.includes('.')) {
        const decimalIndex = updatedPercentageStr?.indexOf('.');
        const decimals = updatedPercentageStr?.substring(decimalIndex + 1);  
        if (decimals?.length >= 2 && e.target.selectionStart > decimalIndex) {
          toast.error("Only up to two decimal places are allowed.");
        }
      }else if (parseFloat(updatedPercentageStr) > 30) {
            toast.error("Percentage cannot be greater than 30%");
            return;
        }
        if (taxId?.length > 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/update-tax-rate`,
                    {
                        tax_rate_id: taxId,
                        percentage: updatedPercentage,
                        remarks: updatedRemarks
                    }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response?.data?.message === "Tax rate updated successfully") {
                    toast?.success('Updated successfully');
                    setTaxPercentage(null);
                    taxRateList();

                } else if (response?.data?.message === "Remarks updated successfully") {
                    toast?.success(response?.data?.message);
                    setRemarks(null);
                    taxRateList();
                }

            } catch (error) {
                if (error?.response?.data?.message === "Request failed with status code 401") {
                    localStorage.removeItem('loginAdmin');
                    navigate("/admin/login");
                }
                setIsLoading(false);       
            }
        }
    };
    
 
    return (<>
        {isLoading ? (
            <Container fluid className="loader">
                <Row className="g-2 pb-3 d-flex justify-content-center align-content-center " style={{ height: "79vh" }}>
                    <Col lg={12} md={12} sm={12} className="d-flex justify-content-center align-content-center">
                        <BallTriangle
                            height={100}
                            width={100}
                            radius={5}
                            color="#0f66b4"
                            ariaLabel="ball-triangle-loading"
                            wrapperClass={{}}
                            wrapperStyle=""
                            visible={true}
                            centered
                        />
                    </Col>
                </Row>
            </Container>
        ) : (
            <>
            <div className="row pt-5 mb-1">
                <div className="col-12">
                    <div style={{ background: "#137CD9", color: "#fff", borderRadius: '5px' }}>
                        <h4 className="mb-0 py-2 px-3">Tax Management</h4>
                    </div>
                </div>
                <div className="col-12">
                    <div className='tax_management_country d-flex justify-content-start align-items-center px-4 my-4'>
                        <p className='mb-0'>Country</p>
                        <div className='tax_management_box ms-3'>
                            <p className='mb-0'>Canada</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12" >
                        <div className="subcription_table">
                            <div className="table-responsive firms_card_box">
                                <Table className="w-100 mb-0 notice_table_body">
                                    <thead className="wrappingBox">
                                        <tr className="notice_table_heading">
                                            <th className="text-center" style={{ background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: "0" }}>PROVINCE</th>
                                            <th className="text-center" style={{ background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: "0", paddingLeft: "13px", }}>TAX (%)</th>
                                            <th className="text-center" style={{ background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: "0" }}>LAST MODIFIED</th>
                                            <th className="text-center" style={{ background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: "0" }}> REMARKS <span> (50 Characters Max.)</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {taxRates?.length === 0 ? (
                                        <tr>
                                            <td colSpan="5" className="text-center">
                                                <div className="d-flex align-items-center justify-content-center w-100">
                                                    No Data Found
                                                </div>
                                            </td>
                                        </tr>
                                        ) : (
                                        taxRates?.map((tax) => (
                                            <>
                                                <tr key={tax?._id}>
                                                    <td className='text-center'>{tax?.name}</td>
                                                    <td className='text-center'>
                                                        {taxPercentage === tax?._id ? (
                                                            <>
                                                                <div className='d-flex align-items-center justify-content-center'>

                                                                    <input type="number" name="taxPercentage" className='tax_percentage' value={updatedPercentage} onChange={handleChangePercentage}  step="0.01"  // Allow two decimal places
                                                                        min="0"
                                                                        max="30" /><span>%</span>

                                                                    <div className='d-flex align-items-center  ms-2'>
                                                                        <i className="fa-solid fa-check " style={{ cursor: 'pointer', color: '#7BBC3A' }} onClick={handleSave}></i>
                                                                        <i className="fa-solid fa-xmark ms-2" style={{ cursor: 'pointer', color: '#DC3939' }} onClick={() => setTaxPercentage(null)} ></i>
                                                                    </div>
                                                                </div>

                                                            </>
                                                        ) : (
                                                            <>
                                                                {tax?.percentage}% <i className="fa-solid fa-pen px-2" style={{ cursor: 'pointer', color: '#0F66B4' }} onClick={() => handleEdit(tax)}></i>
                                                            </>
                                                        )}

                                                    </td>
                                                    <td className='text-center'>{tax?.updated_at?.split('T')[0]}</td>

                                                    <td className='text-center'>
                                                        {remarks === tax?._id ? (
                                                            <>
                                                                <div className='d-flex align-items-center justify-content-center'>

                                                                    <input type="text" name="remarks" className="tax_remarks" value={updatedRemarks} onChange={handleChangeRemarks} maxLength={50}/>

                                                                    <div className='d-flex align-items-center  ms-2'>
                                                                        <i className="fa-solid fa-check " style={{ cursor: 'pointer', color: '#7BBC3A' }} onClick={handleSave}></i>
                                                                        <i className="fa-solid fa-xmark ms-2" style={{ cursor: 'pointer', color: '#DC3939' }} onClick={() => setRemarks(null)}></i>
                                                                    </div>
                                                                </div>

                                                            </>
                                                        ) : (
                                                            <>

                                                                {tax?.remarks ? tax?.remarks :'--'} <i className="fa-solid fa-pen px-2" style={{ cursor: 'pointer', color: '#0F66B4' }} onClick={() => handleEditRemark(tax)}></i>

                                                            </>
                                                        )}
                                                    </td>


                                                </tr>
                                            </>
                                        )))}                                               
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>       
                
        )}
    </>
 
        
    );
};
export default TaxManagement;