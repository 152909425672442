import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchNavs = createAsyncThunk("fetchNavs", async () => {
  try {
    const response = await fetch(
      `https://pcma1dev.wpenginepowered.com/wp-json/custom/v1/menu`
    );
    return response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});

const navSlice = createSlice({
  name: "menus",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNavs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchNavs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchNavs.rejected, (state) => {
      state.isError = true;
    });
  },
});
export default navSlice.reducer;
