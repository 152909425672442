import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate , Link} from 'react-router-dom';
import axios from 'axios';
import '../../styles/Global.css';

const NavbarGlobal = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [navData, setNavData] = useState([]);
  const [toggleData, setToggleView] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const extractFirstPart = (str) => (str.split(/[^a-zA-Z0-9]+/).filter(Boolean)[0] || '');

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setToggleView(false);
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const MemberShipToggle = () => {
    setToggleView(!toggleData);
  };

  const handleScroll = useCallback(() => {
    setScrollPosition(window.scrollY);
  }, []);

  useEffect(() => {
    const throttledScroll = throttle(handleScroll, 200); // Limit the number of times scroll is called
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [handleScroll]);

  const redirectContactPage = () => {
    window.open('https://pcma1dev.wpenginepowered.com/contact-us/', '_blank');
  };

  useEffect(() => {
    axios.get(`https://pcma1dev.wpenginepowered.com/wp-json/custom/v1/menu`, {
    }).then((res) => {
        setNavData(res?.data);
    }).catch((error) => {
        console.error("Error fetching navigation data:", error);
    }) }, []);
 

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function (...args) {
      if (!lastRan) {
        func.apply(this, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if ((Date.now() - lastRan) >= limit) {
            func.apply(this, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); 


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className={`header_wordpress p-0 ${scrollPosition > 50 ? 'navbar-shrink' : ''}`}>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <a href="https://pcma1dev.wpenginepowered.com/" className="navbar-brand">
              <img
                src="https://pcma1dev.wpenginepowered.com/wp-content/uploads/2024/02/logo_old1.png"
                alt="pcm" width="164" height="77"
                className="logo-img d-inline-block align-text-top" />
            </a>
            <button className="navbar-toggler ms-sm-auto" type="button" onClick={handleClick}>
              <i className="fa-solid fa-bars" style={{ fontSize: '27px' }}></i>
            </button>
            <div className={`collapse navbar-collapse ms-auto navbar_nav ${isOpen ? 'show' : ''}`} id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 me-2" id="navbar_close">
                <li className="nav-item text-start text-lg-center menu-item d-none d-lg-block">
                  <a href="/" className="nav-link" aria-current="page">Home</a>
                </li>
                        <li className="nav-item dropdown dropdown-mega position-static">
                        {!isMobile ?  
                            <a className="nav-link dropdown-toggle mega-dropdown-menu-btn" href="#" data-bs-toggle="dropdown"  data-bs-auto-close="false" aria-expanded="false">More</a>
                            :""}
                            <div className="dropdown-menu shadow mega-dropdown-menu">
                                <div className="mega-content p-2 p-lg-4">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="d-block d-md-none mb-2">
                                                <div className="col-12">
                                                    <div className="sub_menu">
                                                        <a className="text-decoration-none" href="#">Home</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-sm-6">
                                                <nav className="toggle_sections">
                                                    <div className="nav nav-tabs select_button flex-sm-row text-center border-0" id="nav-tab" role="tablist">
                                                    {navData?.map((nav, index) => (
                                                        <button
                                                            key={index}
                                                            className={`nav-link ${index === 0 ? 'active' : ''} flex-sm-fill border-0`}
                                                            id={`nav-${nav?.title?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase()}-tab`}
                                                            data-bs-toggle="tab"
                                                            data-bs-target={`#nav-${nav?.title?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase()}`}
                                                            type="button"
                                                            role="tab"
                                                            aria-controls={`nav-${nav?.title?.replace(/\s+/g, '-')}`}
                                                            aria-selected={index === 0 ? 'true' : 'false'}
                                                          
                                                        >
                                                            {nav?.title}                                                                                                                 
                                                        </button>
                                                    ))}  
                                                      
        
                                                        
                                                         </div>   
                                                </nav>
                                            </div>
                                            <div className="col-lg-12 col-sm-6">  
                                                <div className="tab-content" id="nav-tabContent">
                                                    {navData?.map((nav, index) => (
                                                    <div
                                                        key={index}
                                                        className={`tab-pane ${index === 0 ? 'show active' : 'fade'}`}
                                                        id={`nav-${nav?.title?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase()}`}
                                                        role="tabpanel"
                                                        aria-labelledby={`nav-${nav?.title?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase()}-tab`}
                                                        >
                                                      
                                                        <div  className='row'>
                                                    
                                                        {nav?.menu && Object.keys(nav?.menu)?.length > 0 && (
                                                        Object.entries(nav?.menu)?.map(([key, item], itemIndex) => (
                                                           
                                                            Array.isArray(item["sub-menu"]) && item["sub-menu"].length > 0 ? (
                                                                <>

                                                           
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12" key={itemIndex}>
                                                                <div className="sub_menu">
                                                                <div className="dropdown">
                                                                    <button className="btn btn-transparent dropdown-toggle"  type="button" id={`dropdownMenuButton-${itemIndex}`}
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                    >
                                                                    {item?.title}
                                                                    </button>
                                                                    <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${itemIndex}`}>
                                                                    {item["sub-menu"].map((subItem, subIndex) => (
                                                                        <li key={subIndex}>
                                                                        {subItem?.url ? (
                                                                             <Link to={subItem.url} className="dropdown-item text-decoration-none text-truncate">
                                                                                {subItem.title}
                                                                            </Link>
                                                                              ) : (
                                                                            <Link  className="dropdown-item text-truncate" style={{ cursor: 'default', pointerEvents: 'none' }}>
                                                                                {subItem.title}
                                                                            </Link>
                                                                        )}
                                                                            
                                                                        </li>
                                                                    ))}
                                                                    </ul>
                                                                </div>
                                                                </div>
                                                            </div>
                                                     
                                                             </>
                                                            

                                                            ) : (
                                                               
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12" key={itemIndex}>
                                                                <div className="sub_menu">
                                                                {item?.url ? (
                                                                    <Link className="text-decoration-none text-truncate" to={item?.url}>
                                                                        {item?.title}
                                                                    </Link>
                                                                    ) : (
                                                                    <Link className="text-decoration-none text-truncate" style={{cursor:"default"}}>
                                                                        {item?.title}
                                                                    </Link>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            )
                                                        )
                                                        )
                                                        )}
                                                        </div>
                                                    </div>
                                                    ))}
                                                   
                                                </div>
                                            </div>
                                            <div className="d-block d-md-none">
                                                <div className="col-12">
                                                    <div className="sub_menu">
                                                        <Link className="text-decoration-none" to={'https://pcma1dev.wpenginepowered.com/contact-us/'}>Contact Us</Link>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                            
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                     </ul>
                     <div className="d-grid gap-2 d-lg-flex justify-content-center justify-content-lg-end d-none">
                        <Link className="btn btn-primary btn-nav text-decoration-none" to={'https://pcma1dev.wpenginepowered.com/contact-us/'}>Contact Us</Link>
                     </div>
                  </div>
               </div>
            </nav>
         </div>

   </>
  );
};

export default NavbarGlobal;